import {
  ApolloLink,
  Operation,
  NextLink,
  Observable,
  FetchResult,
} from '@apollo/client';
import axios, { AxiosResponse } from 'axios';

const API_HOST = process.env.API_HOST || process.env.REACT_APP_API_HOST;

export class ReportLink extends ApolloLink {
  constructor() {
    super();
  }

  submissionReport(operation: Operation) {
    return new Observable<FetchResult<{ submissionReport: Blob }>>(
      (observer) => {
        const context = operation.getContext();
        axios
          .get(
            `${API_HOST}/core/client/submissions/${operation.variables.id}/report`,
            {
              responseType: 'blob',
              headers: context.headers,
              withCredentials: true,
            }
          )
          .then((res: AxiosResponse<Blob>) => {
            observer.next({
              data: {
                submissionReport: res.data,
              },
            });
            observer.complete();
          })
          .catch((e) => {
            observer.error(e);
          });
      }
    );
  }

  casesReport(operation: Operation) {
    return new Observable<FetchResult<{ casesReport: Blob }>>((observer) => {
      const context = operation.getContext();

      axios
        .get(`${API_HOST}/core/client/cases/to_csv`, {
          responseType: 'blob',
          headers: context.headers,
          params: operation.variables,
        })
        .then((res: AxiosResponse<Blob>) => {
          observer.next({
            data: {
              casesReport: res.data,
            },
          });
          observer.complete();
        })
        .catch((e) => {
          observer.error(e);
        });
    });
  }

  pPScreeningReport(operation: Operation) {
    return new Observable<FetchResult<{ pPScreeningReport: Blob }>>(
      (observer) => {
        const context = operation.getContext();

        axios
          .get(`${API_HOST}/pp/passive_profile_screenings/to_csv`, {
            responseType: 'blob',
            headers: context.headers,
            params: operation.variables,
            withCredentials: true,
          })
          .then((res: AxiosResponse<Blob>) => {
            observer.next({
              data: {
                pPScreeningReport: res.data,
              },
            });
            observer.complete();
          })
          .catch((e) => {
            observer.error(e);
          });
      }
    );
  }

  submissionsReport(operation: Operation) {
    return new Observable<FetchResult<{ submissionsReport: Blob }>>(
      (observer) => {
        const context = operation.getContext();

        axios
          .get(`${API_HOST}/core/client/submissions/to_csv`, {
            responseType: 'blob',
            headers: context.headers,
            params: operation.variables,
            withCredentials: true,
          })
          .then((res: AxiosResponse<Blob>) => {
            observer.next({
              data: {
                submissionsReport: res.data,
              },
            });
            observer.complete();
          })
          .catch((e) => {
            observer.error(e);
          });
      }
    );
  }

  fincenReport(operation: Operation) {
    return new Observable<FetchResult<{ fincenCheckupsReport: Blob }>>(
      (observer) => {
        const context = operation.getContext();

        axios
          .get(`${API_HOST}/checkups/client/fincen/reports/checkups_csv`, {
            responseType: 'blob',
            headers: context.headers,
            params: operation.variables,
            withCredentials: true,
          })
          .then((res: AxiosResponse<Blob>) => {
            observer.next({
              data: {
                fincenCheckupsReport: res.data,
              },
            });
            observer.complete();
          })
          .catch((e) => {
            observer.error(e);
          });
      }
    );
  }

  checkupsReport(operation: Operation) {
    return new Observable<FetchResult<{ checkupsReport: Blob }>>((observer) => {
      const context = operation.getContext();

      axios
        .get(`${API_HOST}/checkups/client/reports/checkups_csv`, {
          responseType: 'blob',
          headers: context.headers,
          params: operation.variables,
          withCredentials: true,
        })
        .then((res: AxiosResponse<Blob>) => {
          observer.next({
            data: {
              checkupsReport: res.data,
            },
          });
          observer.complete();
        })
        .catch((e) => {
          observer.error(e);
        });
    });
  }

  metricsReport(operation: Operation) {
    return new Observable<FetchResult<{ createMetricsReport: Blob }>>(
      (observer) => {
        const context = operation.getContext();

        axios
          .post(
            `${API_HOST}/stats/client/to_csv?csv_type=metric`,
            operation.variables.body,
            {
              responseType: 'blob',
              headers: context.headers,
              withCredentials: true,
            }
          )
          .then((res: AxiosResponse<Blob>) => {
            observer.next({
              data: {
                createMetricsReport: res.data,
              },
            });
            observer.complete();
          })
          .catch((e) => {
            observer.error(e);
          });
      }
    );
  }

  complianceViewReport(operation: Operation) {
    return new Observable<FetchResult<{ createComplianceViewReport: Blob }>>(
      (observer) => {
        const context = operation.getContext();
        axios
          .post(
            `${API_HOST}/stats/client/to_csv?csv_type=сompliance_view`,
            operation.variables.body,
            {
              responseType: 'blob',
              headers: context.headers,
              withCredentials: true,
            }
          )
          .then((res: AxiosResponse<Blob>) => {
            observer.next({
              data: {
                createComplianceViewReport: res.data,
              },
            });
            observer.complete();
          })
          .catch((e) => {
            observer.error(e);
          });
      }
    );
  }

  quickViewReport(operation: Operation) {
    return new Observable<FetchResult<{ createQuickViewReport: Blob }>>(
      (observer) => {
        const context = operation.getContext();

        axios
          .post(
            `${API_HOST}/stats/client/to_csv?csv_type=quick_view`,
            operation.variables.body,
            {
              responseType: 'blob',
              headers: context.headers,
              withCredentials: true,
            }
          )
          .then((res: AxiosResponse<Blob>) => {
            observer.next({
              data: {
                createQuickViewReport: res.data,
              },
            });
            observer.complete();
          })
          .catch((e) => {
            observer.error(e);
          });
      }
    );
  }

  isacCodes(operation: Operation) {
    return new Observable<FetchResult<{ clientIsacIndustriesCSV: Blob }>>(
      (observer) => {
        const context = operation.getContext();

        axios
          .get(`${API_HOST}/core/client/isac_industries/to_csv`, {
            responseType: 'blob',
            headers: context.headers,
            withCredentials: true,
          })
          .then((res: AxiosResponse<Blob>) => {
            observer.next({
              data: {
                clientIsacIndustriesCSV: res.data,
              },
            });
            observer.complete();
          })
          .catch((e) => {
            observer.error(e);
          });
      }
    );
  }

  transactionScoringReport(operation: Operation) {
    return new Observable<
      FetchResult<{ downloadTransactionScoringMatrixXlsx: Blob }>
    >((observer) => {
      const context = operation.getContext();

      axios
        .post(
          `${API_HOST}/tm/scoring/matrices/run_matrix/xlsx`,
          operation.variables.body,
          {
            responseType: 'blob',
            headers: context.headers,
            withCredentials: true,
          }
        )
        .then((res: AxiosResponse<Blob>) => {
          observer.next({
            data: {
              downloadTransactionScoringMatrixXlsx: res.data,
            },
          });
          observer.complete();
        })
        .catch((e) => {
          observer.error(e);
        });
    });
  }

  transactionsScoringReport(operation: Operation) {
    return new Observable<
      FetchResult<{ downloadTransactionsScoringMatrixXlsx: Blob }>
    >((observer) => {
      const context = operation.getContext();

      axios
        .post(
          `${API_HOST}/tm/scoring/matrices/run_many_matrices/xlsx`,
          operation.variables.body,
          {
            responseType: 'blob',
            headers: context.headers,
            withCredentials: true,
          }
        )
        .then((res: AxiosResponse<Blob>) => {
          observer.next({
            data: {
              downloadTransactionsScoringMatrixXlsx: res.data,
            },
          });
          observer.complete();
        })
        .catch((e) => {
          observer.error(e);
        });
    });
  }

  blacklistProfilesReport(operation: Operation) {
    return new Observable<FetchResult<{ blacklistProfilesReport: Blob }>>(
      (observer) => {
        const context = operation.getContext();

        axios
          .get(`${API_HOST}/core/client/blacklisted_profiles/to_csv`, {
            responseType: 'blob',
            headers: context.headers,
            withCredentials: true,
          })
          .then((res: AxiosResponse<Blob>) => {
            observer.next({
              data: {
                blacklistProfilesReport: res.data,
              },
            });
            observer.complete();
          })
          .catch((e) => {
            observer.error(e);
          });
      }
    );
  }

  request(operation: Operation, forward: NextLink) {
    switch (operation.operationName) {
      case 'SubmissionReport':
        return this.submissionReport(operation);
      case 'CasesReport':
        return this.casesReport(operation);
      case 'SubmissionsReport':
        return this.submissionsReport(operation);
      case 'FincenCheckupsReport':
        return this.fincenReport(operation);
      case 'CheckupsReport':
        return this.checkupsReport(operation);
      case 'PPScreeningReport':
        return this.pPScreeningReport(operation);
      case 'CreateMetricsReport':
        return this.metricsReport(operation);
      case 'CreateComplianceViewReport':
        return this.complianceViewReport(operation);
      case 'CreateQuickViewReport':
        return this.quickViewReport(operation);
      case 'BlacklistProfilesReport':
        return this.blacklistProfilesReport(operation);
      case 'DownloadTransactionScoringMatrixXlsx':
        return this.transactionScoringReport(operation);
      case 'DownloadTransactionsScoringMatrixXlsx':
        return this.transactionsScoringReport(operation);
      case 'ClientIsacIndustriesCSV':
        return this.isacCodes(operation);
      default:
        return forward(operation);
    }
  }
}
