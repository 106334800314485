import { BreadcrumbsRoutesType } from './types';

export const BreadcrumbsRoutes: BreadcrumbsRoutesType = {
  all: {
    name: 'Screening Management',
    formName: 'Create new screening',
  },
  aml_for_business: {
    name: 'AML for business',
  },
  plans: {
    name: 'Pricing Plans',
    formName: 'Add New Pricing Plan',
    infoName: 'Pricing Plan Information',
  },
  features: {
    name: 'Pricing Features',
  },
  sections: {
    name: 'Pricing Sections',
  },
  audit: {
    name: 'Audit',
  },
  search_entries: {
    name: 'Global search',
  },
  addresses: {
    name: 'Addresses',
  },
  account_statuses: {
    name: 'Account Statuses',
  },
  ac_for_business: {
    name: 'Comply Advantage for business',
  },
  client_dictionaries: {
    name: 'Dictionaries',
    formName: 'Add New Dictionary',
    infoName: 'Dictionary Information',
  },
  companies: {
    name: 'Companies',
    formName: 'Company',
  },
  outgoing: {
    name: 'Outgoing Sharings',
    formName: 'Sharing Details',
  },
  incoming: {
    name: 'Incoming Sharings',
    formName: 'Sharing Details',
  },
  case_projections: {
    name: 'Received Cases',
  },
  matrices: {
    name: 'TRX Risk Scores',
    formName: 'Create TRX Risk Score',
    infoName: 'Edit TRX Risk Score',
  },
  narrative_templates: {
    name: 'Narrative Templates',
    formName: 'Add Narrative Template',
    infoName: 'Narrative Template Information',
  },
  alerts: {
    name: 'Alerts',
    formName: 'Alert Information',
  },
  alerts_queues: {
    name: 'Alert Queues',
    formName: 'Alert Information',
  },
  my_alerts: {
    name: 'My Alerts',
    formName: 'Alert Information',
  },
  aml_for_individual: {
    name: 'AML for individual',
  },
  ac_for_individual: {
    name: 'Comply Advantage for individual',
  },
  cm: {
    name: 'Case management',
  },
  verifications: {
    name: 'Verifications',
    formName: 'Verification information',
  },
  blacklist: {
    name: 'Blacklist',
    formName: 'Add to blacklist',
    infoName: 'Edit blacklisted profile',
  },
  blacklisted_profiles_person: {
    name: 'A.ID Blacklist individual',
    formName: 'Create new A.ID Blacklist individual screening',
  },
  blacklisted_profiles_business: {
    name: 'A.ID Blacklist business',
    formName: 'Create new A.ID Blacklist business screening',
  },
  blacklist_reasons: {
    name: 'Blacklist reasons',
    formName: 'Create new Blacklist reason',
    infoName: 'Edit Blacklist reason',
  },
  invites: {
    name: 'User Invites',
    formName: 'User Invite information',
  },
  info: {
    name: 'Description',
  },
  verification_flows: {
    name: 'Verification flows',
    infoName: 'Verification flow information',
    formName: 'Create new verification flow',
  },
  // verifications: {
  //   name: 'Verifications',
  //   infoName: 'Customer Verification Details',    
  // },
  profiles: {
    name: 'Profiles',
    formName: 'Profile information',
  },
  monitoring: {
    name: 'Transactions',
    formName: 'Transaction Information',
  },
  onboarding_flows: {
    name: 'Onboarding Flows',
  },
  periodic_reviews: {
    name: 'Periodic Reviews',
  },
  persons: {
    name: 'Persons',
    formName: 'Person',
  },
  risk_assessments: {
    name: 'Risk Assessments',
  },
  screening_checks: {
    name: 'Screening Checks',
  },
  cases: {
    name: 'Cases',
    formName: 'Case information',
  },
  edd_reports: {
    name: 'Enhanced due diligence',
    formName: 'EDD Details',
  },
  investigations: {
    name: 'Investigations',
    formName: 'Investigation information',
  },
  customer_documents: {
    name: 'Customer Documents',
    formName: 'Customer Document Information',
  },
  my_cases: {
    name: 'My Investigations',
    formName: 'Investigation information',
  },
  cases_queues: {
    name: 'Investigation Queues',
    formName: 'Investigation information',
  },
  queues: {
    name: 'Alert Queues',
    formName: 'Alert Queue Information',
  },
  mappings: {
    name: 'Form mappings',
    formName: 'Form mapping informaton',
  },
  identity_verifications: {
    name: 'Identity verifications',
    formName: 'Identity verification information',
  },
  tags: {
    name: 'Tags',
    infoName: 'Edit tag',
    formName: 'Create new tag',
  },
  blacklist_groups: {
    name: 'Blacklist groups',
    formName: 'Edit blacklist group',
  },
  form: {
    name: 'Create new',
  },
  elliptic: {
    name: 'Cryptocurrency Toolset',
  },
  kyc_doc_verification: {
    name: 'KYC Screenings',
  },
  regions: {
    name: 'Client regions',
    formName: 'Edit region',
  },
  bus_registry_verification: {
    name: 'Registry verification',
  },
  admin: {
    name: 'Administrator tools',
  },
  clients: {
    name: 'Clients',
    infoName: 'Edit client',
    formName: 'Create new client',
  },
  form_templates: {
    name: 'Form templates',
  },
  users: {
    name: 'Users',
    infoName: 'Edit user',
    formName: 'User Information',
  },
  clients_checkups: {
    name: 'Checkups',
  },
  checkups_kinds: {
    name: 'Screenings',
  },
  providers: {
    name: 'Providers',
    formName: 'Provider information',
  },
  checkup_kinds: {
    name: 'Screenings',
    formName: 'Screenings information',
  },
  clients_providers: {
    name: 'Clients Providers',
    formName: 'Client provider information',
  },
  resolutions: {
    name: 'Resolutions for screenings',
    infoName: 'Resolution for screening information',
  },
  blacklisted_device_infos: {
    name: 'Blacklisted Device Infos',
  },
  prohibited_countries: {
    name: 'Prohibited Countries',
  },
  case_rejection_reasons: {
    name: 'Rejection reasons',
    infoName: 'Rejection reason information',
  },
  checkup_kinds_clients: {
    name: 'Screenings Clients',
    formName: 'Screening client information',
  },
  checkups: {
    name: 'Verification Settings',
    formName: 'Edit verification setting',
  },
  checkup: {
    name: 'Compliance toolset',
  },
  field_templates: {
    name: 'Field templates',
    formName: 'Create new field template',
    infoName: 'Edit field template',
  },
  risk_scoring_templates: {
    name: 'Risk scoring templates',
    formName: 'Create new risk scoring template',
    infoName: 'Edit risk scoring template',
  },
  account: {
    name: 'Client Settings',
  },
  balance: {
    name: 'Payments and Billing',
    formName: 'Make a deposit',
  },
  dashboard: {
    name: 'Statistics',
  },
  verification_dashboard: {
    name: 'Quick view',
  },
  metrics: {
    name: 'Metrics',
  },
  submissions: {
    name: 'Verification statistics',
    formName: 'Verification Statistic',
  },
  compliance_dashboard: {
    name: 'Compliance view',
  },
  roles: {
    name: 'Roles',
    formName: 'Create new role',
    infoName: 'Role information',
  },
  rulesets: {
    name: 'Rules management',
  },
  form_tags: {
    name: 'Form tags',
    formName: 'Create new form tag',
    infoName: 'Edit form tag',
  },
  translations: {
    name: 'Translations',
    formName: 'Create new translation',
  },
  aml_shared_result: {
    name: 'Shared result info',
  },
  unit_21_kyb: {
    name: 'Unit 21 KYB',
  },
  forms: {
    name: 'Form builder',
  },
  comply_advantage_aml: {
    name: 'AML for individual',
    formName: 'Create new AML for individual screening',
  },
  comply_advantage_aml_business: {
    name: 'AML for business',
    formName: 'Create new AML for business screening',
  },
  shuftipro_kyb: {
    name: 'Company registries',
    formName: 'Create new Legal entity registries screening',
  },
  elliptic_wallet: {
    name: 'Cryptocurrency wallet',
    formName: 'Create new Cryptocurrency wallet screening',
  },
  shuftipro_aml: {
    name: 'AML for individual (shuftipro)',
  },
  shuftipro_aml_business: {
    name: 'AML for business (shuftipro)',
  },
  fincen_business: {
    name: 'FinCEN 314(a) business',
    formName: 'Create new FinCEN 314(a) business screening',
  },
  fincen_person: {
    name: 'FinCEN 314(a) person',
    formName: 'Create new FinCEN 314(a) person screening',
  },
  entity: {
    name: 'Client information',
  },
  instrument: {
    name: 'Account information',
  },
  fincen_lists: {
    name: 'FinCEN 314(a) lists',
  },
  screening: {
    name: 'Screening Information',
  },
  mass_screenings_by_fincen_id: {
    name: 'Mass Screenings By FinCEN 314(a) List',
  },
  mass_screenings: {
    name: 'Mass Screening',
  },
  stop_words: {
    name: 'Ignored Words',
    formName: 'Create New Ignored Word',
    infoName: 'Update Ignored Word',
  },
  email_templates: {
    name: 'Templates',
    formName: 'Add Email Template',
    infoName: 'Email Template Information',
  },
  customer_messages: {
    name: 'Letters',
    formName: 'Create Letter',
    infoName: 'Letter Details',
  },
  idv_results: {
    name: 'IDV Results',
  },
  data_profiles: {
    name: 'Data Profiles',
    formName: 'Data profile information',
  },
};
