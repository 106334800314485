/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Operations from './mutation';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export type CreateSessionMutationFn = Apollo.MutationFunction<import('./api').CreateSessionMutation, import('./api').CreateSessionMutationVariables>;
export function useCreateSessionMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateSessionMutation, import('./api').CreateSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateSessionMutation, import('./api').CreateSessionMutationVariables>(Operations.CreateSession, options);
      }
export type CreateSessionMutationHookResult = ReturnType<typeof useCreateSessionMutation>;
export type UpdateCaseRejectReasonMutationFn = Apollo.MutationFunction<import('./api').UpdateCaseRejectReasonMutation, import('./api').UpdateCaseRejectReasonMutationVariables>;
export function useUpdateCaseRejectReasonMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateCaseRejectReasonMutation, import('./api').UpdateCaseRejectReasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateCaseRejectReasonMutation, import('./api').UpdateCaseRejectReasonMutationVariables>(Operations.UpdateCaseRejectReason, options);
      }
export type UpdateCaseRejectReasonMutationHookResult = ReturnType<typeof useUpdateCaseRejectReasonMutation>;
export type CreateCaseRejectReasonMutationFn = Apollo.MutationFunction<import('./api').CreateCaseRejectReasonMutation, import('./api').CreateCaseRejectReasonMutationVariables>;
export function useCreateCaseRejectReasonMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateCaseRejectReasonMutation, import('./api').CreateCaseRejectReasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateCaseRejectReasonMutation, import('./api').CreateCaseRejectReasonMutationVariables>(Operations.CreateCaseRejectReason, options);
      }
export type CreateCaseRejectReasonMutationHookResult = ReturnType<typeof useCreateCaseRejectReasonMutation>;
export type DeleteCaseRejectReasonMutationFn = Apollo.MutationFunction<import('./api').DeleteCaseRejectReasonMutation, import('./api').DeleteCaseRejectReasonMutationVariables>;
export function useDeleteCaseRejectReasonMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteCaseRejectReasonMutation, import('./api').DeleteCaseRejectReasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteCaseRejectReasonMutation, import('./api').DeleteCaseRejectReasonMutationVariables>(Operations.DeleteCaseRejectReason, options);
      }
export type DeleteCaseRejectReasonMutationHookResult = ReturnType<typeof useDeleteCaseRejectReasonMutation>;
export type FormTagUpdateMutationFn = Apollo.MutationFunction<import('./api').FormTagUpdateMutation, import('./api').FormTagUpdateMutationVariables>;
export function useFormTagUpdateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').FormTagUpdateMutation, import('./api').FormTagUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').FormTagUpdateMutation, import('./api').FormTagUpdateMutationVariables>(Operations.FormTagUpdate, options);
      }
export type FormTagUpdateMutationHookResult = ReturnType<typeof useFormTagUpdateMutation>;
export type FormTagCreateMutationFn = Apollo.MutationFunction<import('./api').FormTagCreateMutation, import('./api').FormTagCreateMutationVariables>;
export function useFormTagCreateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').FormTagCreateMutation, import('./api').FormTagCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').FormTagCreateMutation, import('./api').FormTagCreateMutationVariables>(Operations.FormTagCreate, options);
      }
export type FormTagCreateMutationHookResult = ReturnType<typeof useFormTagCreateMutation>;
export type UpdateResolutionItemMutationFn = Apollo.MutationFunction<import('./api').UpdateResolutionItemMutation, import('./api').UpdateResolutionItemMutationVariables>;
export function useUpdateResolutionItemMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateResolutionItemMutation, import('./api').UpdateResolutionItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateResolutionItemMutation, import('./api').UpdateResolutionItemMutationVariables>(Operations.UpdateResolutionItem, options);
      }
export type UpdateResolutionItemMutationHookResult = ReturnType<typeof useUpdateResolutionItemMutation>;
export type CreateResolutionMutationFn = Apollo.MutationFunction<import('./api').CreateResolutionMutation, import('./api').CreateResolutionMutationVariables>;
export function useCreateResolutionMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateResolutionMutation, import('./api').CreateResolutionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateResolutionMutation, import('./api').CreateResolutionMutationVariables>(Operations.CreateResolution, options);
      }
export type CreateResolutionMutationHookResult = ReturnType<typeof useCreateResolutionMutation>;
export type DeleteResolutionMutationFn = Apollo.MutationFunction<import('./api').DeleteResolutionMutation, import('./api').DeleteResolutionMutationVariables>;
export function useDeleteResolutionMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteResolutionMutation, import('./api').DeleteResolutionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteResolutionMutation, import('./api').DeleteResolutionMutationVariables>(Operations.DeleteResolution, options);
      }
export type DeleteResolutionMutationHookResult = ReturnType<typeof useDeleteResolutionMutation>;
export type RiskScoringTemplatesUpdateMutationFn = Apollo.MutationFunction<import('./api').RiskScoringTemplatesUpdateMutation, import('./api').RiskScoringTemplatesUpdateMutationVariables>;
export function useRiskScoringTemplatesUpdateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').RiskScoringTemplatesUpdateMutation, import('./api').RiskScoringTemplatesUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').RiskScoringTemplatesUpdateMutation, import('./api').RiskScoringTemplatesUpdateMutationVariables>(Operations.RiskScoringTemplatesUpdate, options);
      }
export type RiskScoringTemplatesUpdateMutationHookResult = ReturnType<typeof useRiskScoringTemplatesUpdateMutation>;
export type RiskScoringTemplateCreateMutationFn = Apollo.MutationFunction<import('./api').RiskScoringTemplateCreateMutation, import('./api').RiskScoringTemplateCreateMutationVariables>;
export function useRiskScoringTemplateCreateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').RiskScoringTemplateCreateMutation, import('./api').RiskScoringTemplateCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').RiskScoringTemplateCreateMutation, import('./api').RiskScoringTemplateCreateMutationVariables>(Operations.RiskScoringTemplateCreate, options);
      }
export type RiskScoringTemplateCreateMutationHookResult = ReturnType<typeof useRiskScoringTemplateCreateMutation>;
export type RiskScoringTemplateDeleteMutationFn = Apollo.MutationFunction<import('./api').RiskScoringTemplateDeleteMutation, import('./api').RiskScoringTemplateDeleteMutationVariables>;
export function useRiskScoringTemplateDeleteMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').RiskScoringTemplateDeleteMutation, import('./api').RiskScoringTemplateDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').RiskScoringTemplateDeleteMutation, import('./api').RiskScoringTemplateDeleteMutationVariables>(Operations.RiskScoringTemplateDelete, options);
      }
export type RiskScoringTemplateDeleteMutationHookResult = ReturnType<typeof useRiskScoringTemplateDeleteMutation>;
export type RoleUpdateMutationFn = Apollo.MutationFunction<import('./api').RoleUpdateMutation, import('./api').RoleUpdateMutationVariables>;
export function useRoleUpdateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').RoleUpdateMutation, import('./api').RoleUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').RoleUpdateMutation, import('./api').RoleUpdateMutationVariables>(Operations.RoleUpdate, options);
      }
export type RoleUpdateMutationHookResult = ReturnType<typeof useRoleUpdateMutation>;
export type RoleCopyMutationFn = Apollo.MutationFunction<import('./api').RoleCopyMutation, import('./api').RoleCopyMutationVariables>;
export function useRoleCopyMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').RoleCopyMutation, import('./api').RoleCopyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').RoleCopyMutation, import('./api').RoleCopyMutationVariables>(Operations.RoleCopy, options);
      }
export type RoleCopyMutationHookResult = ReturnType<typeof useRoleCopyMutation>;
export type RoleCreateMutationFn = Apollo.MutationFunction<import('./api').RoleCreateMutation, import('./api').RoleCreateMutationVariables>;
export function useRoleCreateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').RoleCreateMutation, import('./api').RoleCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').RoleCreateMutation, import('./api').RoleCreateMutationVariables>(Operations.RoleCreate, options);
      }
export type RoleCreateMutationHookResult = ReturnType<typeof useRoleCreateMutation>;
export type DeleteRoleMutationFn = Apollo.MutationFunction<import('./api').DeleteRoleMutation, import('./api').DeleteRoleMutationVariables>;
export function useDeleteRoleMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteRoleMutation, import('./api').DeleteRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteRoleMutation, import('./api').DeleteRoleMutationVariables>(Operations.DeleteRole, options);
      }
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type UserRolesUpdateMutationFn = Apollo.MutationFunction<import('./api').UserRolesUpdateMutation, import('./api').UserRolesUpdateMutationVariables>;
export function useUserRolesUpdateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UserRolesUpdateMutation, import('./api').UserRolesUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UserRolesUpdateMutation, import('./api').UserRolesUpdateMutationVariables>(Operations.UserRolesUpdate, options);
      }
export type UserRolesUpdateMutationHookResult = ReturnType<typeof useUserRolesUpdateMutation>;
export type ToggleEnabledUserMutationFn = Apollo.MutationFunction<import('./api').ToggleEnabledUserMutation, import('./api').ToggleEnabledUserMutationVariables>;
export function useToggleEnabledUserMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ToggleEnabledUserMutation, import('./api').ToggleEnabledUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ToggleEnabledUserMutation, import('./api').ToggleEnabledUserMutationVariables>(Operations.ToggleEnabledUser, options);
      }
export type ToggleEnabledUserMutationHookResult = ReturnType<typeof useToggleEnabledUserMutation>;
export type UpdateAccountSettingsMutationFn = Apollo.MutationFunction<import('./api').UpdateAccountSettingsMutation, import('./api').UpdateAccountSettingsMutationVariables>;
export function useUpdateAccountSettingsMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateAccountSettingsMutation, import('./api').UpdateAccountSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateAccountSettingsMutation, import('./api').UpdateAccountSettingsMutationVariables>(Operations.UpdateAccountSettings, options);
      }
export type UpdateAccountSettingsMutationHookResult = ReturnType<typeof useUpdateAccountSettingsMutation>;
export type AccountUpdateMutationFn = Apollo.MutationFunction<import('./api').AccountUpdateMutation, import('./api').AccountUpdateMutationVariables>;
export function useAccountUpdateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').AccountUpdateMutation, import('./api').AccountUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').AccountUpdateMutation, import('./api').AccountUpdateMutationVariables>(Operations.AccountUpdate, options);
      }
export type AccountUpdateMutationHookResult = ReturnType<typeof useAccountUpdateMutation>;
export type BlacklistReasonUpdateMutationFn = Apollo.MutationFunction<import('./api').BlacklistReasonUpdateMutation, import('./api').BlacklistReasonUpdateMutationVariables>;
export function useBlacklistReasonUpdateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').BlacklistReasonUpdateMutation, import('./api').BlacklistReasonUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').BlacklistReasonUpdateMutation, import('./api').BlacklistReasonUpdateMutationVariables>(Operations.BlacklistReasonUpdate, options);
      }
export type BlacklistReasonUpdateMutationHookResult = ReturnType<typeof useBlacklistReasonUpdateMutation>;
export type BlacklistReasonCreateMutationFn = Apollo.MutationFunction<import('./api').BlacklistReasonCreateMutation, import('./api').BlacklistReasonCreateMutationVariables>;
export function useBlacklistReasonCreateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').BlacklistReasonCreateMutation, import('./api').BlacklistReasonCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').BlacklistReasonCreateMutation, import('./api').BlacklistReasonCreateMutationVariables>(Operations.BlacklistReasonCreate, options);
      }
export type BlacklistReasonCreateMutationHookResult = ReturnType<typeof useBlacklistReasonCreateMutation>;
export type BlacklistReasonDeleteMutationFn = Apollo.MutationFunction<import('./api').BlacklistReasonDeleteMutation, import('./api').BlacklistReasonDeleteMutationVariables>;
export function useBlacklistReasonDeleteMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').BlacklistReasonDeleteMutation, import('./api').BlacklistReasonDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').BlacklistReasonDeleteMutation, import('./api').BlacklistReasonDeleteMutationVariables>(Operations.BlacklistReasonDelete, options);
      }
export type BlacklistReasonDeleteMutationHookResult = ReturnType<typeof useBlacklistReasonDeleteMutation>;
export type CheckupUpdateMutationFn = Apollo.MutationFunction<import('./api').CheckupUpdateMutation, import('./api').CheckupUpdateMutationVariables>;
export function useCheckupUpdateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CheckupUpdateMutation, import('./api').CheckupUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CheckupUpdateMutation, import('./api').CheckupUpdateMutationVariables>(Operations.CheckupUpdate, options);
      }
export type CheckupUpdateMutationHookResult = ReturnType<typeof useCheckupUpdateMutation>;
export type ToggleClientCheckupKindMutationFn = Apollo.MutationFunction<import('./api').ToggleClientCheckupKindMutation, import('./api').ToggleClientCheckupKindMutationVariables>;
export function useToggleClientCheckupKindMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ToggleClientCheckupKindMutation, import('./api').ToggleClientCheckupKindMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ToggleClientCheckupKindMutation, import('./api').ToggleClientCheckupKindMutationVariables>(Operations.ToggleClientCheckupKind, options);
      }
export type ToggleClientCheckupKindMutationHookResult = ReturnType<typeof useToggleClientCheckupKindMutation>;
export type ToggleClientProviderMutationFn = Apollo.MutationFunction<import('./api').ToggleClientProviderMutation, import('./api').ToggleClientProviderMutationVariables>;
export function useToggleClientProviderMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ToggleClientProviderMutation, import('./api').ToggleClientProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ToggleClientProviderMutation, import('./api').ToggleClientProviderMutationVariables>(Operations.ToggleClientProvider, options);
      }
export type ToggleClientProviderMutationHookResult = ReturnType<typeof useToggleClientProviderMutation>;
export type ClientUpdateMutationFn = Apollo.MutationFunction<import('./api').ClientUpdateMutation, import('./api').ClientUpdateMutationVariables>;
export function useClientUpdateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ClientUpdateMutation, import('./api').ClientUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ClientUpdateMutation, import('./api').ClientUpdateMutationVariables>(Operations.ClientUpdate, options);
      }
export type ClientUpdateMutationHookResult = ReturnType<typeof useClientUpdateMutation>;
export type ClientCreateMutationFn = Apollo.MutationFunction<import('./api').ClientCreateMutation, import('./api').ClientCreateMutationVariables>;
export function useClientCreateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ClientCreateMutation, import('./api').ClientCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ClientCreateMutation, import('./api').ClientCreateMutationVariables>(Operations.ClientCreate, options);
      }
export type ClientCreateMutationHookResult = ReturnType<typeof useClientCreateMutation>;
export type ProviderCredentialsUpdateMutationFn = Apollo.MutationFunction<import('./api').ProviderCredentialsUpdateMutation, import('./api').ProviderCredentialsUpdateMutationVariables>;
export function useProviderCredentialsUpdateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ProviderCredentialsUpdateMutation, import('./api').ProviderCredentialsUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ProviderCredentialsUpdateMutation, import('./api').ProviderCredentialsUpdateMutationVariables>(Operations.ProviderCredentialsUpdate, options);
      }
export type ProviderCredentialsUpdateMutationHookResult = ReturnType<typeof useProviderCredentialsUpdateMutation>;
export type ClientProviderCredentialsUpdateMutationFn = Apollo.MutationFunction<import('./api').ClientProviderCredentialsUpdateMutation, import('./api').ClientProviderCredentialsUpdateMutationVariables>;
export function useClientProviderCredentialsUpdateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ClientProviderCredentialsUpdateMutation, import('./api').ClientProviderCredentialsUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ClientProviderCredentialsUpdateMutation, import('./api').ClientProviderCredentialsUpdateMutationVariables>(Operations.ClientProviderCredentialsUpdate, options);
      }
export type ClientProviderCredentialsUpdateMutationHookResult = ReturnType<typeof useClientProviderCredentialsUpdateMutation>;
export type CheckupKindCredentialsUpdateMutationFn = Apollo.MutationFunction<import('./api').CheckupKindCredentialsUpdateMutation, import('./api').CheckupKindCredentialsUpdateMutationVariables>;
export function useCheckupKindCredentialsUpdateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CheckupKindCredentialsUpdateMutation, import('./api').CheckupKindCredentialsUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CheckupKindCredentialsUpdateMutation, import('./api').CheckupKindCredentialsUpdateMutationVariables>(Operations.CheckupKindCredentialsUpdate, options);
      }
export type CheckupKindCredentialsUpdateMutationHookResult = ReturnType<typeof useCheckupKindCredentialsUpdateMutation>;
export type ClientCheckupKindCredentialsUpdateMutationFn = Apollo.MutationFunction<import('./api').ClientCheckupKindCredentialsUpdateMutation, import('./api').ClientCheckupKindCredentialsUpdateMutationVariables>;
export function useClientCheckupKindCredentialsUpdateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ClientCheckupKindCredentialsUpdateMutation, import('./api').ClientCheckupKindCredentialsUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ClientCheckupKindCredentialsUpdateMutation, import('./api').ClientCheckupKindCredentialsUpdateMutationVariables>(Operations.ClientCheckupKindCredentialsUpdate, options);
      }
export type ClientCheckupKindCredentialsUpdateMutationHookResult = ReturnType<typeof useClientCheckupKindCredentialsUpdateMutation>;
export type FieldTemplateUpdateMutationFn = Apollo.MutationFunction<import('./api').FieldTemplateUpdateMutation, import('./api').FieldTemplateUpdateMutationVariables>;
export function useFieldTemplateUpdateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').FieldTemplateUpdateMutation, import('./api').FieldTemplateUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').FieldTemplateUpdateMutation, import('./api').FieldTemplateUpdateMutationVariables>(Operations.FieldTemplateUpdate, options);
      }
export type FieldTemplateUpdateMutationHookResult = ReturnType<typeof useFieldTemplateUpdateMutation>;
export type FieldTemplateCreateMutationFn = Apollo.MutationFunction<import('./api').FieldTemplateCreateMutation, import('./api').FieldTemplateCreateMutationVariables>;
export function useFieldTemplateCreateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').FieldTemplateCreateMutation, import('./api').FieldTemplateCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').FieldTemplateCreateMutation, import('./api').FieldTemplateCreateMutationVariables>(Operations.FieldTemplateCreate, options);
      }
export type FieldTemplateCreateMutationHookResult = ReturnType<typeof useFieldTemplateCreateMutation>;
export type ToggleFormTemplateMutationFn = Apollo.MutationFunction<import('./api').ToggleFormTemplateMutation, import('./api').ToggleFormTemplateMutationVariables>;
export function useToggleFormTemplateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ToggleFormTemplateMutation, import('./api').ToggleFormTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ToggleFormTemplateMutation, import('./api').ToggleFormTemplateMutationVariables>(Operations.ToggleFormTemplate, options);
      }
export type ToggleFormTemplateMutationHookResult = ReturnType<typeof useToggleFormTemplateMutation>;
export type InstantCheckupUpdateMutationFn = Apollo.MutationFunction<import('./api').InstantCheckupUpdateMutation, import('./api').InstantCheckupUpdateMutationVariables>;
export function useInstantCheckupUpdateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').InstantCheckupUpdateMutation, import('./api').InstantCheckupUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').InstantCheckupUpdateMutation, import('./api').InstantCheckupUpdateMutationVariables>(Operations.InstantCheckupUpdate, options);
      }
export type InstantCheckupUpdateMutationHookResult = ReturnType<typeof useInstantCheckupUpdateMutation>;
export type RegionUpdateMutationFn = Apollo.MutationFunction<import('./api').RegionUpdateMutation, import('./api').RegionUpdateMutationVariables>;
export function useRegionUpdateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').RegionUpdateMutation, import('./api').RegionUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').RegionUpdateMutation, import('./api').RegionUpdateMutationVariables>(Operations.RegionUpdate, options);
      }
export type RegionUpdateMutationHookResult = ReturnType<typeof useRegionUpdateMutation>;
export type UpdateStopWordMutationFn = Apollo.MutationFunction<import('./api').UpdateStopWordMutation, import('./api').UpdateStopWordMutationVariables>;
export function useUpdateStopWordMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateStopWordMutation, import('./api').UpdateStopWordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateStopWordMutation, import('./api').UpdateStopWordMutationVariables>(Operations.UpdateStopWord, options);
      }
export type UpdateStopWordMutationHookResult = ReturnType<typeof useUpdateStopWordMutation>;
export type CreateStopWordMutationFn = Apollo.MutationFunction<import('./api').CreateStopWordMutation, import('./api').CreateStopWordMutationVariables>;
export function useCreateStopWordMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateStopWordMutation, import('./api').CreateStopWordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateStopWordMutation, import('./api').CreateStopWordMutationVariables>(Operations.CreateStopWord, options);
      }
export type CreateStopWordMutationHookResult = ReturnType<typeof useCreateStopWordMutation>;
export type DeleteStopWordMutationFn = Apollo.MutationFunction<import('./api').DeleteStopWordMutation, import('./api').DeleteStopWordMutationVariables>;
export function useDeleteStopWordMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteStopWordMutation, import('./api').DeleteStopWordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteStopWordMutation, import('./api').DeleteStopWordMutationVariables>(Operations.DeleteStopWord, options);
      }
export type DeleteStopWordMutationHookResult = ReturnType<typeof useDeleteStopWordMutation>;
export type CreateAiTaskConfigMutationFn = Apollo.MutationFunction<import('./api').CreateAiTaskConfigMutation, import('./api').CreateAiTaskConfigMutationVariables>;
export function useCreateAiTaskConfigMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateAiTaskConfigMutation, import('./api').CreateAiTaskConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateAiTaskConfigMutation, import('./api').CreateAiTaskConfigMutationVariables>(Operations.CreateAITaskConfig, options);
      }
export type CreateAiTaskConfigMutationHookResult = ReturnType<typeof useCreateAiTaskConfigMutation>;
export type UpdateAiTaskConfigMutationFn = Apollo.MutationFunction<import('./api').UpdateAiTaskConfigMutation, import('./api').UpdateAiTaskConfigMutationVariables>;
export function useUpdateAiTaskConfigMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateAiTaskConfigMutation, import('./api').UpdateAiTaskConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateAiTaskConfigMutation, import('./api').UpdateAiTaskConfigMutationVariables>(Operations.UpdateAITaskConfig, options);
      }
export type UpdateAiTaskConfigMutationHookResult = ReturnType<typeof useUpdateAiTaskConfigMutation>;
export type DeleteAiTaskConfigMutationFn = Apollo.MutationFunction<import('./api').DeleteAiTaskConfigMutation, import('./api').DeleteAiTaskConfigMutationVariables>;
export function useDeleteAiTaskConfigMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteAiTaskConfigMutation, import('./api').DeleteAiTaskConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteAiTaskConfigMutation, import('./api').DeleteAiTaskConfigMutationVariables>(Operations.DeleteAITaskConfig, options);
      }
export type DeleteAiTaskConfigMutationHookResult = ReturnType<typeof useDeleteAiTaskConfigMutation>;
export type MarkAsActualAiTaskResultMutationFn = Apollo.MutationFunction<import('./api').MarkAsActualAiTaskResultMutation, import('./api').MarkAsActualAiTaskResultMutationVariables>;
export function useMarkAsActualAiTaskResultMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').MarkAsActualAiTaskResultMutation, import('./api').MarkAsActualAiTaskResultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').MarkAsActualAiTaskResultMutation, import('./api').MarkAsActualAiTaskResultMutationVariables>(Operations.MarkAsActualAITaskResult, options);
      }
export type MarkAsActualAiTaskResultMutationHookResult = ReturnType<typeof useMarkAsActualAiTaskResultMutation>;
export type MarkAsIrrelevantAiTaskResultMutationFn = Apollo.MutationFunction<import('./api').MarkAsIrrelevantAiTaskResultMutation, import('./api').MarkAsIrrelevantAiTaskResultMutationVariables>;
export function useMarkAsIrrelevantAiTaskResultMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').MarkAsIrrelevantAiTaskResultMutation, import('./api').MarkAsIrrelevantAiTaskResultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').MarkAsIrrelevantAiTaskResultMutation, import('./api').MarkAsIrrelevantAiTaskResultMutationVariables>(Operations.MarkAsIrrelevantAITaskResult, options);
      }
export type MarkAsIrrelevantAiTaskResultMutationHookResult = ReturnType<typeof useMarkAsIrrelevantAiTaskResultMutation>;
export type UpdateAiTaskResultMutationFn = Apollo.MutationFunction<import('./api').UpdateAiTaskResultMutation, import('./api').UpdateAiTaskResultMutationVariables>;
export function useUpdateAiTaskResultMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateAiTaskResultMutation, import('./api').UpdateAiTaskResultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateAiTaskResultMutation, import('./api').UpdateAiTaskResultMutationVariables>(Operations.UpdateAITaskResult, options);
      }
export type UpdateAiTaskResultMutationHookResult = ReturnType<typeof useUpdateAiTaskResultMutation>;
export type CreateAiTaskMutationFn = Apollo.MutationFunction<import('./api').CreateAiTaskMutation, import('./api').CreateAiTaskMutationVariables>;
export function useCreateAiTaskMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateAiTaskMutation, import('./api').CreateAiTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateAiTaskMutation, import('./api').CreateAiTaskMutationVariables>(Operations.CreateAITask, options);
      }
export type CreateAiTaskMutationHookResult = ReturnType<typeof useCreateAiTaskMutation>;
export type UpdateAiTaskMutationFn = Apollo.MutationFunction<import('./api').UpdateAiTaskMutation, import('./api').UpdateAiTaskMutationVariables>;
export function useUpdateAiTaskMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateAiTaskMutation, import('./api').UpdateAiTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateAiTaskMutation, import('./api').UpdateAiTaskMutationVariables>(Operations.UpdateAITask, options);
      }
export type UpdateAiTaskMutationHookResult = ReturnType<typeof useUpdateAiTaskMutation>;
export type DeleteAiTaskMutationFn = Apollo.MutationFunction<import('./api').DeleteAiTaskMutation, import('./api').DeleteAiTaskMutationVariables>;
export function useDeleteAiTaskMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteAiTaskMutation, import('./api').DeleteAiTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteAiTaskMutation, import('./api').DeleteAiTaskMutationVariables>(Operations.DeleteAITask, options);
      }
export type DeleteAiTaskMutationHookResult = ReturnType<typeof useDeleteAiTaskMutation>;
export type CreateCaseQuestionMutationFn = Apollo.MutationFunction<import('./api').CreateCaseQuestionMutation, import('./api').CreateCaseQuestionMutationVariables>;
export function useCreateCaseQuestionMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateCaseQuestionMutation, import('./api').CreateCaseQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateCaseQuestionMutation, import('./api').CreateCaseQuestionMutationVariables>(Operations.CreateCaseQuestion, options);
      }
export type CreateCaseQuestionMutationHookResult = ReturnType<typeof useCreateCaseQuestionMutation>;
export type MarkAsActualCaseQuestionMutationFn = Apollo.MutationFunction<import('./api').MarkAsActualCaseQuestionMutation, import('./api').MarkAsActualCaseQuestionMutationVariables>;
export function useMarkAsActualCaseQuestionMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').MarkAsActualCaseQuestionMutation, import('./api').MarkAsActualCaseQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').MarkAsActualCaseQuestionMutation, import('./api').MarkAsActualCaseQuestionMutationVariables>(Operations.MarkAsActualCaseQuestion, options);
      }
export type MarkAsActualCaseQuestionMutationHookResult = ReturnType<typeof useMarkAsActualCaseQuestionMutation>;
export type MarkAsIrrelevantCaseQuestionMutationFn = Apollo.MutationFunction<import('./api').MarkAsIrrelevantCaseQuestionMutation, import('./api').MarkAsIrrelevantCaseQuestionMutationVariables>;
export function useMarkAsIrrelevantCaseQuestionMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').MarkAsIrrelevantCaseQuestionMutation, import('./api').MarkAsIrrelevantCaseQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').MarkAsIrrelevantCaseQuestionMutation, import('./api').MarkAsIrrelevantCaseQuestionMutationVariables>(Operations.MarkAsIrrelevantCaseQuestion, options);
      }
export type MarkAsIrrelevantCaseQuestionMutationHookResult = ReturnType<typeof useMarkAsIrrelevantCaseQuestionMutation>;
export type CreateCaseQuestionConfigMutationFn = Apollo.MutationFunction<import('./api').CreateCaseQuestionConfigMutation, import('./api').CreateCaseQuestionConfigMutationVariables>;
export function useCreateCaseQuestionConfigMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateCaseQuestionConfigMutation, import('./api').CreateCaseQuestionConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateCaseQuestionConfigMutation, import('./api').CreateCaseQuestionConfigMutationVariables>(Operations.CreateCaseQuestionConfig, options);
      }
export type CreateCaseQuestionConfigMutationHookResult = ReturnType<typeof useCreateCaseQuestionConfigMutation>;
export type UpdateCaseQuestionConfigMutationFn = Apollo.MutationFunction<import('./api').UpdateCaseQuestionConfigMutation, import('./api').UpdateCaseQuestionConfigMutationVariables>;
export function useUpdateCaseQuestionConfigMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateCaseQuestionConfigMutation, import('./api').UpdateCaseQuestionConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateCaseQuestionConfigMutation, import('./api').UpdateCaseQuestionConfigMutationVariables>(Operations.UpdateCaseQuestionConfig, options);
      }
export type UpdateCaseQuestionConfigMutationHookResult = ReturnType<typeof useUpdateCaseQuestionConfigMutation>;
export type DeleteCaseQuestionConfigMutationFn = Apollo.MutationFunction<import('./api').DeleteCaseQuestionConfigMutation, import('./api').DeleteCaseQuestionConfigMutationVariables>;
export function useDeleteCaseQuestionConfigMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteCaseQuestionConfigMutation, import('./api').DeleteCaseQuestionConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteCaseQuestionConfigMutation, import('./api').DeleteCaseQuestionConfigMutationVariables>(Operations.DeleteCaseQuestionConfig, options);
      }
export type DeleteCaseQuestionConfigMutationHookResult = ReturnType<typeof useDeleteCaseQuestionConfigMutation>;
export type CreateAiSummaryConfigMutationFn = Apollo.MutationFunction<import('./api').CreateAiSummaryConfigMutation, import('./api').CreateAiSummaryConfigMutationVariables>;
export function useCreateAiSummaryConfigMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateAiSummaryConfigMutation, import('./api').CreateAiSummaryConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateAiSummaryConfigMutation, import('./api').CreateAiSummaryConfigMutationVariables>(Operations.CreateAISummaryConfig, options);
      }
export type CreateAiSummaryConfigMutationHookResult = ReturnType<typeof useCreateAiSummaryConfigMutation>;
export type UpdateAiSummaryConfigMutationFn = Apollo.MutationFunction<import('./api').UpdateAiSummaryConfigMutation, import('./api').UpdateAiSummaryConfigMutationVariables>;
export function useUpdateAiSummaryConfigMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateAiSummaryConfigMutation, import('./api').UpdateAiSummaryConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateAiSummaryConfigMutation, import('./api').UpdateAiSummaryConfigMutationVariables>(Operations.UpdateAISummaryConfig, options);
      }
export type UpdateAiSummaryConfigMutationHookResult = ReturnType<typeof useUpdateAiSummaryConfigMutation>;
export type DeleteAiSummaryConfigMutationFn = Apollo.MutationFunction<import('./api').DeleteAiSummaryConfigMutation, import('./api').DeleteAiSummaryConfigMutationVariables>;
export function useDeleteAiSummaryConfigMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteAiSummaryConfigMutation, import('./api').DeleteAiSummaryConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteAiSummaryConfigMutation, import('./api').DeleteAiSummaryConfigMutationVariables>(Operations.DeleteAISummaryConfig, options);
      }
export type DeleteAiSummaryConfigMutationHookResult = ReturnType<typeof useDeleteAiSummaryConfigMutation>;
export type MarkAsActualRevisionMutationFn = Apollo.MutationFunction<import('./api').MarkAsActualRevisionMutation, import('./api').MarkAsActualRevisionMutationVariables>;
export function useMarkAsActualRevisionMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').MarkAsActualRevisionMutation, import('./api').MarkAsActualRevisionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').MarkAsActualRevisionMutation, import('./api').MarkAsActualRevisionMutationVariables>(Operations.MarkAsActualRevision, options);
      }
export type MarkAsActualRevisionMutationHookResult = ReturnType<typeof useMarkAsActualRevisionMutation>;
export type MarkAsIrrelevantRevisionMutationFn = Apollo.MutationFunction<import('./api').MarkAsIrrelevantRevisionMutation, import('./api').MarkAsIrrelevantRevisionMutationVariables>;
export function useMarkAsIrrelevantRevisionMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').MarkAsIrrelevantRevisionMutation, import('./api').MarkAsIrrelevantRevisionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').MarkAsIrrelevantRevisionMutation, import('./api').MarkAsIrrelevantRevisionMutationVariables>(Operations.MarkAsIrrelevantRevision, options);
      }
export type MarkAsIrrelevantRevisionMutationHookResult = ReturnType<typeof useMarkAsIrrelevantRevisionMutation>;
export type CreateAiSummaryRevisionMutationFn = Apollo.MutationFunction<import('./api').CreateAiSummaryRevisionMutation, import('./api').CreateAiSummaryRevisionMutationVariables>;
export function useCreateAiSummaryRevisionMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateAiSummaryRevisionMutation, import('./api').CreateAiSummaryRevisionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateAiSummaryRevisionMutation, import('./api').CreateAiSummaryRevisionMutationVariables>(Operations.CreateAISummaryRevision, options);
      }
export type CreateAiSummaryRevisionMutationHookResult = ReturnType<typeof useCreateAiSummaryRevisionMutation>;
export type CreateAuditMutationFn = Apollo.MutationFunction<import('./api').CreateAuditMutation, import('./api').CreateAuditMutationVariables>;
export function useCreateAuditMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateAuditMutation, import('./api').CreateAuditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateAuditMutation, import('./api').CreateAuditMutationVariables>(Operations.CreateAudit, options);
      }
export type CreateAuditMutationHookResult = ReturnType<typeof useCreateAuditMutation>;
export type ChangeAuditStatusMutationFn = Apollo.MutationFunction<import('./api').ChangeAuditStatusMutation, import('./api').ChangeAuditStatusMutationVariables>;
export function useChangeAuditStatusMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ChangeAuditStatusMutation, import('./api').ChangeAuditStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ChangeAuditStatusMutation, import('./api').ChangeAuditStatusMutationVariables>(Operations.ChangeAuditStatus, options);
      }
export type ChangeAuditStatusMutationHookResult = ReturnType<typeof useChangeAuditStatusMutation>;
export type CreateBlacklistedDeviceMutationFn = Apollo.MutationFunction<import('./api').CreateBlacklistedDeviceMutation, import('./api').CreateBlacklistedDeviceMutationVariables>;
export function useCreateBlacklistedDeviceMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateBlacklistedDeviceMutation, import('./api').CreateBlacklistedDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateBlacklistedDeviceMutation, import('./api').CreateBlacklistedDeviceMutationVariables>(Operations.CreateBlacklistedDevice, options);
      }
export type CreateBlacklistedDeviceMutationHookResult = ReturnType<typeof useCreateBlacklistedDeviceMutation>;
export type DeleteBlacklistedDeviceMutationFn = Apollo.MutationFunction<import('./api').DeleteBlacklistedDeviceMutation, import('./api').DeleteBlacklistedDeviceMutationVariables>;
export function useDeleteBlacklistedDeviceMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteBlacklistedDeviceMutation, import('./api').DeleteBlacklistedDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteBlacklistedDeviceMutation, import('./api').DeleteBlacklistedDeviceMutationVariables>(Operations.DeleteBlacklistedDevice, options);
      }
export type DeleteBlacklistedDeviceMutationHookResult = ReturnType<typeof useDeleteBlacklistedDeviceMutation>;
export type CreateCaseConfigMutationFn = Apollo.MutationFunction<import('./api').CreateCaseConfigMutation, import('./api').CreateCaseConfigMutationVariables>;
export function useCreateCaseConfigMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateCaseConfigMutation, import('./api').CreateCaseConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateCaseConfigMutation, import('./api').CreateCaseConfigMutationVariables>(Operations.CreateCaseConfig, options);
      }
export type CreateCaseConfigMutationHookResult = ReturnType<typeof useCreateCaseConfigMutation>;
export type UpdateCaseConfigMutationFn = Apollo.MutationFunction<import('./api').UpdateCaseConfigMutation, import('./api').UpdateCaseConfigMutationVariables>;
export function useUpdateCaseConfigMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateCaseConfigMutation, import('./api').UpdateCaseConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateCaseConfigMutation, import('./api').UpdateCaseConfigMutationVariables>(Operations.UpdateCaseConfig, options);
      }
export type UpdateCaseConfigMutationHookResult = ReturnType<typeof useUpdateCaseConfigMutation>;
export type CreateCdsCableRefreshTaskMutationFn = Apollo.MutationFunction<import('./api').CreateCdsCableRefreshTaskMutation, import('./api').CreateCdsCableRefreshTaskMutationVariables>;
export function useCreateCdsCableRefreshTaskMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateCdsCableRefreshTaskMutation, import('./api').CreateCdsCableRefreshTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateCdsCableRefreshTaskMutation, import('./api').CreateCdsCableRefreshTaskMutationVariables>(Operations.CreateCdsCableRefreshTask, options);
      }
export type CreateCdsCableRefreshTaskMutationHookResult = ReturnType<typeof useCreateCdsCableRefreshTaskMutation>;
export type CreateCdsCableCsvExportMutationFn = Apollo.MutationFunction<import('./api').CreateCdsCableCsvExportMutation, import('./api').CreateCdsCableCsvExportMutationVariables>;
export function useCreateCdsCableCsvExportMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateCdsCableCsvExportMutation, import('./api').CreateCdsCableCsvExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateCdsCableCsvExportMutation, import('./api').CreateCdsCableCsvExportMutationVariables>(Operations.CreateCdsCableCsvExport, options);
      }
export type CreateCdsCableCsvExportMutationHookResult = ReturnType<typeof useCreateCdsCableCsvExportMutation>;
export type CreateCdsCableCsvExportAllMutationFn = Apollo.MutationFunction<import('./api').CreateCdsCableCsvExportAllMutation, import('./api').CreateCdsCableCsvExportAllMutationVariables>;
export function useCreateCdsCableCsvExportAllMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateCdsCableCsvExportAllMutation, import('./api').CreateCdsCableCsvExportAllMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateCdsCableCsvExportAllMutation, import('./api').CreateCdsCableCsvExportAllMutationVariables>(Operations.CreateCdsCableCsvExportAll, options);
      }
export type CreateCdsCableCsvExportAllMutationHookResult = ReturnType<typeof useCreateCdsCableCsvExportAllMutation>;
export type UploadToCableCsvExportMutationFn = Apollo.MutationFunction<import('./api').UploadToCableCsvExportMutation, import('./api').UploadToCableCsvExportMutationVariables>;
export function useUploadToCableCsvExportMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UploadToCableCsvExportMutation, import('./api').UploadToCableCsvExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UploadToCableCsvExportMutation, import('./api').UploadToCableCsvExportMutationVariables>(Operations.UploadToCableCsvExport, options);
      }
export type UploadToCableCsvExportMutationHookResult = ReturnType<typeof useUploadToCableCsvExportMutation>;
export type CreateCdsEntityTemplateMutationFn = Apollo.MutationFunction<import('./api').CreateCdsEntityTemplateMutation, import('./api').CreateCdsEntityTemplateMutationVariables>;
export function useCreateCdsEntityTemplateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateCdsEntityTemplateMutation, import('./api').CreateCdsEntityTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateCdsEntityTemplateMutation, import('./api').CreateCdsEntityTemplateMutationVariables>(Operations.CreateCdsEntityTemplate, options);
      }
export type CreateCdsEntityTemplateMutationHookResult = ReturnType<typeof useCreateCdsEntityTemplateMutation>;
export type UpdateCdsEntityTemplateMutationFn = Apollo.MutationFunction<import('./api').UpdateCdsEntityTemplateMutation, import('./api').UpdateCdsEntityTemplateMutationVariables>;
export function useUpdateCdsEntityTemplateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateCdsEntityTemplateMutation, import('./api').UpdateCdsEntityTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateCdsEntityTemplateMutation, import('./api').UpdateCdsEntityTemplateMutationVariables>(Operations.UpdateCdsEntityTemplate, options);
      }
export type UpdateCdsEntityTemplateMutationHookResult = ReturnType<typeof useUpdateCdsEntityTemplateMutation>;
export type DeleteCdsEntityTemplateMutationFn = Apollo.MutationFunction<import('./api').DeleteCdsEntityTemplateMutation, import('./api').DeleteCdsEntityTemplateMutationVariables>;
export function useDeleteCdsEntityTemplateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteCdsEntityTemplateMutation, import('./api').DeleteCdsEntityTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteCdsEntityTemplateMutation, import('./api').DeleteCdsEntityTemplateMutationVariables>(Operations.DeleteCdsEntityTemplate, options);
      }
export type DeleteCdsEntityTemplateMutationHookResult = ReturnType<typeof useDeleteCdsEntityTemplateMutation>;
export type CreateCdsFieldMappingMutationFn = Apollo.MutationFunction<import('./api').CreateCdsFieldMappingMutation, import('./api').CreateCdsFieldMappingMutationVariables>;
export function useCreateCdsFieldMappingMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateCdsFieldMappingMutation, import('./api').CreateCdsFieldMappingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateCdsFieldMappingMutation, import('./api').CreateCdsFieldMappingMutationVariables>(Operations.CreateCdsFieldMapping, options);
      }
export type CreateCdsFieldMappingMutationHookResult = ReturnType<typeof useCreateCdsFieldMappingMutation>;
export type UpdateCdsFieldMappingMutationFn = Apollo.MutationFunction<import('./api').UpdateCdsFieldMappingMutation, import('./api').UpdateCdsFieldMappingMutationVariables>;
export function useUpdateCdsFieldMappingMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateCdsFieldMappingMutation, import('./api').UpdateCdsFieldMappingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateCdsFieldMappingMutation, import('./api').UpdateCdsFieldMappingMutationVariables>(Operations.UpdateCdsFieldMapping, options);
      }
export type UpdateCdsFieldMappingMutationHookResult = ReturnType<typeof useUpdateCdsFieldMappingMutation>;
export type DeleteCdsFieldMappingMutationFn = Apollo.MutationFunction<import('./api').DeleteCdsFieldMappingMutation, import('./api').DeleteCdsFieldMappingMutationVariables>;
export function useDeleteCdsFieldMappingMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteCdsFieldMappingMutation, import('./api').DeleteCdsFieldMappingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteCdsFieldMappingMutation, import('./api').DeleteCdsFieldMappingMutationVariables>(Operations.DeleteCdsFieldMapping, options);
      }
export type DeleteCdsFieldMappingMutationHookResult = ReturnType<typeof useDeleteCdsFieldMappingMutation>;
export type ChangeStateCdsFormMappingMutationFn = Apollo.MutationFunction<import('./api').ChangeStateCdsFormMappingMutation, import('./api').ChangeStateCdsFormMappingMutationVariables>;
export function useChangeStateCdsFormMappingMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ChangeStateCdsFormMappingMutation, import('./api').ChangeStateCdsFormMappingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ChangeStateCdsFormMappingMutation, import('./api').ChangeStateCdsFormMappingMutationVariables>(Operations.ChangeStateCdsFormMapping, options);
      }
export type ChangeStateCdsFormMappingMutationHookResult = ReturnType<typeof useChangeStateCdsFormMappingMutation>;
export type CreateAuditConfigMutationFn = Apollo.MutationFunction<import('./api').CreateAuditConfigMutation, import('./api').CreateAuditConfigMutationVariables>;
export function useCreateAuditConfigMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateAuditConfigMutation, import('./api').CreateAuditConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateAuditConfigMutation, import('./api').CreateAuditConfigMutationVariables>(Operations.CreateAuditConfig, options);
      }
export type CreateAuditConfigMutationHookResult = ReturnType<typeof useCreateAuditConfigMutation>;
export type UpdateAuditConfigMutationFn = Apollo.MutationFunction<import('./api').UpdateAuditConfigMutation, import('./api').UpdateAuditConfigMutationVariables>;
export function useUpdateAuditConfigMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateAuditConfigMutation, import('./api').UpdateAuditConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateAuditConfigMutation, import('./api').UpdateAuditConfigMutationVariables>(Operations.UpdateAuditConfig, options);
      }
export type UpdateAuditConfigMutationHookResult = ReturnType<typeof useUpdateAuditConfigMutation>;
export type UpdateLaunchConfigMutationFn = Apollo.MutationFunction<import('./api').UpdateLaunchConfigMutation, import('./api').UpdateLaunchConfigMutationVariables>;
export function useUpdateLaunchConfigMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateLaunchConfigMutation, import('./api').UpdateLaunchConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateLaunchConfigMutation, import('./api').UpdateLaunchConfigMutationVariables>(Operations.UpdateLaunchConfig, options);
      }
export type UpdateLaunchConfigMutationHookResult = ReturnType<typeof useUpdateLaunchConfigMutation>;
export type UpdateRiskLevelMutationFn = Apollo.MutationFunction<import('./api').UpdateRiskLevelMutation, import('./api').UpdateRiskLevelMutationVariables>;
export function useUpdateRiskLevelMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateRiskLevelMutation, import('./api').UpdateRiskLevelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateRiskLevelMutation, import('./api').UpdateRiskLevelMutationVariables>(Operations.UpdateRiskLevel, options);
      }
export type UpdateRiskLevelMutationHookResult = ReturnType<typeof useUpdateRiskLevelMutation>;
export type UpdateMonitoringMutationFn = Apollo.MutationFunction<import('./api').UpdateMonitoringMutation, import('./api').UpdateMonitoringMutationVariables>;
export function useUpdateMonitoringMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateMonitoringMutation, import('./api').UpdateMonitoringMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateMonitoringMutation, import('./api').UpdateMonitoringMutationVariables>(Operations.UpdateMonitoring, options);
      }
export type UpdateMonitoringMutationHookResult = ReturnType<typeof useUpdateMonitoringMutation>;
export type UpdateHitsMutationFn = Apollo.MutationFunction<import('./api').UpdateHitsMutation, import('./api').UpdateHitsMutationVariables>;
export function useUpdateHitsMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateHitsMutation, import('./api').UpdateHitsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateHitsMutation, import('./api').UpdateHitsMutationVariables>(Operations.UpdateHits, options);
      }
export type UpdateHitsMutationHookResult = ReturnType<typeof useUpdateHitsMutation>;
export type CreateCheckupAttachmentMutationFn = Apollo.MutationFunction<import('./api').CreateCheckupAttachmentMutation, import('./api').CreateCheckupAttachmentMutationVariables>;
export function useCreateCheckupAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateCheckupAttachmentMutation, import('./api').CreateCheckupAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateCheckupAttachmentMutation, import('./api').CreateCheckupAttachmentMutationVariables>(Operations.CreateCheckupAttachment, options);
      }
export type CreateCheckupAttachmentMutationHookResult = ReturnType<typeof useCreateCheckupAttachmentMutation>;
export type UpdateCheckupAttachmentMutationFn = Apollo.MutationFunction<import('./api').UpdateCheckupAttachmentMutation, import('./api').UpdateCheckupAttachmentMutationVariables>;
export function useUpdateCheckupAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateCheckupAttachmentMutation, import('./api').UpdateCheckupAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateCheckupAttachmentMutation, import('./api').UpdateCheckupAttachmentMutationVariables>(Operations.UpdateCheckupAttachment, options);
      }
export type UpdateCheckupAttachmentMutationHookResult = ReturnType<typeof useUpdateCheckupAttachmentMutation>;
export type DeleteAttachementMutationFn = Apollo.MutationFunction<import('./api').DeleteAttachementMutation, import('./api').DeleteAttachementMutationVariables>;
export function useDeleteAttachementMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteAttachementMutation, import('./api').DeleteAttachementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteAttachementMutation, import('./api').DeleteAttachementMutationVariables>(Operations.DeleteAttachement, options);
      }
export type DeleteAttachementMutationHookResult = ReturnType<typeof useDeleteAttachementMutation>;
export type CreateCheckupConfigMutationFn = Apollo.MutationFunction<import('./api').CreateCheckupConfigMutation, import('./api').CreateCheckupConfigMutationVariables>;
export function useCreateCheckupConfigMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateCheckupConfigMutation, import('./api').CreateCheckupConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateCheckupConfigMutation, import('./api').CreateCheckupConfigMutationVariables>(Operations.CreateCheckupConfig, options);
      }
export type CreateCheckupConfigMutationHookResult = ReturnType<typeof useCreateCheckupConfigMutation>;
export type UpdateCheckupConfigMutationFn = Apollo.MutationFunction<import('./api').UpdateCheckupConfigMutation, import('./api').UpdateCheckupConfigMutationVariables>;
export function useUpdateCheckupConfigMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateCheckupConfigMutation, import('./api').UpdateCheckupConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateCheckupConfigMutation, import('./api').UpdateCheckupConfigMutationVariables>(Operations.UpdateCheckupConfig, options);
      }
export type UpdateCheckupConfigMutationHookResult = ReturnType<typeof useUpdateCheckupConfigMutation>;
export type ChangeStatusCheckupConfigMutationFn = Apollo.MutationFunction<import('./api').ChangeStatusCheckupConfigMutation, import('./api').ChangeStatusCheckupConfigMutationVariables>;
export function useChangeStatusCheckupConfigMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ChangeStatusCheckupConfigMutation, import('./api').ChangeStatusCheckupConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ChangeStatusCheckupConfigMutation, import('./api').ChangeStatusCheckupConfigMutationVariables>(Operations.ChangeStatusCheckupConfig, options);
      }
export type ChangeStatusCheckupConfigMutationHookResult = ReturnType<typeof useChangeStatusCheckupConfigMutation>;
export type DeleteCheckupConfigMutationFn = Apollo.MutationFunction<import('./api').DeleteCheckupConfigMutation, import('./api').DeleteCheckupConfigMutationVariables>;
export function useDeleteCheckupConfigMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteCheckupConfigMutation, import('./api').DeleteCheckupConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteCheckupConfigMutation, import('./api').DeleteCheckupConfigMutationVariables>(Operations.DeleteCheckupConfig, options);
      }
export type DeleteCheckupConfigMutationHookResult = ReturnType<typeof useDeleteCheckupConfigMutation>;
export type UpdateProviderStatusMutationFn = Apollo.MutationFunction<import('./api').UpdateProviderStatusMutation, import('./api').UpdateProviderStatusMutationVariables>;
export function useUpdateProviderStatusMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateProviderStatusMutation, import('./api').UpdateProviderStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateProviderStatusMutation, import('./api').UpdateProviderStatusMutationVariables>(Operations.UpdateProviderStatus, options);
      }
export type UpdateProviderStatusMutationHookResult = ReturnType<typeof useUpdateProviderStatusMutation>;
export type UpdateAuditStatusMutationFn = Apollo.MutationFunction<import('./api').UpdateAuditStatusMutation, import('./api').UpdateAuditStatusMutationVariables>;
export function useUpdateAuditStatusMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateAuditStatusMutation, import('./api').UpdateAuditStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateAuditStatusMutation, import('./api').UpdateAuditStatusMutationVariables>(Operations.UpdateAuditStatus, options);
      }
export type UpdateAuditStatusMutationHookResult = ReturnType<typeof useUpdateAuditStatusMutation>;
export type UpdateResolutionMutationFn = Apollo.MutationFunction<import('./api').UpdateResolutionMutation, import('./api').UpdateResolutionMutationVariables>;
export function useUpdateResolutionMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateResolutionMutation, import('./api').UpdateResolutionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateResolutionMutation, import('./api').UpdateResolutionMutationVariables>(Operations.UpdateResolution, options);
      }
export type UpdateResolutionMutationHookResult = ReturnType<typeof useUpdateResolutionMutation>;
export type UpdateCheckupProfilesMutationFn = Apollo.MutationFunction<import('./api').UpdateCheckupProfilesMutation, import('./api').UpdateCheckupProfilesMutationVariables>;
export function useUpdateCheckupProfilesMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateCheckupProfilesMutation, import('./api').UpdateCheckupProfilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateCheckupProfilesMutation, import('./api').UpdateCheckupProfilesMutationVariables>(Operations.UpdateCheckupProfiles, options);
      }
export type UpdateCheckupProfilesMutationHookResult = ReturnType<typeof useUpdateCheckupProfilesMutation>;
export type DeleteLinkedProfileMutationFn = Apollo.MutationFunction<import('./api').DeleteLinkedProfileMutation, import('./api').DeleteLinkedProfileMutationVariables>;
export function useDeleteLinkedProfileMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteLinkedProfileMutation, import('./api').DeleteLinkedProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteLinkedProfileMutation, import('./api').DeleteLinkedProfileMutationVariables>(Operations.DeleteLinkedProfile, options);
      }
export type DeleteLinkedProfileMutationHookResult = ReturnType<typeof useDeleteLinkedProfileMutation>;
export type AssignCheckupMutationFn = Apollo.MutationFunction<import('./api').AssignCheckupMutation, import('./api').AssignCheckupMutationVariables>;
export function useAssignCheckupMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').AssignCheckupMutation, import('./api').AssignCheckupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').AssignCheckupMutation, import('./api').AssignCheckupMutationVariables>(Operations.AssignCheckup, options);
      }
export type AssignCheckupMutationHookResult = ReturnType<typeof useAssignCheckupMutation>;
export type CreateExternalRefMutationFn = Apollo.MutationFunction<import('./api').CreateExternalRefMutation, import('./api').CreateExternalRefMutationVariables>;
export function useCreateExternalRefMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateExternalRefMutation, import('./api').CreateExternalRefMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateExternalRefMutation, import('./api').CreateExternalRefMutationVariables>(Operations.CreateExternalRef, options);
      }
export type CreateExternalRefMutationHookResult = ReturnType<typeof useCreateExternalRefMutation>;
export type DeleteExternalRefMutationFn = Apollo.MutationFunction<import('./api').DeleteExternalRefMutation, import('./api').DeleteExternalRefMutationVariables>;
export function useDeleteExternalRefMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteExternalRefMutation, import('./api').DeleteExternalRefMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteExternalRefMutation, import('./api').DeleteExternalRefMutationVariables>(Operations.DeleteExternalRef, options);
      }
export type DeleteExternalRefMutationHookResult = ReturnType<typeof useDeleteExternalRefMutation>;
export type AddFincenListMutationFn = Apollo.MutationFunction<import('./api').AddFincenListMutation, import('./api').AddFincenListMutationVariables>;
export function useAddFincenListMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').AddFincenListMutation, import('./api').AddFincenListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').AddFincenListMutation, import('./api').AddFincenListMutationVariables>(Operations.AddFincenList, options);
      }
export type AddFincenListMutationHookResult = ReturnType<typeof useAddFincenListMutation>;
export type RunMassScreeningMutationFn = Apollo.MutationFunction<import('./api').RunMassScreeningMutation, import('./api').RunMassScreeningMutationVariables>;
export function useRunMassScreeningMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').RunMassScreeningMutation, import('./api').RunMassScreeningMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').RunMassScreeningMutation, import('./api').RunMassScreeningMutationVariables>(Operations.RunMassScreening, options);
      }
export type RunMassScreeningMutationHookResult = ReturnType<typeof useRunMassScreeningMutation>;
export type AddPpFincenListMutationFn = Apollo.MutationFunction<import('./api').AddPpFincenListMutation, import('./api').AddPpFincenListMutationVariables>;
export function useAddPpFincenListMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').AddPpFincenListMutation, import('./api').AddPpFincenListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').AddPpFincenListMutation, import('./api').AddPpFincenListMutationVariables>(Operations.AddPPFincenList, options);
      }
export type AddPpFincenListMutationHookResult = ReturnType<typeof useAddPpFincenListMutation>;
export type RunPpMassScreeningMutationFn = Apollo.MutationFunction<import('./api').RunPpMassScreeningMutation, import('./api').RunPpMassScreeningMutationVariables>;
export function useRunPpMassScreeningMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').RunPpMassScreeningMutation, import('./api').RunPpMassScreeningMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').RunPpMassScreeningMutation, import('./api').RunPpMassScreeningMutationVariables>(Operations.RunPPMassScreening, options);
      }
export type RunPpMassScreeningMutationHookResult = ReturnType<typeof useRunPpMassScreeningMutation>;
export type ChangeStatusPpScreeningMutationFn = Apollo.MutationFunction<import('./api').ChangeStatusPpScreeningMutation, import('./api').ChangeStatusPpScreeningMutationVariables>;
export function useChangeStatusPpScreeningMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ChangeStatusPpScreeningMutation, import('./api').ChangeStatusPpScreeningMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ChangeStatusPpScreeningMutation, import('./api').ChangeStatusPpScreeningMutationVariables>(Operations.ChangeStatusPPScreening, options);
      }
export type ChangeStatusPpScreeningMutationHookResult = ReturnType<typeof useChangeStatusPpScreeningMutation>;
export type CreateCheckupMutationFn = Apollo.MutationFunction<import('./api').CreateCheckupMutation, import('./api').CreateCheckupMutationVariables>;
export function useCreateCheckupMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateCheckupMutation, import('./api').CreateCheckupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateCheckupMutation, import('./api').CreateCheckupMutationVariables>(Operations.CreateCheckup, options);
      }
export type CreateCheckupMutationHookResult = ReturnType<typeof useCreateCheckupMutation>;
export type ServiceCheckupsBulkChangeStatusMutationFn = Apollo.MutationFunction<import('./api').ServiceCheckupsBulkChangeStatusMutation, import('./api').ServiceCheckupsBulkChangeStatusMutationVariables>;
export function useServiceCheckupsBulkChangeStatusMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ServiceCheckupsBulkChangeStatusMutation, import('./api').ServiceCheckupsBulkChangeStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ServiceCheckupsBulkChangeStatusMutation, import('./api').ServiceCheckupsBulkChangeStatusMutationVariables>(Operations.ServiceCheckupsBulkChangeStatus, options);
      }
export type ServiceCheckupsBulkChangeStatusMutationHookResult = ReturnType<typeof useServiceCheckupsBulkChangeStatusMutation>;
export type CreateTranslationQuoteMutationFn = Apollo.MutationFunction<import('./api').CreateTranslationQuoteMutation, import('./api').CreateTranslationQuoteMutationVariables>;
export function useCreateTranslationQuoteMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateTranslationQuoteMutation, import('./api').CreateTranslationQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateTranslationQuoteMutation, import('./api').CreateTranslationQuoteMutationVariables>(Operations.CreateTranslationQuote, options);
      }
export type CreateTranslationQuoteMutationHookResult = ReturnType<typeof useCreateTranslationQuoteMutation>;
export type CreateTranslationIbmMutationFn = Apollo.MutationFunction<import('./api').CreateTranslationIbmMutation, import('./api').CreateTranslationIbmMutationVariables>;
export function useCreateTranslationIbmMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateTranslationIbmMutation, import('./api').CreateTranslationIbmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateTranslationIbmMutation, import('./api').CreateTranslationIbmMutationVariables>(Operations.CreateTranslationIBM, options);
      }
export type CreateTranslationIbmMutationHookResult = ReturnType<typeof useCreateTranslationIbmMutation>;
export type UpdateClientDictionaryMutationFn = Apollo.MutationFunction<import('./api').UpdateClientDictionaryMutation, import('./api').UpdateClientDictionaryMutationVariables>;
export function useUpdateClientDictionaryMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateClientDictionaryMutation, import('./api').UpdateClientDictionaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateClientDictionaryMutation, import('./api').UpdateClientDictionaryMutationVariables>(Operations.UpdateClientDictionary, options);
      }
export type UpdateClientDictionaryMutationHookResult = ReturnType<typeof useUpdateClientDictionaryMutation>;
export type CreateClientDictionaryMutationFn = Apollo.MutationFunction<import('./api').CreateClientDictionaryMutation, import('./api').CreateClientDictionaryMutationVariables>;
export function useCreateClientDictionaryMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateClientDictionaryMutation, import('./api').CreateClientDictionaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateClientDictionaryMutation, import('./api').CreateClientDictionaryMutationVariables>(Operations.CreateClientDictionary, options);
      }
export type CreateClientDictionaryMutationHookResult = ReturnType<typeof useCreateClientDictionaryMutation>;
export type DeleteClientDictionaryMutationFn = Apollo.MutationFunction<import('./api').DeleteClientDictionaryMutation, import('./api').DeleteClientDictionaryMutationVariables>;
export function useDeleteClientDictionaryMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteClientDictionaryMutation, import('./api').DeleteClientDictionaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteClientDictionaryMutation, import('./api').DeleteClientDictionaryMutationVariables>(Operations.DeleteClientDictionary, options);
      }
export type DeleteClientDictionaryMutationHookResult = ReturnType<typeof useDeleteClientDictionaryMutation>;
export type ClientIsacIndustriesCsvMutationFn = Apollo.MutationFunction<import('./api').ClientIsacIndustriesCsvMutation, import('./api').ClientIsacIndustriesCsvMutationVariables>;
export function useClientIsacIndustriesCsvMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ClientIsacIndustriesCsvMutation, import('./api').ClientIsacIndustriesCsvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ClientIsacIndustriesCsvMutation, import('./api').ClientIsacIndustriesCsvMutationVariables>(Operations.ClientIsacIndustriesCSV, options);
      }
export type ClientIsacIndustriesCsvMutationHookResult = ReturnType<typeof useClientIsacIndustriesCsvMutation>;
export type AssignCaseMutationFn = Apollo.MutationFunction<import('./api').AssignCaseMutation, import('./api').AssignCaseMutationVariables>;
export function useAssignCaseMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').AssignCaseMutation, import('./api').AssignCaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').AssignCaseMutation, import('./api').AssignCaseMutationVariables>(Operations.AssignCase, options);
      }
export type AssignCaseMutationHookResult = ReturnType<typeof useAssignCaseMutation>;
export type CreateCaseMutationFn = Apollo.MutationFunction<import('./api').CreateCaseMutation, import('./api').CreateCaseMutationVariables>;
export function useCreateCaseMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateCaseMutation, import('./api').CreateCaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateCaseMutation, import('./api').CreateCaseMutationVariables>(Operations.CreateCase, options);
      }
export type CreateCaseMutationHookResult = ReturnType<typeof useCreateCaseMutation>;
export type ChangeReminderMutationFn = Apollo.MutationFunction<import('./api').ChangeReminderMutation, import('./api').ChangeReminderMutationVariables>;
export function useChangeReminderMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ChangeReminderMutation, import('./api').ChangeReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ChangeReminderMutation, import('./api').ChangeReminderMutationVariables>(Operations.ChangeReminder, options);
      }
export type ChangeReminderMutationHookResult = ReturnType<typeof useChangeReminderMutation>;
export type ChangeStateMutationFn = Apollo.MutationFunction<import('./api').ChangeStateMutation, import('./api').ChangeStateMutationVariables>;
export function useChangeStateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ChangeStateMutation, import('./api').ChangeStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ChangeStateMutation, import('./api').ChangeStateMutationVariables>(Operations.ChangeState, options);
      }
export type ChangeStateMutationHookResult = ReturnType<typeof useChangeStateMutation>;
export type SectionChangeStateMutationFn = Apollo.MutationFunction<import('./api').SectionChangeStateMutation, import('./api').SectionChangeStateMutationVariables>;
export function useSectionChangeStateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').SectionChangeStateMutation, import('./api').SectionChangeStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').SectionChangeStateMutation, import('./api').SectionChangeStateMutationVariables>(Operations.SectionChangeState, options);
      }
export type SectionChangeStateMutationHookResult = ReturnType<typeof useSectionChangeStateMutation>;
export type ShareCaseMutationFn = Apollo.MutationFunction<import('./api').ShareCaseMutation, import('./api').ShareCaseMutationVariables>;
export function useShareCaseMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ShareCaseMutation, import('./api').ShareCaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ShareCaseMutation, import('./api').ShareCaseMutationVariables>(Operations.ShareCase, options);
      }
export type ShareCaseMutationHookResult = ReturnType<typeof useShareCaseMutation>;
export type DeleteCaseSectionItemMutationFn = Apollo.MutationFunction<import('./api').DeleteCaseSectionItemMutation, import('./api').DeleteCaseSectionItemMutationVariables>;
export function useDeleteCaseSectionItemMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteCaseSectionItemMutation, import('./api').DeleteCaseSectionItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteCaseSectionItemMutation, import('./api').DeleteCaseSectionItemMutationVariables>(Operations.DeleteCaseSectionItem, options);
      }
export type DeleteCaseSectionItemMutationHookResult = ReturnType<typeof useDeleteCaseSectionItemMutation>;
export type CreateCaseSectionItemMutationFn = Apollo.MutationFunction<import('./api').CreateCaseSectionItemMutation, import('./api').CreateCaseSectionItemMutationVariables>;
export function useCreateCaseSectionItemMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateCaseSectionItemMutation, import('./api').CreateCaseSectionItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateCaseSectionItemMutation, import('./api').CreateCaseSectionItemMutationVariables>(Operations.CreateCaseSectionItem, options);
      }
export type CreateCaseSectionItemMutationHookResult = ReturnType<typeof useCreateCaseSectionItemMutation>;
export type DeleteSharedCaseMutationFn = Apollo.MutationFunction<import('./api').DeleteSharedCaseMutation, import('./api').DeleteSharedCaseMutationVariables>;
export function useDeleteSharedCaseMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteSharedCaseMutation, import('./api').DeleteSharedCaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteSharedCaseMutation, import('./api').DeleteSharedCaseMutationVariables>(Operations.DeleteSharedCase, options);
      }
export type DeleteSharedCaseMutationHookResult = ReturnType<typeof useDeleteSharedCaseMutation>;
export type UpdateCaseSectionItemMutationFn = Apollo.MutationFunction<import('./api').UpdateCaseSectionItemMutation, import('./api').UpdateCaseSectionItemMutationVariables>;
export function useUpdateCaseSectionItemMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateCaseSectionItemMutation, import('./api').UpdateCaseSectionItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateCaseSectionItemMutation, import('./api').UpdateCaseSectionItemMutationVariables>(Operations.UpdateCaseSectionItem, options);
      }
export type UpdateCaseSectionItemMutationHookResult = ReturnType<typeof useUpdateCaseSectionItemMutation>;
export type UpdateCaseSectionItemNaicsMutationFn = Apollo.MutationFunction<import('./api').UpdateCaseSectionItemNaicsMutation, import('./api').UpdateCaseSectionItemNaicsMutationVariables>;
export function useUpdateCaseSectionItemNaicsMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateCaseSectionItemNaicsMutation, import('./api').UpdateCaseSectionItemNaicsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateCaseSectionItemNaicsMutation, import('./api').UpdateCaseSectionItemNaicsMutationVariables>(Operations.UpdateCaseSectionItemNAICS, options);
      }
export type UpdateCaseSectionItemNaicsMutationHookResult = ReturnType<typeof useUpdateCaseSectionItemNaicsMutation>;
export type ShareRoleCaseMutationFn = Apollo.MutationFunction<import('./api').ShareRoleCaseMutation, import('./api').ShareRoleCaseMutationVariables>;
export function useShareRoleCaseMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ShareRoleCaseMutation, import('./api').ShareRoleCaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ShareRoleCaseMutation, import('./api').ShareRoleCaseMutationVariables>(Operations.ShareRoleCase, options);
      }
export type ShareRoleCaseMutationHookResult = ReturnType<typeof useShareRoleCaseMutation>;
export type CreateCaseSectionTabMutationFn = Apollo.MutationFunction<import('./api').CreateCaseSectionTabMutation, import('./api').CreateCaseSectionTabMutationVariables>;
export function useCreateCaseSectionTabMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateCaseSectionTabMutation, import('./api').CreateCaseSectionTabMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateCaseSectionTabMutation, import('./api').CreateCaseSectionTabMutationVariables>(Operations.CreateCaseSectionTab, options);
      }
export type CreateCaseSectionTabMutationHookResult = ReturnType<typeof useCreateCaseSectionTabMutation>;
export type DeleteShareRoleCaseMutationFn = Apollo.MutationFunction<import('./api').DeleteShareRoleCaseMutation, import('./api').DeleteShareRoleCaseMutationVariables>;
export function useDeleteShareRoleCaseMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteShareRoleCaseMutation, import('./api').DeleteShareRoleCaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteShareRoleCaseMutation, import('./api').DeleteShareRoleCaseMutationVariables>(Operations.DeleteShareRoleCase, options);
      }
export type DeleteShareRoleCaseMutationHookResult = ReturnType<typeof useDeleteShareRoleCaseMutation>;
export type UpdateCaseSectionTabMutationFn = Apollo.MutationFunction<import('./api').UpdateCaseSectionTabMutation, import('./api').UpdateCaseSectionTabMutationVariables>;
export function useUpdateCaseSectionTabMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateCaseSectionTabMutation, import('./api').UpdateCaseSectionTabMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateCaseSectionTabMutation, import('./api').UpdateCaseSectionTabMutationVariables>(Operations.UpdateCaseSectionTab, options);
      }
export type UpdateCaseSectionTabMutationHookResult = ReturnType<typeof useUpdateCaseSectionTabMutation>;
export type UploadCaseSectionDocumentMutationFn = Apollo.MutationFunction<import('./api').UploadCaseSectionDocumentMutation, import('./api').UploadCaseSectionDocumentMutationVariables>;
export function useUploadCaseSectionDocumentMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UploadCaseSectionDocumentMutation, import('./api').UploadCaseSectionDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UploadCaseSectionDocumentMutation, import('./api').UploadCaseSectionDocumentMutationVariables>(Operations.UploadCaseSectionDocument, options);
      }
export type UploadCaseSectionDocumentMutationHookResult = ReturnType<typeof useUploadCaseSectionDocumentMutation>;
export type LinkToProfileMutationFn = Apollo.MutationFunction<import('./api').LinkToProfileMutation, import('./api').LinkToProfileMutationVariables>;
export function useLinkToProfileMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').LinkToProfileMutation, import('./api').LinkToProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').LinkToProfileMutation, import('./api').LinkToProfileMutationVariables>(Operations.LinkToProfile, options);
      }
export type LinkToProfileMutationHookResult = ReturnType<typeof useLinkToProfileMutation>;
export type UnlinkFromProfileMutationFn = Apollo.MutationFunction<import('./api').UnlinkFromProfileMutation, import('./api').UnlinkFromProfileMutationVariables>;
export function useUnlinkFromProfileMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UnlinkFromProfileMutation, import('./api').UnlinkFromProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UnlinkFromProfileMutation, import('./api').UnlinkFromProfileMutationVariables>(Operations.UnlinkFromProfile, options);
      }
export type UnlinkFromProfileMutationHookResult = ReturnType<typeof useUnlinkFromProfileMutation>;
export type ChangeWhitelistedMutationFn = Apollo.MutationFunction<import('./api').ChangeWhitelistedMutation, import('./api').ChangeWhitelistedMutationVariables>;
export function useChangeWhitelistedMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ChangeWhitelistedMutation, import('./api').ChangeWhitelistedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ChangeWhitelistedMutation, import('./api').ChangeWhitelistedMutationVariables>(Operations.ChangeWhitelisted, options);
      }
export type ChangeWhitelistedMutationHookResult = ReturnType<typeof useChangeWhitelistedMutation>;
export type FailQuestionnaireItemMutationFn = Apollo.MutationFunction<import('./api').FailQuestionnaireItemMutation, import('./api').FailQuestionnaireItemMutationVariables>;
export function useFailQuestionnaireItemMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').FailQuestionnaireItemMutation, import('./api').FailQuestionnaireItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').FailQuestionnaireItemMutation, import('./api').FailQuestionnaireItemMutationVariables>(Operations.FailQuestionnaireItem, options);
      }
export type FailQuestionnaireItemMutationHookResult = ReturnType<typeof useFailQuestionnaireItemMutation>;
export type UnfailQuestionnaireItemMutationFn = Apollo.MutationFunction<import('./api').UnfailQuestionnaireItemMutation, import('./api').UnfailQuestionnaireItemMutationVariables>;
export function useUnfailQuestionnaireItemMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UnfailQuestionnaireItemMutation, import('./api').UnfailQuestionnaireItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UnfailQuestionnaireItemMutation, import('./api').UnfailQuestionnaireItemMutationVariables>(Operations.UnfailQuestionnaireItem, options);
      }
export type UnfailQuestionnaireItemMutationHookResult = ReturnType<typeof useUnfailQuestionnaireItemMutation>;
export type CreateCaseCustomStepActionEvaluationMutationFn = Apollo.MutationFunction<import('./api').CreateCaseCustomStepActionEvaluationMutation, import('./api').CreateCaseCustomStepActionEvaluationMutationVariables>;
export function useCreateCaseCustomStepActionEvaluationMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateCaseCustomStepActionEvaluationMutation, import('./api').CreateCaseCustomStepActionEvaluationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateCaseCustomStepActionEvaluationMutation, import('./api').CreateCaseCustomStepActionEvaluationMutationVariables>(Operations.CreateCaseCustomStepActionEvaluation, options);
      }
export type CreateCaseCustomStepActionEvaluationMutationHookResult = ReturnType<typeof useCreateCaseCustomStepActionEvaluationMutation>;
export type CreateCommentMutationFn = Apollo.MutationFunction<import('./api').CreateCommentMutation, import('./api').CreateCommentMutationVariables>;
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateCommentMutation, import('./api').CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateCommentMutation, import('./api').CreateCommentMutationVariables>(Operations.CreateComment, options);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentBulkMutationFn = Apollo.MutationFunction<import('./api').CreateCommentBulkMutation, import('./api').CreateCommentBulkMutationVariables>;
export function useCreateCommentBulkMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateCommentBulkMutation, import('./api').CreateCommentBulkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateCommentBulkMutation, import('./api').CreateCommentBulkMutationVariables>(Operations.CreateCommentBulk, options);
      }
export type CreateCommentBulkMutationHookResult = ReturnType<typeof useCreateCommentBulkMutation>;
export type UpdateCommentMutationFn = Apollo.MutationFunction<import('./api').UpdateCommentMutation, import('./api').UpdateCommentMutationVariables>;
export function useUpdateCommentMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateCommentMutation, import('./api').UpdateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateCommentMutation, import('./api').UpdateCommentMutationVariables>(Operations.UpdateComment, options);
      }
export type UpdateCommentMutationHookResult = ReturnType<typeof useUpdateCommentMutation>;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<import('./api').UpdateCustomerMutation, import('./api').UpdateCustomerMutationVariables>;
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateCustomerMutation, import('./api').UpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateCustomerMutation, import('./api').UpdateCustomerMutationVariables>(Operations.UpdateCustomer, options);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type CustomerDocumentChangeBlacklistedMutationFn = Apollo.MutationFunction<import('./api').CustomerDocumentChangeBlacklistedMutation, import('./api').CustomerDocumentChangeBlacklistedMutationVariables>;
export function useCustomerDocumentChangeBlacklistedMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CustomerDocumentChangeBlacklistedMutation, import('./api').CustomerDocumentChangeBlacklistedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CustomerDocumentChangeBlacklistedMutation, import('./api').CustomerDocumentChangeBlacklistedMutationVariables>(Operations.CustomerDocumentChangeBlacklisted, options);
      }
export type CustomerDocumentChangeBlacklistedMutationHookResult = ReturnType<typeof useCustomerDocumentChangeBlacklistedMutation>;
export type UpdateDataProfileFieldMutationFn = Apollo.MutationFunction<import('./api').UpdateDataProfileFieldMutation, import('./api').UpdateDataProfileFieldMutationVariables>;
export function useUpdateDataProfileFieldMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateDataProfileFieldMutation, import('./api').UpdateDataProfileFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateDataProfileFieldMutation, import('./api').UpdateDataProfileFieldMutationVariables>(Operations.UpdateDataProfileField, options);
      }
export type UpdateDataProfileFieldMutationHookResult = ReturnType<typeof useUpdateDataProfileFieldMutation>;
export type UpdateDataProfileMutationFn = Apollo.MutationFunction<import('./api').UpdateDataProfileMutation, import('./api').UpdateDataProfileMutationVariables>;
export function useUpdateDataProfileMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateDataProfileMutation, import('./api').UpdateDataProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateDataProfileMutation, import('./api').UpdateDataProfileMutationVariables>(Operations.UpdateDataProfile, options);
      }
export type UpdateDataProfileMutationHookResult = ReturnType<typeof useUpdateDataProfileMutation>;
export type CreateDataRetentionConfigMutationFn = Apollo.MutationFunction<import('./api').CreateDataRetentionConfigMutation, import('./api').CreateDataRetentionConfigMutationVariables>;
export function useCreateDataRetentionConfigMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateDataRetentionConfigMutation, import('./api').CreateDataRetentionConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateDataRetentionConfigMutation, import('./api').CreateDataRetentionConfigMutationVariables>(Operations.CreateDataRetentionConfig, options);
      }
export type CreateDataRetentionConfigMutationHookResult = ReturnType<typeof useCreateDataRetentionConfigMutation>;
export type UpdateDataRetentionConfigMutationFn = Apollo.MutationFunction<import('./api').UpdateDataRetentionConfigMutation, import('./api').UpdateDataRetentionConfigMutationVariables>;
export function useUpdateDataRetentionConfigMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateDataRetentionConfigMutation, import('./api').UpdateDataRetentionConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateDataRetentionConfigMutation, import('./api').UpdateDataRetentionConfigMutationVariables>(Operations.UpdateDataRetentionConfig, options);
      }
export type UpdateDataRetentionConfigMutationHookResult = ReturnType<typeof useUpdateDataRetentionConfigMutation>;
export type UpdateDataRetentionPlanMutationFn = Apollo.MutationFunction<import('./api').UpdateDataRetentionPlanMutation, import('./api').UpdateDataRetentionPlanMutationVariables>;
export function useUpdateDataRetentionPlanMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateDataRetentionPlanMutation, import('./api').UpdateDataRetentionPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateDataRetentionPlanMutation, import('./api').UpdateDataRetentionPlanMutationVariables>(Operations.UpdateDataRetentionPlan, options);
      }
export type UpdateDataRetentionPlanMutationHookResult = ReturnType<typeof useUpdateDataRetentionPlanMutation>;
export type CreateDataRetentionPlanMutationFn = Apollo.MutationFunction<import('./api').CreateDataRetentionPlanMutation, import('./api').CreateDataRetentionPlanMutationVariables>;
export function useCreateDataRetentionPlanMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateDataRetentionPlanMutation, import('./api').CreateDataRetentionPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateDataRetentionPlanMutation, import('./api').CreateDataRetentionPlanMutationVariables>(Operations.CreateDataRetentionPlan, options);
      }
export type CreateDataRetentionPlanMutationHookResult = ReturnType<typeof useCreateDataRetentionPlanMutation>;
export type DeleteDataRetentionPlanMutationFn = Apollo.MutationFunction<import('./api').DeleteDataRetentionPlanMutation, import('./api').DeleteDataRetentionPlanMutationVariables>;
export function useDeleteDataRetentionPlanMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteDataRetentionPlanMutation, import('./api').DeleteDataRetentionPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteDataRetentionPlanMutation, import('./api').DeleteDataRetentionPlanMutationVariables>(Operations.DeleteDataRetentionPlan, options);
      }
export type DeleteDataRetentionPlanMutationHookResult = ReturnType<typeof useDeleteDataRetentionPlanMutation>;
export type UploadDocumentMutationFn = Apollo.MutationFunction<import('./api').UploadDocumentMutation, import('./api').UploadDocumentMutationVariables>;
export function useUploadDocumentMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UploadDocumentMutation, import('./api').UploadDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UploadDocumentMutation, import('./api').UploadDocumentMutationVariables>(Operations.UploadDocument, options);
      }
export type UploadDocumentMutationHookResult = ReturnType<typeof useUploadDocumentMutation>;
export type CreateEddReportMutationFn = Apollo.MutationFunction<import('./api').CreateEddReportMutation, import('./api').CreateEddReportMutationVariables>;
export function useCreateEddReportMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateEddReportMutation, import('./api').CreateEddReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateEddReportMutation, import('./api').CreateEddReportMutationVariables>(Operations.CreateEDDReport, options);
      }
export type CreateEddReportMutationHookResult = ReturnType<typeof useCreateEddReportMutation>;
export type UnlinkFromCaseEddReportMutationFn = Apollo.MutationFunction<import('./api').UnlinkFromCaseEddReportMutation, import('./api').UnlinkFromCaseEddReportMutationVariables>;
export function useUnlinkFromCaseEddReportMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UnlinkFromCaseEddReportMutation, import('./api').UnlinkFromCaseEddReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UnlinkFromCaseEddReportMutation, import('./api').UnlinkFromCaseEddReportMutationVariables>(Operations.UnlinkFromCaseEDDReport, options);
      }
export type UnlinkFromCaseEddReportMutationHookResult = ReturnType<typeof useUnlinkFromCaseEddReportMutation>;
export type LinkToCaseEddReportMutationFn = Apollo.MutationFunction<import('./api').LinkToCaseEddReportMutation, import('./api').LinkToCaseEddReportMutationVariables>;
export function useLinkToCaseEddReportMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').LinkToCaseEddReportMutation, import('./api').LinkToCaseEddReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').LinkToCaseEddReportMutation, import('./api').LinkToCaseEddReportMutationVariables>(Operations.LinkToCaseEDDReport, options);
      }
export type LinkToCaseEddReportMutationHookResult = ReturnType<typeof useLinkToCaseEddReportMutation>;
export type LinkToProfileEddReportMutationFn = Apollo.MutationFunction<import('./api').LinkToProfileEddReportMutation, import('./api').LinkToProfileEddReportMutationVariables>;
export function useLinkToProfileEddReportMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').LinkToProfileEddReportMutation, import('./api').LinkToProfileEddReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').LinkToProfileEddReportMutation, import('./api').LinkToProfileEddReportMutationVariables>(Operations.LinkToProfileEDDReport, options);
      }
export type LinkToProfileEddReportMutationHookResult = ReturnType<typeof useLinkToProfileEddReportMutation>;
export type UnlinkFromProfileEddReportMutationFn = Apollo.MutationFunction<import('./api').UnlinkFromProfileEddReportMutation, import('./api').UnlinkFromProfileEddReportMutationVariables>;
export function useUnlinkFromProfileEddReportMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UnlinkFromProfileEddReportMutation, import('./api').UnlinkFromProfileEddReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UnlinkFromProfileEddReportMutation, import('./api').UnlinkFromProfileEddReportMutationVariables>(Operations.UnlinkFromProfileEDDReport, options);
      }
export type UnlinkFromProfileEddReportMutationHookResult = ReturnType<typeof useUnlinkFromProfileEddReportMutation>;
export type AssignToEddReportMutationFn = Apollo.MutationFunction<import('./api').AssignToEddReportMutation, import('./api').AssignToEddReportMutationVariables>;
export function useAssignToEddReportMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').AssignToEddReportMutation, import('./api').AssignToEddReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').AssignToEddReportMutation, import('./api').AssignToEddReportMutationVariables>(Operations.AssignToEDDReport, options);
      }
export type AssignToEddReportMutationHookResult = ReturnType<typeof useAssignToEddReportMutation>;
export type CreateEddReportDocumentMutationFn = Apollo.MutationFunction<import('./api').CreateEddReportDocumentMutation, import('./api').CreateEddReportDocumentMutationVariables>;
export function useCreateEddReportDocumentMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateEddReportDocumentMutation, import('./api').CreateEddReportDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateEddReportDocumentMutation, import('./api').CreateEddReportDocumentMutationVariables>(Operations.CreateEDDReportDocument, options);
      }
export type CreateEddReportDocumentMutationHookResult = ReturnType<typeof useCreateEddReportDocumentMutation>;
export type UpdateEddReportDocumentMutationFn = Apollo.MutationFunction<import('./api').UpdateEddReportDocumentMutation, import('./api').UpdateEddReportDocumentMutationVariables>;
export function useUpdateEddReportDocumentMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateEddReportDocumentMutation, import('./api').UpdateEddReportDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateEddReportDocumentMutation, import('./api').UpdateEddReportDocumentMutationVariables>(Operations.UpdateEDDReportDocument, options);
      }
export type UpdateEddReportDocumentMutationHookResult = ReturnType<typeof useUpdateEddReportDocumentMutation>;
export type DeleteEddReportDocumentMutationFn = Apollo.MutationFunction<import('./api').DeleteEddReportDocumentMutation, import('./api').DeleteEddReportDocumentMutationVariables>;
export function useDeleteEddReportDocumentMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteEddReportDocumentMutation, import('./api').DeleteEddReportDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteEddReportDocumentMutation, import('./api').DeleteEddReportDocumentMutationVariables>(Operations.DeleteEDDReportDocument, options);
      }
export type DeleteEddReportDocumentMutationHookResult = ReturnType<typeof useDeleteEddReportDocumentMutation>;
export type CreateCustomerMessageMutationFn = Apollo.MutationFunction<import('./api').CreateCustomerMessageMutation, import('./api').CreateCustomerMessageMutationVariables>;
export function useCreateCustomerMessageMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateCustomerMessageMutation, import('./api').CreateCustomerMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateCustomerMessageMutation, import('./api').CreateCustomerMessageMutationVariables>(Operations.CreateCustomerMessage, options);
      }
export type CreateCustomerMessageMutationHookResult = ReturnType<typeof useCreateCustomerMessageMutation>;
export type CreateAndSendCustomerMessageMutationFn = Apollo.MutationFunction<import('./api').CreateAndSendCustomerMessageMutation, import('./api').CreateAndSendCustomerMessageMutationVariables>;
export function useCreateAndSendCustomerMessageMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateAndSendCustomerMessageMutation, import('./api').CreateAndSendCustomerMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateAndSendCustomerMessageMutation, import('./api').CreateAndSendCustomerMessageMutationVariables>(Operations.CreateAndSendCustomerMessage, options);
      }
export type CreateAndSendCustomerMessageMutationHookResult = ReturnType<typeof useCreateAndSendCustomerMessageMutation>;
export type UpdateCustomerMessageMutationFn = Apollo.MutationFunction<import('./api').UpdateCustomerMessageMutation, import('./api').UpdateCustomerMessageMutationVariables>;
export function useUpdateCustomerMessageMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateCustomerMessageMutation, import('./api').UpdateCustomerMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateCustomerMessageMutation, import('./api').UpdateCustomerMessageMutationVariables>(Operations.UpdateCustomerMessage, options);
      }
export type UpdateCustomerMessageMutationHookResult = ReturnType<typeof useUpdateCustomerMessageMutation>;
export type UpdateAndSendCustomerMessageMutationFn = Apollo.MutationFunction<import('./api').UpdateAndSendCustomerMessageMutation, import('./api').UpdateAndSendCustomerMessageMutationVariables>;
export function useUpdateAndSendCustomerMessageMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateAndSendCustomerMessageMutation, import('./api').UpdateAndSendCustomerMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateAndSendCustomerMessageMutation, import('./api').UpdateAndSendCustomerMessageMutationVariables>(Operations.UpdateAndSendCustomerMessage, options);
      }
export type UpdateAndSendCustomerMessageMutationHookResult = ReturnType<typeof useUpdateAndSendCustomerMessageMutation>;
export type DeleteCustomerMessageMutationFn = Apollo.MutationFunction<import('./api').DeleteCustomerMessageMutation, import('./api').DeleteCustomerMessageMutationVariables>;
export function useDeleteCustomerMessageMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteCustomerMessageMutation, import('./api').DeleteCustomerMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteCustomerMessageMutation, import('./api').DeleteCustomerMessageMutationVariables>(Operations.DeleteCustomerMessage, options);
      }
export type DeleteCustomerMessageMutationHookResult = ReturnType<typeof useDeleteCustomerMessageMutation>;
export type SendCustomerMessageMutationFn = Apollo.MutationFunction<import('./api').SendCustomerMessageMutation, import('./api').SendCustomerMessageMutationVariables>;
export function useSendCustomerMessageMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').SendCustomerMessageMutation, import('./api').SendCustomerMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').SendCustomerMessageMutation, import('./api').SendCustomerMessageMutationVariables>(Operations.SendCustomerMessage, options);
      }
export type SendCustomerMessageMutationHookResult = ReturnType<typeof useSendCustomerMessageMutation>;
export type CreateEmailTemplateMutationFn = Apollo.MutationFunction<import('./api').CreateEmailTemplateMutation, import('./api').CreateEmailTemplateMutationVariables>;
export function useCreateEmailTemplateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateEmailTemplateMutation, import('./api').CreateEmailTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateEmailTemplateMutation, import('./api').CreateEmailTemplateMutationVariables>(Operations.CreateEmailTemplate, options);
      }
export type CreateEmailTemplateMutationHookResult = ReturnType<typeof useCreateEmailTemplateMutation>;
export type UpdateEmailTemplateMutationFn = Apollo.MutationFunction<import('./api').UpdateEmailTemplateMutation, import('./api').UpdateEmailTemplateMutationVariables>;
export function useUpdateEmailTemplateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateEmailTemplateMutation, import('./api').UpdateEmailTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateEmailTemplateMutation, import('./api').UpdateEmailTemplateMutationVariables>(Operations.UpdateEmailTemplate, options);
      }
export type UpdateEmailTemplateMutationHookResult = ReturnType<typeof useUpdateEmailTemplateMutation>;
export type DeleteEmailTemplateMutationFn = Apollo.MutationFunction<import('./api').DeleteEmailTemplateMutation, import('./api').DeleteEmailTemplateMutationVariables>;
export function useDeleteEmailTemplateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteEmailTemplateMutation, import('./api').DeleteEmailTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteEmailTemplateMutation, import('./api').DeleteEmailTemplateMutationVariables>(Operations.DeleteEmailTemplate, options);
      }
export type DeleteEmailTemplateMutationHookResult = ReturnType<typeof useDeleteEmailTemplateMutation>;
export type FieldUpdateClaimMutationFn = Apollo.MutationFunction<import('./api').FieldUpdateClaimMutation, import('./api').FieldUpdateClaimMutationVariables>;
export function useFieldUpdateClaimMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').FieldUpdateClaimMutation, import('./api').FieldUpdateClaimMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').FieldUpdateClaimMutation, import('./api').FieldUpdateClaimMutationVariables>(Operations.FieldUpdateClaim, options);
      }
export type FieldUpdateClaimMutationHookResult = ReturnType<typeof useFieldUpdateClaimMutation>;
export type CreateFieldsPresetMutationFn = Apollo.MutationFunction<import('./api').CreateFieldsPresetMutation, import('./api').CreateFieldsPresetMutationVariables>;
export function useCreateFieldsPresetMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateFieldsPresetMutation, import('./api').CreateFieldsPresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateFieldsPresetMutation, import('./api').CreateFieldsPresetMutationVariables>(Operations.CreateFieldsPreset, options);
      }
export type CreateFieldsPresetMutationHookResult = ReturnType<typeof useCreateFieldsPresetMutation>;
export type UpdateFieldsPresetMutationFn = Apollo.MutationFunction<import('./api').UpdateFieldsPresetMutation, import('./api').UpdateFieldsPresetMutationVariables>;
export function useUpdateFieldsPresetMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateFieldsPresetMutation, import('./api').UpdateFieldsPresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateFieldsPresetMutation, import('./api').UpdateFieldsPresetMutationVariables>(Operations.UpdateFieldsPreset, options);
      }
export type UpdateFieldsPresetMutationHookResult = ReturnType<typeof useUpdateFieldsPresetMutation>;
export type DeleteFieldsPresetMutationFn = Apollo.MutationFunction<import('./api').DeleteFieldsPresetMutation, import('./api').DeleteFieldsPresetMutationVariables>;
export function useDeleteFieldsPresetMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteFieldsPresetMutation, import('./api').DeleteFieldsPresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteFieldsPresetMutation, import('./api').DeleteFieldsPresetMutationVariables>(Operations.DeleteFieldsPreset, options);
      }
export type DeleteFieldsPresetMutationHookResult = ReturnType<typeof useDeleteFieldsPresetMutation>;
export type DuplicateMutationFn = Apollo.MutationFunction<import('./api').DuplicateMutation, import('./api').DuplicateMutationVariables>;
export function useDuplicateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DuplicateMutation, import('./api').DuplicateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DuplicateMutation, import('./api').DuplicateMutationVariables>(Operations.Duplicate, options);
      }
export type DuplicateMutationHookResult = ReturnType<typeof useDuplicateMutation>;
export type UpdateFavoritesMutationFn = Apollo.MutationFunction<import('./api').UpdateFavoritesMutation, import('./api').UpdateFavoritesMutationVariables>;
export function useUpdateFavoritesMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateFavoritesMutation, import('./api').UpdateFavoritesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateFavoritesMutation, import('./api').UpdateFavoritesMutationVariables>(Operations.UpdateFavorites, options);
      }
export type UpdateFavoritesMutationHookResult = ReturnType<typeof useUpdateFavoritesMutation>;
export type CreateFormMutationFn = Apollo.MutationFunction<import('./api').CreateFormMutation, import('./api').CreateFormMutationVariables>;
export function useCreateFormMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateFormMutation, import('./api').CreateFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateFormMutation, import('./api').CreateFormMutationVariables>(Operations.CreateForm, options);
      }
export type CreateFormMutationHookResult = ReturnType<typeof useCreateFormMutation>;
export type UpdateFormMutationFn = Apollo.MutationFunction<import('./api').UpdateFormMutation, import('./api').UpdateFormMutationVariables>;
export function useUpdateFormMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateFormMutation, import('./api').UpdateFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateFormMutation, import('./api').UpdateFormMutationVariables>(Operations.UpdateForm, options);
      }
export type UpdateFormMutationHookResult = ReturnType<typeof useUpdateFormMutation>;
export type CreateRevisionMutationFn = Apollo.MutationFunction<import('./api').CreateRevisionMutation, import('./api').CreateRevisionMutationVariables>;
export function useCreateRevisionMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateRevisionMutation, import('./api').CreateRevisionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateRevisionMutation, import('./api').CreateRevisionMutationVariables>(Operations.CreateRevision, options);
      }
export type CreateRevisionMutationHookResult = ReturnType<typeof useCreateRevisionMutation>;
export type CreateFormInvitationMutationFn = Apollo.MutationFunction<import('./api').CreateFormInvitationMutation, import('./api').CreateFormInvitationMutationVariables>;
export function useCreateFormInvitationMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateFormInvitationMutation, import('./api').CreateFormInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateFormInvitationMutation, import('./api').CreateFormInvitationMutationVariables>(Operations.CreateFormInvitation, options);
      }
export type CreateFormInvitationMutationHookResult = ReturnType<typeof useCreateFormInvitationMutation>;
export type UpdateFormInvitationMutationFn = Apollo.MutationFunction<import('./api').UpdateFormInvitationMutation, import('./api').UpdateFormInvitationMutationVariables>;
export function useUpdateFormInvitationMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateFormInvitationMutation, import('./api').UpdateFormInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateFormInvitationMutation, import('./api').UpdateFormInvitationMutationVariables>(Operations.UpdateFormInvitation, options);
      }
export type UpdateFormInvitationMutationHookResult = ReturnType<typeof useUpdateFormInvitationMutation>;
export type FormToggleMutationFn = Apollo.MutationFunction<import('./api').FormToggleMutation, import('./api').FormToggleMutationVariables>;
export function useFormToggleMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').FormToggleMutation, import('./api').FormToggleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').FormToggleMutation, import('./api').FormToggleMutationVariables>(Operations.FormToggle, options);
      }
export type FormToggleMutationHookResult = ReturnType<typeof useFormToggleMutation>;
export type CreateGlobalSearchEntrieMutationFn = Apollo.MutationFunction<import('./api').CreateGlobalSearchEntrieMutation, import('./api').CreateGlobalSearchEntrieMutationVariables>;
export function useCreateGlobalSearchEntrieMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateGlobalSearchEntrieMutation, import('./api').CreateGlobalSearchEntrieMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateGlobalSearchEntrieMutation, import('./api').CreateGlobalSearchEntrieMutationVariables>(Operations.CreateGlobalSearchEntrie, options);
      }
export type CreateGlobalSearchEntrieMutationHookResult = ReturnType<typeof useCreateGlobalSearchEntrieMutation>;
export type CreateInviteMutationFn = Apollo.MutationFunction<import('./api').CreateInviteMutation, import('./api').CreateInviteMutationVariables>;
export function useCreateInviteMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateInviteMutation, import('./api').CreateInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateInviteMutation, import('./api').CreateInviteMutationVariables>(Operations.CreateInvite, options);
      }
export type CreateInviteMutationHookResult = ReturnType<typeof useCreateInviteMutation>;
export type UpdateInviteStateMutationFn = Apollo.MutationFunction<import('./api').UpdateInviteStateMutation, import('./api').UpdateInviteStateMutationVariables>;
export function useUpdateInviteStateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateInviteStateMutation, import('./api').UpdateInviteStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateInviteStateMutation, import('./api').UpdateInviteStateMutationVariables>(Operations.UpdateInviteState, options);
      }
export type UpdateInviteStateMutationHookResult = ReturnType<typeof useUpdateInviteStateMutation>;
export type UpdateMeMutationFn = Apollo.MutationFunction<import('./api').UpdateMeMutation, import('./api').UpdateMeMutationVariables>;
export function useUpdateMeMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateMeMutation, import('./api').UpdateMeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateMeMutation, import('./api').UpdateMeMutationVariables>(Operations.UpdateMe, options);
      }
export type UpdateMeMutationHookResult = ReturnType<typeof useUpdateMeMutation>;
export type NotificationReadMutationFn = Apollo.MutationFunction<import('./api').NotificationReadMutation, import('./api').NotificationReadMutationVariables>;
export function useNotificationReadMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').NotificationReadMutation, import('./api').NotificationReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').NotificationReadMutation, import('./api').NotificationReadMutationVariables>(Operations.NotificationRead, options);
      }
export type NotificationReadMutationHookResult = ReturnType<typeof useNotificationReadMutation>;
export type NotificationReadAllMutationFn = Apollo.MutationFunction<import('./api').NotificationReadAllMutation, import('./api').NotificationReadAllMutationVariables>;
export function useNotificationReadAllMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').NotificationReadAllMutation, import('./api').NotificationReadAllMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').NotificationReadAllMutation, import('./api').NotificationReadAllMutationVariables>(Operations.NotificationReadAll, options);
      }
export type NotificationReadAllMutationHookResult = ReturnType<typeof useNotificationReadAllMutation>;
export type NotificationPinnedUpdateMutationFn = Apollo.MutationFunction<import('./api').NotificationPinnedUpdateMutation, import('./api').NotificationPinnedUpdateMutationVariables>;
export function useNotificationPinnedUpdateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').NotificationPinnedUpdateMutation, import('./api').NotificationPinnedUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').NotificationPinnedUpdateMutation, import('./api').NotificationPinnedUpdateMutationVariables>(Operations.NotificationPinnedUpdate, options);
      }
export type NotificationPinnedUpdateMutationHookResult = ReturnType<typeof useNotificationPinnedUpdateMutation>;
export type NotificationSettingUpdateMutationFn = Apollo.MutationFunction<import('./api').NotificationSettingUpdateMutation, import('./api').NotificationSettingUpdateMutationVariables>;
export function useNotificationSettingUpdateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').NotificationSettingUpdateMutation, import('./api').NotificationSettingUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').NotificationSettingUpdateMutation, import('./api').NotificationSettingUpdateMutationVariables>(Operations.NotificationSettingUpdate, options);
      }
export type NotificationSettingUpdateMutationHookResult = ReturnType<typeof useNotificationSettingUpdateMutation>;
export type CreateDraftClientMutationFn = Apollo.MutationFunction<import('./api').CreateDraftClientMutation, import('./api').CreateDraftClientMutationVariables>;
export function useCreateDraftClientMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateDraftClientMutation, import('./api').CreateDraftClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateDraftClientMutation, import('./api').CreateDraftClientMutationVariables>(Operations.CreateDraftClient, options);
      }
export type CreateDraftClientMutationHookResult = ReturnType<typeof useCreateDraftClientMutation>;
export type CreateExportRequestMutationFn = Apollo.MutationFunction<import('./api').CreateExportRequestMutation, import('./api').CreateExportRequestMutationVariables>;
export function useCreateExportRequestMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateExportRequestMutation, import('./api').CreateExportRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateExportRequestMutation, import('./api').CreateExportRequestMutationVariables>(Operations.CreateExportRequest, options);
      }
export type CreateExportRequestMutationHookResult = ReturnType<typeof useCreateExportRequestMutation>;
export type SignDownloadExportRequestMutationFn = Apollo.MutationFunction<import('./api').SignDownloadExportRequestMutation, import('./api').SignDownloadExportRequestMutationVariables>;
export function useSignDownloadExportRequestMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').SignDownloadExportRequestMutation, import('./api').SignDownloadExportRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').SignDownloadExportRequestMutation, import('./api').SignDownloadExportRequestMutationVariables>(Operations.SignDownloadExportRequest, options);
      }
export type SignDownloadExportRequestMutationHookResult = ReturnType<typeof useSignDownloadExportRequestMutation>;
export type SubscribeOnPlanMutationFn = Apollo.MutationFunction<import('./api').SubscribeOnPlanMutation, import('./api').SubscribeOnPlanMutationVariables>;
export function useSubscribeOnPlanMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').SubscribeOnPlanMutation, import('./api').SubscribeOnPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').SubscribeOnPlanMutation, import('./api').SubscribeOnPlanMutationVariables>(Operations.SubscribeOnPlan, options);
      }
export type SubscribeOnPlanMutationHookResult = ReturnType<typeof useSubscribeOnPlanMutation>;
export type CreateFeatureDictionaryMutationFn = Apollo.MutationFunction<import('./api').CreateFeatureDictionaryMutation, import('./api').CreateFeatureDictionaryMutationVariables>;
export function useCreateFeatureDictionaryMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateFeatureDictionaryMutation, import('./api').CreateFeatureDictionaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateFeatureDictionaryMutation, import('./api').CreateFeatureDictionaryMutationVariables>(Operations.CreateFeatureDictionary, options);
      }
export type CreateFeatureDictionaryMutationHookResult = ReturnType<typeof useCreateFeatureDictionaryMutation>;
export type CreateFeatureTemplateMutationFn = Apollo.MutationFunction<import('./api').CreateFeatureTemplateMutation, import('./api').CreateFeatureTemplateMutationVariables>;
export function useCreateFeatureTemplateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateFeatureTemplateMutation, import('./api').CreateFeatureTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateFeatureTemplateMutation, import('./api').CreateFeatureTemplateMutationVariables>(Operations.CreateFeatureTemplate, options);
      }
export type CreateFeatureTemplateMutationHookResult = ReturnType<typeof useCreateFeatureTemplateMutation>;
export type UpdateFeatureTemplateMutationFn = Apollo.MutationFunction<import('./api').UpdateFeatureTemplateMutation, import('./api').UpdateFeatureTemplateMutationVariables>;
export function useUpdateFeatureTemplateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateFeatureTemplateMutation, import('./api').UpdateFeatureTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateFeatureTemplateMutation, import('./api').UpdateFeatureTemplateMutationVariables>(Operations.UpdateFeatureTemplate, options);
      }
export type UpdateFeatureTemplateMutationHookResult = ReturnType<typeof useUpdateFeatureTemplateMutation>;
export type CreatePricingPlanMutationFn = Apollo.MutationFunction<import('./api').CreatePricingPlanMutation, import('./api').CreatePricingPlanMutationVariables>;
export function useCreatePricingPlanMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreatePricingPlanMutation, import('./api').CreatePricingPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreatePricingPlanMutation, import('./api').CreatePricingPlanMutationVariables>(Operations.CreatePricingPlan, options);
      }
export type CreatePricingPlanMutationHookResult = ReturnType<typeof useCreatePricingPlanMutation>;
export type EditPricingPlanMutationFn = Apollo.MutationFunction<import('./api').EditPricingPlanMutation, import('./api').EditPricingPlanMutationVariables>;
export function useEditPricingPlanMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').EditPricingPlanMutation, import('./api').EditPricingPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').EditPricingPlanMutation, import('./api').EditPricingPlanMutationVariables>(Operations.EditPricingPlan, options);
      }
export type EditPricingPlanMutationHookResult = ReturnType<typeof useEditPricingPlanMutation>;
export type NewRevisionPricingPlanMutationFn = Apollo.MutationFunction<import('./api').NewRevisionPricingPlanMutation, import('./api').NewRevisionPricingPlanMutationVariables>;
export function useNewRevisionPricingPlanMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').NewRevisionPricingPlanMutation, import('./api').NewRevisionPricingPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').NewRevisionPricingPlanMutation, import('./api').NewRevisionPricingPlanMutationVariables>(Operations.NewRevisionPricingPlan, options);
      }
export type NewRevisionPricingPlanMutationHookResult = ReturnType<typeof useNewRevisionPricingPlanMutation>;
export type DuplicatePricingPlanMutationFn = Apollo.MutationFunction<import('./api').DuplicatePricingPlanMutation, import('./api').DuplicatePricingPlanMutationVariables>;
export function useDuplicatePricingPlanMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DuplicatePricingPlanMutation, import('./api').DuplicatePricingPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DuplicatePricingPlanMutation, import('./api').DuplicatePricingPlanMutationVariables>(Operations.DuplicatePricingPlan, options);
      }
export type DuplicatePricingPlanMutationHookResult = ReturnType<typeof useDuplicatePricingPlanMutation>;
export type ChangeStatusPricingPlanMutationFn = Apollo.MutationFunction<import('./api').ChangeStatusPricingPlanMutation, import('./api').ChangeStatusPricingPlanMutationVariables>;
export function useChangeStatusPricingPlanMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ChangeStatusPricingPlanMutation, import('./api').ChangeStatusPricingPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ChangeStatusPricingPlanMutation, import('./api').ChangeStatusPricingPlanMutationVariables>(Operations.ChangeStatusPricingPlan, options);
      }
export type ChangeStatusPricingPlanMutationHookResult = ReturnType<typeof useChangeStatusPricingPlanMutation>;
export type AddToBlacklistMutationFn = Apollo.MutationFunction<import('./api').AddToBlacklistMutation, import('./api').AddToBlacklistMutationVariables>;
export function useAddToBlacklistMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').AddToBlacklistMutation, import('./api').AddToBlacklistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').AddToBlacklistMutation, import('./api').AddToBlacklistMutationVariables>(Operations.AddToBlacklist, options);
      }
export type AddToBlacklistMutationHookResult = ReturnType<typeof useAddToBlacklistMutation>;
export type AddManyToBlacklistMutationFn = Apollo.MutationFunction<import('./api').AddManyToBlacklistMutation, import('./api').AddManyToBlacklistMutationVariables>;
export function useAddManyToBlacklistMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').AddManyToBlacklistMutation, import('./api').AddManyToBlacklistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').AddManyToBlacklistMutation, import('./api').AddManyToBlacklistMutationVariables>(Operations.AddManyToBlacklist, options);
      }
export type AddManyToBlacklistMutationHookResult = ReturnType<typeof useAddManyToBlacklistMutation>;
export type AddToBlacklistWithCreateProfileMutationFn = Apollo.MutationFunction<import('./api').AddToBlacklistWithCreateProfileMutation, import('./api').AddToBlacklistWithCreateProfileMutationVariables>;
export function useAddToBlacklistWithCreateProfileMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').AddToBlacklistWithCreateProfileMutation, import('./api').AddToBlacklistWithCreateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').AddToBlacklistWithCreateProfileMutation, import('./api').AddToBlacklistWithCreateProfileMutationVariables>(Operations.AddToBlacklistWithCreateProfile, options);
      }
export type AddToBlacklistWithCreateProfileMutationHookResult = ReturnType<typeof useAddToBlacklistWithCreateProfileMutation>;
export type DeleteBlacklistProfileMutationFn = Apollo.MutationFunction<import('./api').DeleteBlacklistProfileMutation, import('./api').DeleteBlacklistProfileMutationVariables>;
export function useDeleteBlacklistProfileMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteBlacklistProfileMutation, import('./api').DeleteBlacklistProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteBlacklistProfileMutation, import('./api').DeleteBlacklistProfileMutationVariables>(Operations.DeleteBlacklistProfile, options);
      }
export type DeleteBlacklistProfileMutationHookResult = ReturnType<typeof useDeleteBlacklistProfileMutation>;
export type CreateProfileMutationFn = Apollo.MutationFunction<import('./api').CreateProfileMutation, import('./api').CreateProfileMutationVariables>;
export function useCreateProfileMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateProfileMutation, import('./api').CreateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateProfileMutation, import('./api').CreateProfileMutationVariables>(Operations.CreateProfile, options);
      }
export type CreateProfileMutationHookResult = ReturnType<typeof useCreateProfileMutation>;
export type UpdateProfileMutationFn = Apollo.MutationFunction<import('./api').UpdateProfileMutation, import('./api').UpdateProfileMutationVariables>;
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateProfileMutation, import('./api').UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateProfileMutation, import('./api').UpdateProfileMutationVariables>(Operations.UpdateProfile, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type BlacklistProfilesReportMutationFn = Apollo.MutationFunction<import('./api').BlacklistProfilesReportMutation, import('./api').BlacklistProfilesReportMutationVariables>;
export function useBlacklistProfilesReportMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').BlacklistProfilesReportMutation, import('./api').BlacklistProfilesReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').BlacklistProfilesReportMutation, import('./api').BlacklistProfilesReportMutationVariables>(Operations.BlacklistProfilesReport, options);
      }
export type BlacklistProfilesReportMutationHookResult = ReturnType<typeof useBlacklistProfilesReportMutation>;
export type CreateProhibitedCountryMutationFn = Apollo.MutationFunction<import('./api').CreateProhibitedCountryMutation, import('./api').CreateProhibitedCountryMutationVariables>;
export function useCreateProhibitedCountryMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateProhibitedCountryMutation, import('./api').CreateProhibitedCountryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateProhibitedCountryMutation, import('./api').CreateProhibitedCountryMutationVariables>(Operations.CreateProhibitedCountry, options);
      }
export type CreateProhibitedCountryMutationHookResult = ReturnType<typeof useCreateProhibitedCountryMutation>;
export type DeleteProhibitedCountryMutationFn = Apollo.MutationFunction<import('./api').DeleteProhibitedCountryMutation, import('./api').DeleteProhibitedCountryMutationVariables>;
export function useDeleteProhibitedCountryMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteProhibitedCountryMutation, import('./api').DeleteProhibitedCountryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteProhibitedCountryMutation, import('./api').DeleteProhibitedCountryMutationVariables>(Operations.DeleteProhibitedCountry, options);
      }
export type DeleteProhibitedCountryMutationHookResult = ReturnType<typeof useDeleteProhibitedCountryMutation>;
export type ChangeStateCaseProjectionMutationFn = Apollo.MutationFunction<import('./api').ChangeStateCaseProjectionMutation, import('./api').ChangeStateCaseProjectionMutationVariables>;
export function useChangeStateCaseProjectionMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ChangeStateCaseProjectionMutation, import('./api').ChangeStateCaseProjectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ChangeStateCaseProjectionMutation, import('./api').ChangeStateCaseProjectionMutationVariables>(Operations.ChangeStateCaseProjection, options);
      }
export type ChangeStateCaseProjectionMutationHookResult = ReturnType<typeof useChangeStateCaseProjectionMutation>;
export type LinkProfileCaseProjectionMutationFn = Apollo.MutationFunction<import('./api').LinkProfileCaseProjectionMutation, import('./api').LinkProfileCaseProjectionMutationVariables>;
export function useLinkProfileCaseProjectionMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').LinkProfileCaseProjectionMutation, import('./api').LinkProfileCaseProjectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').LinkProfileCaseProjectionMutation, import('./api').LinkProfileCaseProjectionMutationVariables>(Operations.LinkProfileCaseProjection, options);
      }
export type LinkProfileCaseProjectionMutationHookResult = ReturnType<typeof useLinkProfileCaseProjectionMutation>;
export type UnlinkProfileCaseProjectionMutationFn = Apollo.MutationFunction<import('./api').UnlinkProfileCaseProjectionMutation, import('./api').UnlinkProfileCaseProjectionMutationVariables>;
export function useUnlinkProfileCaseProjectionMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UnlinkProfileCaseProjectionMutation, import('./api').UnlinkProfileCaseProjectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UnlinkProfileCaseProjectionMutation, import('./api').UnlinkProfileCaseProjectionMutationVariables>(Operations.UnlinkProfileCaseProjection, options);
      }
export type UnlinkProfileCaseProjectionMutationHookResult = ReturnType<typeof useUnlinkProfileCaseProjectionMutation>;
export type LinkSubmissionCaseProjectionMutationFn = Apollo.MutationFunction<import('./api').LinkSubmissionCaseProjectionMutation, import('./api').LinkSubmissionCaseProjectionMutationVariables>;
export function useLinkSubmissionCaseProjectionMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').LinkSubmissionCaseProjectionMutation, import('./api').LinkSubmissionCaseProjectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').LinkSubmissionCaseProjectionMutation, import('./api').LinkSubmissionCaseProjectionMutationVariables>(Operations.LinkSubmissionCaseProjection, options);
      }
export type LinkSubmissionCaseProjectionMutationHookResult = ReturnType<typeof useLinkSubmissionCaseProjectionMutation>;
export type UnlinkSubmissionCaseProjectionMutationFn = Apollo.MutationFunction<import('./api').UnlinkSubmissionCaseProjectionMutation, import('./api').UnlinkSubmissionCaseProjectionMutationVariables>;
export function useUnlinkSubmissionCaseProjectionMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UnlinkSubmissionCaseProjectionMutation, import('./api').UnlinkSubmissionCaseProjectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UnlinkSubmissionCaseProjectionMutation, import('./api').UnlinkSubmissionCaseProjectionMutationVariables>(Operations.UnlinkSubmissionCaseProjection, options);
      }
export type UnlinkSubmissionCaseProjectionMutationHookResult = ReturnType<typeof useUnlinkSubmissionCaseProjectionMutation>;
export type ChangeStateCaseProjectionSectionMutationFn = Apollo.MutationFunction<import('./api').ChangeStateCaseProjectionSectionMutation, import('./api').ChangeStateCaseProjectionSectionMutationVariables>;
export function useChangeStateCaseProjectionSectionMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ChangeStateCaseProjectionSectionMutation, import('./api').ChangeStateCaseProjectionSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ChangeStateCaseProjectionSectionMutation, import('./api').ChangeStateCaseProjectionSectionMutationVariables>(Operations.ChangeStateCaseProjectionSection, options);
      }
export type ChangeStateCaseProjectionSectionMutationHookResult = ReturnType<typeof useChangeStateCaseProjectionSectionMutation>;
export type FieldUpdateClaimProjectionMutationFn = Apollo.MutationFunction<import('./api').FieldUpdateClaimProjectionMutation, import('./api').FieldUpdateClaimProjectionMutationVariables>;
export function useFieldUpdateClaimProjectionMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').FieldUpdateClaimProjectionMutation, import('./api').FieldUpdateClaimProjectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').FieldUpdateClaimProjectionMutation, import('./api').FieldUpdateClaimProjectionMutationVariables>(Operations.FieldUpdateClaimProjection, options);
      }
export type FieldUpdateClaimProjectionMutationHookResult = ReturnType<typeof useFieldUpdateClaimProjectionMutation>;
export type DeleteCaseProjectionSectionItemMutationFn = Apollo.MutationFunction<import('./api').DeleteCaseProjectionSectionItemMutation, import('./api').DeleteCaseProjectionSectionItemMutationVariables>;
export function useDeleteCaseProjectionSectionItemMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteCaseProjectionSectionItemMutation, import('./api').DeleteCaseProjectionSectionItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteCaseProjectionSectionItemMutation, import('./api').DeleteCaseProjectionSectionItemMutationVariables>(Operations.DeleteCaseProjectionSectionItem, options);
      }
export type DeleteCaseProjectionSectionItemMutationHookResult = ReturnType<typeof useDeleteCaseProjectionSectionItemMutation>;
export type CreateCaseProjectionSectionItemMutationFn = Apollo.MutationFunction<import('./api').CreateCaseProjectionSectionItemMutation, import('./api').CreateCaseProjectionSectionItemMutationVariables>;
export function useCreateCaseProjectionSectionItemMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateCaseProjectionSectionItemMutation, import('./api').CreateCaseProjectionSectionItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateCaseProjectionSectionItemMutation, import('./api').CreateCaseProjectionSectionItemMutationVariables>(Operations.CreateCaseProjectionSectionItem, options);
      }
export type CreateCaseProjectionSectionItemMutationHookResult = ReturnType<typeof useCreateCaseProjectionSectionItemMutation>;
export type UpdateCaseProjectionSectionItemMutationFn = Apollo.MutationFunction<import('./api').UpdateCaseProjectionSectionItemMutation, import('./api').UpdateCaseProjectionSectionItemMutationVariables>;
export function useUpdateCaseProjectionSectionItemMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateCaseProjectionSectionItemMutation, import('./api').UpdateCaseProjectionSectionItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateCaseProjectionSectionItemMutation, import('./api').UpdateCaseProjectionSectionItemMutationVariables>(Operations.UpdateCaseProjectionSectionItem, options);
      }
export type UpdateCaseProjectionSectionItemMutationHookResult = ReturnType<typeof useUpdateCaseProjectionSectionItemMutation>;
export type UploadCaseProjectionSectionDocumentMutationFn = Apollo.MutationFunction<import('./api').UploadCaseProjectionSectionDocumentMutation, import('./api').UploadCaseProjectionSectionDocumentMutationVariables>;
export function useUploadCaseProjectionSectionDocumentMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UploadCaseProjectionSectionDocumentMutation, import('./api').UploadCaseProjectionSectionDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UploadCaseProjectionSectionDocumentMutation, import('./api').UploadCaseProjectionSectionDocumentMutationVariables>(Operations.UploadCaseProjectionSectionDocument, options);
      }
export type UploadCaseProjectionSectionDocumentMutationHookResult = ReturnType<typeof useUploadCaseProjectionSectionDocumentMutation>;
export type CreateCaseProjectionSectionTabMutationFn = Apollo.MutationFunction<import('./api').CreateCaseProjectionSectionTabMutation, import('./api').CreateCaseProjectionSectionTabMutationVariables>;
export function useCreateCaseProjectionSectionTabMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateCaseProjectionSectionTabMutation, import('./api').CreateCaseProjectionSectionTabMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateCaseProjectionSectionTabMutation, import('./api').CreateCaseProjectionSectionTabMutationVariables>(Operations.CreateCaseProjectionSectionTab, options);
      }
export type CreateCaseProjectionSectionTabMutationHookResult = ReturnType<typeof useCreateCaseProjectionSectionTabMutation>;
export type UpdateCaseProjectionSectionTabMutationFn = Apollo.MutationFunction<import('./api').UpdateCaseProjectionSectionTabMutation, import('./api').UpdateCaseProjectionSectionTabMutationVariables>;
export function useUpdateCaseProjectionSectionTabMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateCaseProjectionSectionTabMutation, import('./api').UpdateCaseProjectionSectionTabMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateCaseProjectionSectionTabMutation, import('./api').UpdateCaseProjectionSectionTabMutationVariables>(Operations.UpdateCaseProjectionSectionTab, options);
      }
export type UpdateCaseProjectionSectionTabMutationHookResult = ReturnType<typeof useUpdateCaseProjectionSectionTabMutation>;
export type CreateProjectionCheckupMutationFn = Apollo.MutationFunction<import('./api').CreateProjectionCheckupMutation, import('./api').CreateProjectionCheckupMutationVariables>;
export function useCreateProjectionCheckupMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateProjectionCheckupMutation, import('./api').CreateProjectionCheckupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateProjectionCheckupMutation, import('./api').CreateProjectionCheckupMutationVariables>(Operations.CreateProjectionCheckup, options);
      }
export type CreateProjectionCheckupMutationHookResult = ReturnType<typeof useCreateProjectionCheckupMutation>;
export type CreateProjectionEddReportMutationFn = Apollo.MutationFunction<import('./api').CreateProjectionEddReportMutation, import('./api').CreateProjectionEddReportMutationVariables>;
export function useCreateProjectionEddReportMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateProjectionEddReportMutation, import('./api').CreateProjectionEddReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateProjectionEddReportMutation, import('./api').CreateProjectionEddReportMutationVariables>(Operations.CreateProjectionEDDReport, options);
      }
export type CreateProjectionEddReportMutationHookResult = ReturnType<typeof useCreateProjectionEddReportMutation>;
export type UnlinkFromCaseProjectionEddReportMutationFn = Apollo.MutationFunction<import('./api').UnlinkFromCaseProjectionEddReportMutation, import('./api').UnlinkFromCaseProjectionEddReportMutationVariables>;
export function useUnlinkFromCaseProjectionEddReportMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UnlinkFromCaseProjectionEddReportMutation, import('./api').UnlinkFromCaseProjectionEddReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UnlinkFromCaseProjectionEddReportMutation, import('./api').UnlinkFromCaseProjectionEddReportMutationVariables>(Operations.UnlinkFromCaseProjectionEDDReport, options);
      }
export type UnlinkFromCaseProjectionEddReportMutationHookResult = ReturnType<typeof useUnlinkFromCaseProjectionEddReportMutation>;
export type CreateCaseReminderMutationFn = Apollo.MutationFunction<import('./api').CreateCaseReminderMutation, import('./api').CreateCaseReminderMutationVariables>;
export function useCreateCaseReminderMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateCaseReminderMutation, import('./api').CreateCaseReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateCaseReminderMutation, import('./api').CreateCaseReminderMutationVariables>(Operations.CreateCaseReminder, options);
      }
export type CreateCaseReminderMutationHookResult = ReturnType<typeof useCreateCaseReminderMutation>;
export type UpdateCaseReminderMutationFn = Apollo.MutationFunction<import('./api').UpdateCaseReminderMutation, import('./api').UpdateCaseReminderMutationVariables>;
export function useUpdateCaseReminderMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateCaseReminderMutation, import('./api').UpdateCaseReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateCaseReminderMutation, import('./api').UpdateCaseReminderMutationVariables>(Operations.UpdateCaseReminder, options);
      }
export type UpdateCaseReminderMutationHookResult = ReturnType<typeof useUpdateCaseReminderMutation>;
export type CreateCaseReminderKindMutationFn = Apollo.MutationFunction<import('./api').CreateCaseReminderKindMutation, import('./api').CreateCaseReminderKindMutationVariables>;
export function useCreateCaseReminderKindMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateCaseReminderKindMutation, import('./api').CreateCaseReminderKindMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateCaseReminderKindMutation, import('./api').CreateCaseReminderKindMutationVariables>(Operations.CreateCaseReminderKind, options);
      }
export type CreateCaseReminderKindMutationHookResult = ReturnType<typeof useCreateCaseReminderKindMutation>;
export type UpdateCaseReminderKindMutationFn = Apollo.MutationFunction<import('./api').UpdateCaseReminderKindMutation, import('./api').UpdateCaseReminderKindMutationVariables>;
export function useUpdateCaseReminderKindMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateCaseReminderKindMutation, import('./api').UpdateCaseReminderKindMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateCaseReminderKindMutation, import('./api').UpdateCaseReminderKindMutationVariables>(Operations.UpdateCaseReminderKind, options);
      }
export type UpdateCaseReminderKindMutationHookResult = ReturnType<typeof useUpdateCaseReminderKindMutation>;
export type CreateCaseReminderSettingMutationFn = Apollo.MutationFunction<import('./api').CreateCaseReminderSettingMutation, import('./api').CreateCaseReminderSettingMutationVariables>;
export function useCreateCaseReminderSettingMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateCaseReminderSettingMutation, import('./api').CreateCaseReminderSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateCaseReminderSettingMutation, import('./api').CreateCaseReminderSettingMutationVariables>(Operations.CreateCaseReminderSetting, options);
      }
export type CreateCaseReminderSettingMutationHookResult = ReturnType<typeof useCreateCaseReminderSettingMutation>;
export type UpdateCaseReminderSettingMutationFn = Apollo.MutationFunction<import('./api').UpdateCaseReminderSettingMutation, import('./api').UpdateCaseReminderSettingMutationVariables>;
export function useUpdateCaseReminderSettingMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateCaseReminderSettingMutation, import('./api').UpdateCaseReminderSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateCaseReminderSettingMutation, import('./api').UpdateCaseReminderSettingMutationVariables>(Operations.UpdateCaseReminderSetting, options);
      }
export type UpdateCaseReminderSettingMutationHookResult = ReturnType<typeof useUpdateCaseReminderSettingMutation>;
export type DeleteCaseReminderSettingMutationFn = Apollo.MutationFunction<import('./api').DeleteCaseReminderSettingMutation, import('./api').DeleteCaseReminderSettingMutationVariables>;
export function useDeleteCaseReminderSettingMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteCaseReminderSettingMutation, import('./api').DeleteCaseReminderSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteCaseReminderSettingMutation, import('./api').DeleteCaseReminderSettingMutationVariables>(Operations.DeleteCaseReminderSetting, options);
      }
export type DeleteCaseReminderSettingMutationHookResult = ReturnType<typeof useDeleteCaseReminderSettingMutation>;
export type DeleteCaseReminderKindMutationFn = Apollo.MutationFunction<import('./api').DeleteCaseReminderKindMutation, import('./api').DeleteCaseReminderKindMutationVariables>;
export function useDeleteCaseReminderKindMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteCaseReminderKindMutation, import('./api').DeleteCaseReminderKindMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteCaseReminderKindMutation, import('./api').DeleteCaseReminderKindMutationVariables>(Operations.DeleteCaseReminderKind, options);
      }
export type DeleteCaseReminderKindMutationHookResult = ReturnType<typeof useDeleteCaseReminderKindMutation>;
export type ChangeStateRequiredActionMutationFn = Apollo.MutationFunction<import('./api').ChangeStateRequiredActionMutation, import('./api').ChangeStateRequiredActionMutationVariables>;
export function useChangeStateRequiredActionMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ChangeStateRequiredActionMutation, import('./api').ChangeStateRequiredActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ChangeStateRequiredActionMutation, import('./api').ChangeStateRequiredActionMutationVariables>(Operations.ChangeStateRequiredAction, options);
      }
export type ChangeStateRequiredActionMutationHookResult = ReturnType<typeof useChangeStateRequiredActionMutation>;
export type CreateRequiredActionMutationFn = Apollo.MutationFunction<import('./api').CreateRequiredActionMutation, import('./api').CreateRequiredActionMutationVariables>;
export function useCreateRequiredActionMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateRequiredActionMutation, import('./api').CreateRequiredActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateRequiredActionMutation, import('./api').CreateRequiredActionMutationVariables>(Operations.CreateRequiredAction, options);
      }
export type CreateRequiredActionMutationHookResult = ReturnType<typeof useCreateRequiredActionMutation>;
export type SaveSarMutationFn = Apollo.MutationFunction<import('./api').SaveSarMutation, import('./api').SaveSarMutationVariables>;
export function useSaveSarMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').SaveSarMutation, import('./api').SaveSarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').SaveSarMutation, import('./api').SaveSarMutationVariables>(Operations.SaveSar, options);
      }
export type SaveSarMutationHookResult = ReturnType<typeof useSaveSarMutation>;
export type ChangeStateSarMutationFn = Apollo.MutationFunction<import('./api').ChangeStateSarMutation, import('./api').ChangeStateSarMutationVariables>;
export function useChangeStateSarMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ChangeStateSarMutation, import('./api').ChangeStateSarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ChangeStateSarMutation, import('./api').ChangeStateSarMutationVariables>(Operations.ChangeStateSar, options);
      }
export type ChangeStateSarMutationHookResult = ReturnType<typeof useChangeStateSarMutation>;
export type UpdateSarMutationFn = Apollo.MutationFunction<import('./api').UpdateSarMutation, import('./api').UpdateSarMutationVariables>;
export function useUpdateSarMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateSarMutation, import('./api').UpdateSarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateSarMutation, import('./api').UpdateSarMutationVariables>(Operations.UpdateSar, options);
      }
export type UpdateSarMutationHookResult = ReturnType<typeof useUpdateSarMutation>;
export type AssignSarMutationFn = Apollo.MutationFunction<import('./api').AssignSarMutation, import('./api').AssignSarMutationVariables>;
export function useAssignSarMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').AssignSarMutation, import('./api').AssignSarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').AssignSarMutation, import('./api').AssignSarMutationVariables>(Operations.AssignSar, options);
      }
export type AssignSarMutationHookResult = ReturnType<typeof useAssignSarMutation>;
export type SendSarMutationFn = Apollo.MutationFunction<import('./api').SendSarMutation, import('./api').SendSarMutationVariables>;
export function useSendSarMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').SendSarMutation, import('./api').SendSarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').SendSarMutation, import('./api').SendSarMutationVariables>(Operations.SendSar, options);
      }
export type SendSarMutationHookResult = ReturnType<typeof useSendSarMutation>;
export type UpdateCaseScoringMutationFn = Apollo.MutationFunction<import('./api').UpdateCaseScoringMutation, import('./api').UpdateCaseScoringMutationVariables>;
export function useUpdateCaseScoringMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateCaseScoringMutation, import('./api').UpdateCaseScoringMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateCaseScoringMutation, import('./api').UpdateCaseScoringMutationVariables>(Operations.UpdateCaseScoring, options);
      }
export type UpdateCaseScoringMutationHookResult = ReturnType<typeof useUpdateCaseScoringMutation>;
export type RecalculateCaseScoringMutationFn = Apollo.MutationFunction<import('./api').RecalculateCaseScoringMutation, import('./api').RecalculateCaseScoringMutationVariables>;
export function useRecalculateCaseScoringMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').RecalculateCaseScoringMutation, import('./api').RecalculateCaseScoringMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').RecalculateCaseScoringMutation, import('./api').RecalculateCaseScoringMutationVariables>(Operations.RecalculateCaseScoring, options);
      }
export type RecalculateCaseScoringMutationHookResult = ReturnType<typeof useRecalculateCaseScoringMutation>;
export type ResolveConflictsCaseScoringMutationFn = Apollo.MutationFunction<import('./api').ResolveConflictsCaseScoringMutation, import('./api').ResolveConflictsCaseScoringMutationVariables>;
export function useResolveConflictsCaseScoringMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ResolveConflictsCaseScoringMutation, import('./api').ResolveConflictsCaseScoringMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ResolveConflictsCaseScoringMutation, import('./api').ResolveConflictsCaseScoringMutationVariables>(Operations.ResolveConflictsCaseScoring, options);
      }
export type ResolveConflictsCaseScoringMutationHookResult = ReturnType<typeof useResolveConflictsCaseScoringMutation>;
export type UpdateCaseSectionScoringMutationFn = Apollo.MutationFunction<import('./api').UpdateCaseSectionScoringMutation, import('./api').UpdateCaseSectionScoringMutationVariables>;
export function useUpdateCaseSectionScoringMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateCaseSectionScoringMutation, import('./api').UpdateCaseSectionScoringMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateCaseSectionScoringMutation, import('./api').UpdateCaseSectionScoringMutationVariables>(Operations.UpdateCaseSectionScoring, options);
      }
export type UpdateCaseSectionScoringMutationHookResult = ReturnType<typeof useUpdateCaseSectionScoringMutation>;
export type RecalculateCaseSectionScoringMutationFn = Apollo.MutationFunction<import('./api').RecalculateCaseSectionScoringMutation, import('./api').RecalculateCaseSectionScoringMutationVariables>;
export function useRecalculateCaseSectionScoringMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').RecalculateCaseSectionScoringMutation, import('./api').RecalculateCaseSectionScoringMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').RecalculateCaseSectionScoringMutation, import('./api').RecalculateCaseSectionScoringMutationVariables>(Operations.RecalculateCaseSectionScoring, options);
      }
export type RecalculateCaseSectionScoringMutationHookResult = ReturnType<typeof useRecalculateCaseSectionScoringMutation>;
export type ResolveConflictsCaseSectionScoringMutationFn = Apollo.MutationFunction<import('./api').ResolveConflictsCaseSectionScoringMutation, import('./api').ResolveConflictsCaseSectionScoringMutationVariables>;
export function useResolveConflictsCaseSectionScoringMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ResolveConflictsCaseSectionScoringMutation, import('./api').ResolveConflictsCaseSectionScoringMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ResolveConflictsCaseSectionScoringMutation, import('./api').ResolveConflictsCaseSectionScoringMutationVariables>(Operations.ResolveConflictsCaseSectionScoring, options);
      }
export type ResolveConflictsCaseSectionScoringMutationHookResult = ReturnType<typeof useResolveConflictsCaseSectionScoringMutation>;
export type UpdateCaseSectionSubjectScoringMutationFn = Apollo.MutationFunction<import('./api').UpdateCaseSectionSubjectScoringMutation, import('./api').UpdateCaseSectionSubjectScoringMutationVariables>;
export function useUpdateCaseSectionSubjectScoringMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateCaseSectionSubjectScoringMutation, import('./api').UpdateCaseSectionSubjectScoringMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateCaseSectionSubjectScoringMutation, import('./api').UpdateCaseSectionSubjectScoringMutationVariables>(Operations.UpdateCaseSectionSubjectScoring, options);
      }
export type UpdateCaseSectionSubjectScoringMutationHookResult = ReturnType<typeof useUpdateCaseSectionSubjectScoringMutation>;
export type CreateCaseSectionSubjectScoringMutationFn = Apollo.MutationFunction<import('./api').CreateCaseSectionSubjectScoringMutation, import('./api').CreateCaseSectionSubjectScoringMutationVariables>;
export function useCreateCaseSectionSubjectScoringMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateCaseSectionSubjectScoringMutation, import('./api').CreateCaseSectionSubjectScoringMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateCaseSectionSubjectScoringMutation, import('./api').CreateCaseSectionSubjectScoringMutationVariables>(Operations.CreateCaseSectionSubjectScoring, options);
      }
export type CreateCaseSectionSubjectScoringMutationHookResult = ReturnType<typeof useCreateCaseSectionSubjectScoringMutation>;
export type DeleteCaseSectionSubjectScoringMutationFn = Apollo.MutationFunction<import('./api').DeleteCaseSectionSubjectScoringMutation, import('./api').DeleteCaseSectionSubjectScoringMutationVariables>;
export function useDeleteCaseSectionSubjectScoringMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteCaseSectionSubjectScoringMutation, import('./api').DeleteCaseSectionSubjectScoringMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteCaseSectionSubjectScoringMutation, import('./api').DeleteCaseSectionSubjectScoringMutationVariables>(Operations.DeleteCaseSectionSubjectScoring, options);
      }
export type DeleteCaseSectionSubjectScoringMutationHookResult = ReturnType<typeof useDeleteCaseSectionSubjectScoringMutation>;
export type UpdateCaseScoringRuleMutationFn = Apollo.MutationFunction<import('./api').UpdateCaseScoringRuleMutation, import('./api').UpdateCaseScoringRuleMutationVariables>;
export function useUpdateCaseScoringRuleMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateCaseScoringRuleMutation, import('./api').UpdateCaseScoringRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateCaseScoringRuleMutation, import('./api').UpdateCaseScoringRuleMutationVariables>(Operations.UpdateCaseScoringRule, options);
      }
export type UpdateCaseScoringRuleMutationHookResult = ReturnType<typeof useUpdateCaseScoringRuleMutation>;
export type UploadCsvScoringRuleMutationFn = Apollo.MutationFunction<import('./api').UploadCsvScoringRuleMutation, import('./api').UploadCsvScoringRuleMutationVariables>;
export function useUploadCsvScoringRuleMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UploadCsvScoringRuleMutation, import('./api').UploadCsvScoringRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UploadCsvScoringRuleMutation, import('./api').UploadCsvScoringRuleMutationVariables>(Operations.UploadCsvScoringRule, options);
      }
export type UploadCsvScoringRuleMutationHookResult = ReturnType<typeof useUploadCsvScoringRuleMutation>;
export type UpdateFormScoringModelMutationFn = Apollo.MutationFunction<import('./api').UpdateFormScoringModelMutation, import('./api').UpdateFormScoringModelMutationVariables>;
export function useUpdateFormScoringModelMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateFormScoringModelMutation, import('./api').UpdateFormScoringModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateFormScoringModelMutation, import('./api').UpdateFormScoringModelMutationVariables>(Operations.UpdateFormScoringModel, options);
      }
export type UpdateFormScoringModelMutationHookResult = ReturnType<typeof useUpdateFormScoringModelMutation>;
export type UpdateScoringRuleMutationFn = Apollo.MutationFunction<import('./api').UpdateScoringRuleMutation, import('./api').UpdateScoringRuleMutationVariables>;
export function useUpdateScoringRuleMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateScoringRuleMutation, import('./api').UpdateScoringRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateScoringRuleMutation, import('./api').UpdateScoringRuleMutationVariables>(Operations.UpdateScoringRule, options);
      }
export type UpdateScoringRuleMutationHookResult = ReturnType<typeof useUpdateScoringRuleMutation>;
export type UpdateSubmissionScoringMutationFn = Apollo.MutationFunction<import('./api').UpdateSubmissionScoringMutation, import('./api').UpdateSubmissionScoringMutationVariables>;
export function useUpdateSubmissionScoringMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateSubmissionScoringMutation, import('./api').UpdateSubmissionScoringMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateSubmissionScoringMutation, import('./api').UpdateSubmissionScoringMutationVariables>(Operations.UpdateSubmissionScoring, options);
      }
export type UpdateSubmissionScoringMutationHookResult = ReturnType<typeof useUpdateSubmissionScoringMutation>;
export type UpdateFieldSubmissionScoringMutationFn = Apollo.MutationFunction<import('./api').UpdateFieldSubmissionScoringMutation, import('./api').UpdateFieldSubmissionScoringMutationVariables>;
export function useUpdateFieldSubmissionScoringMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateFieldSubmissionScoringMutation, import('./api').UpdateFieldSubmissionScoringMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateFieldSubmissionScoringMutation, import('./api').UpdateFieldSubmissionScoringMutationVariables>(Operations.UpdateFieldSubmissionScoring, options);
      }
export type UpdateFieldSubmissionScoringMutationHookResult = ReturnType<typeof useUpdateFieldSubmissionScoringMutation>;
export type RecalculateSubmissionScoringMutationFn = Apollo.MutationFunction<import('./api').RecalculateSubmissionScoringMutation, import('./api').RecalculateSubmissionScoringMutationVariables>;
export function useRecalculateSubmissionScoringMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').RecalculateSubmissionScoringMutation, import('./api').RecalculateSubmissionScoringMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').RecalculateSubmissionScoringMutation, import('./api').RecalculateSubmissionScoringMutationVariables>(Operations.RecalculateSubmissionScoring, options);
      }
export type RecalculateSubmissionScoringMutationHookResult = ReturnType<typeof useRecalculateSubmissionScoringMutation>;
export type ResolveConflictsSubmissionScoringMutationFn = Apollo.MutationFunction<import('./api').ResolveConflictsSubmissionScoringMutation, import('./api').ResolveConflictsSubmissionScoringMutationVariables>;
export function useResolveConflictsSubmissionScoringMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ResolveConflictsSubmissionScoringMutation, import('./api').ResolveConflictsSubmissionScoringMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ResolveConflictsSubmissionScoringMutation, import('./api').ResolveConflictsSubmissionScoringMutationVariables>(Operations.ResolveConflictsSubmissionScoring, options);
      }
export type ResolveConflictsSubmissionScoringMutationHookResult = ReturnType<typeof useResolveConflictsSubmissionScoringMutation>;
export type CreateDataSharingMutationFn = Apollo.MutationFunction<import('./api').CreateDataSharingMutation, import('./api').CreateDataSharingMutationVariables>;
export function useCreateDataSharingMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateDataSharingMutation, import('./api').CreateDataSharingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateDataSharingMutation, import('./api').CreateDataSharingMutationVariables>(Operations.CreateDataSharing, options);
      }
export type CreateDataSharingMutationHookResult = ReturnType<typeof useCreateDataSharingMutation>;
export type UpdateDataSharingMutationFn = Apollo.MutationFunction<import('./api').UpdateDataSharingMutation, import('./api').UpdateDataSharingMutationVariables>;
export function useUpdateDataSharingMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateDataSharingMutation, import('./api').UpdateDataSharingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateDataSharingMutation, import('./api').UpdateDataSharingMutationVariables>(Operations.UpdateDataSharing, options);
      }
export type UpdateDataSharingMutationHookResult = ReturnType<typeof useUpdateDataSharingMutation>;
export type CreateDataSharingRuleMutationFn = Apollo.MutationFunction<import('./api').CreateDataSharingRuleMutation, import('./api').CreateDataSharingRuleMutationVariables>;
export function useCreateDataSharingRuleMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateDataSharingRuleMutation, import('./api').CreateDataSharingRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateDataSharingRuleMutation, import('./api').CreateDataSharingRuleMutationVariables>(Operations.CreateDataSharingRule, options);
      }
export type CreateDataSharingRuleMutationHookResult = ReturnType<typeof useCreateDataSharingRuleMutation>;
export type UpdateDataSharingRuleMutationFn = Apollo.MutationFunction<import('./api').UpdateDataSharingRuleMutation, import('./api').UpdateDataSharingRuleMutationVariables>;
export function useUpdateDataSharingRuleMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateDataSharingRuleMutation, import('./api').UpdateDataSharingRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateDataSharingRuleMutation, import('./api').UpdateDataSharingRuleMutationVariables>(Operations.UpdateDataSharingRule, options);
      }
export type UpdateDataSharingRuleMutationHookResult = ReturnType<typeof useUpdateDataSharingRuleMutation>;
export type DeleteDataSharingRuleMutationFn = Apollo.MutationFunction<import('./api').DeleteDataSharingRuleMutation, import('./api').DeleteDataSharingRuleMutationVariables>;
export function useDeleteDataSharingRuleMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteDataSharingRuleMutation, import('./api').DeleteDataSharingRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteDataSharingRuleMutation, import('./api').DeleteDataSharingRuleMutationVariables>(Operations.DeleteDataSharingRule, options);
      }
export type DeleteDataSharingRuleMutationHookResult = ReturnType<typeof useDeleteDataSharingRuleMutation>;
export type ChangeStateDataSharingMutationFn = Apollo.MutationFunction<import('./api').ChangeStateDataSharingMutation, import('./api').ChangeStateDataSharingMutationVariables>;
export function useChangeStateDataSharingMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ChangeStateDataSharingMutation, import('./api').ChangeStateDataSharingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ChangeStateDataSharingMutation, import('./api').ChangeStateDataSharingMutationVariables>(Operations.ChangeStateDataSharing, options);
      }
export type ChangeStateDataSharingMutationHookResult = ReturnType<typeof useChangeStateDataSharingMutation>;
export type ChangeStateCustomerDataSharingMutationFn = Apollo.MutationFunction<import('./api').ChangeStateCustomerDataSharingMutation, import('./api').ChangeStateCustomerDataSharingMutationVariables>;
export function useChangeStateCustomerDataSharingMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ChangeStateCustomerDataSharingMutation, import('./api').ChangeStateCustomerDataSharingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ChangeStateCustomerDataSharingMutation, import('./api').ChangeStateCustomerDataSharingMutationVariables>(Operations.ChangeStateCustomerDataSharing, options);
      }
export type ChangeStateCustomerDataSharingMutationHookResult = ReturnType<typeof useChangeStateCustomerDataSharingMutation>;
export type SharingUploadDocumentMutationFn = Apollo.MutationFunction<import('./api').SharingUploadDocumentMutation, import('./api').SharingUploadDocumentMutationVariables>;
export function useSharingUploadDocumentMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').SharingUploadDocumentMutation, import('./api').SharingUploadDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').SharingUploadDocumentMutation, import('./api').SharingUploadDocumentMutationVariables>(Operations.SharingUploadDocument, options);
      }
export type SharingUploadDocumentMutationHookResult = ReturnType<typeof useSharingUploadDocumentMutation>;
export type CreateMetricsReportMutationFn = Apollo.MutationFunction<import('./api').CreateMetricsReportMutation, import('./api').CreateMetricsReportMutationVariables>;
export function useCreateMetricsReportMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateMetricsReportMutation, import('./api').CreateMetricsReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateMetricsReportMutation, import('./api').CreateMetricsReportMutationVariables>(Operations.CreateMetricsReport, options);
      }
export type CreateMetricsReportMutationHookResult = ReturnType<typeof useCreateMetricsReportMutation>;
export type CreateComplianceViewReportMutationFn = Apollo.MutationFunction<import('./api').CreateComplianceViewReportMutation, import('./api').CreateComplianceViewReportMutationVariables>;
export function useCreateComplianceViewReportMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateComplianceViewReportMutation, import('./api').CreateComplianceViewReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateComplianceViewReportMutation, import('./api').CreateComplianceViewReportMutationVariables>(Operations.CreateComplianceViewReport, options);
      }
export type CreateComplianceViewReportMutationHookResult = ReturnType<typeof useCreateComplianceViewReportMutation>;
export type CreateQuickViewReportMutationFn = Apollo.MutationFunction<import('./api').CreateQuickViewReportMutation, import('./api').CreateQuickViewReportMutationVariables>;
export function useCreateQuickViewReportMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateQuickViewReportMutation, import('./api').CreateQuickViewReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateQuickViewReportMutation, import('./api').CreateQuickViewReportMutationVariables>(Operations.CreateQuickViewReport, options);
      }
export type CreateQuickViewReportMutationHookResult = ReturnType<typeof useCreateQuickViewReportMutation>;
export type FetchSubmissionsStatsMutationFn = Apollo.MutationFunction<import('./api').FetchSubmissionsStatsMutation, import('./api').FetchSubmissionsStatsMutationVariables>;
export function useFetchSubmissionsStatsMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').FetchSubmissionsStatsMutation, import('./api').FetchSubmissionsStatsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').FetchSubmissionsStatsMutation, import('./api').FetchSubmissionsStatsMutationVariables>(Operations.FetchSubmissionsStats, options);
      }
export type FetchSubmissionsStatsMutationHookResult = ReturnType<typeof useFetchSubmissionsStatsMutation>;
export type CreateSubmissionsStatsPresetMutationFn = Apollo.MutationFunction<import('./api').CreateSubmissionsStatsPresetMutation, import('./api').CreateSubmissionsStatsPresetMutationVariables>;
export function useCreateSubmissionsStatsPresetMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateSubmissionsStatsPresetMutation, import('./api').CreateSubmissionsStatsPresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateSubmissionsStatsPresetMutation, import('./api').CreateSubmissionsStatsPresetMutationVariables>(Operations.CreateSubmissionsStatsPreset, options);
      }
export type CreateSubmissionsStatsPresetMutationHookResult = ReturnType<typeof useCreateSubmissionsStatsPresetMutation>;
export type FetchSubmissionsByValuesMutationFn = Apollo.MutationFunction<import('./api').FetchSubmissionsByValuesMutation, import('./api').FetchSubmissionsByValuesMutationVariables>;
export function useFetchSubmissionsByValuesMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').FetchSubmissionsByValuesMutation, import('./api').FetchSubmissionsByValuesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').FetchSubmissionsByValuesMutation, import('./api').FetchSubmissionsByValuesMutationVariables>(Operations.FetchSubmissionsByValues, options);
      }
export type FetchSubmissionsByValuesMutationHookResult = ReturnType<typeof useFetchSubmissionsByValuesMutation>;
export type UpdateSubmissionsStatsPresetMutationFn = Apollo.MutationFunction<import('./api').UpdateSubmissionsStatsPresetMutation, import('./api').UpdateSubmissionsStatsPresetMutationVariables>;
export function useUpdateSubmissionsStatsPresetMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateSubmissionsStatsPresetMutation, import('./api').UpdateSubmissionsStatsPresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateSubmissionsStatsPresetMutation, import('./api').UpdateSubmissionsStatsPresetMutationVariables>(Operations.UpdateSubmissionsStatsPreset, options);
      }
export type UpdateSubmissionsStatsPresetMutationHookResult = ReturnType<typeof useUpdateSubmissionsStatsPresetMutation>;
export type DashboardPlusMutationFn = Apollo.MutationFunction<import('./api').DashboardPlusMutation, import('./api').DashboardPlusMutationVariables>;
export function useDashboardPlusMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DashboardPlusMutation, import('./api').DashboardPlusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DashboardPlusMutation, import('./api').DashboardPlusMutationVariables>(Operations.DashboardPlus, options);
      }
export type DashboardPlusMutationHookResult = ReturnType<typeof useDashboardPlusMutation>;
export type DeleteSubmissionsStatsPresetMutationFn = Apollo.MutationFunction<import('./api').DeleteSubmissionsStatsPresetMutation, import('./api').DeleteSubmissionsStatsPresetMutationVariables>;
export function useDeleteSubmissionsStatsPresetMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteSubmissionsStatsPresetMutation, import('./api').DeleteSubmissionsStatsPresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteSubmissionsStatsPresetMutation, import('./api').DeleteSubmissionsStatsPresetMutationVariables>(Operations.DeleteSubmissionsStatsPreset, options);
      }
export type DeleteSubmissionsStatsPresetMutationHookResult = ReturnType<typeof useDeleteSubmissionsStatsPresetMutation>;
export type LinkToProfileSubmissionMutationFn = Apollo.MutationFunction<import('./api').LinkToProfileSubmissionMutation, import('./api').LinkToProfileSubmissionMutationVariables>;
export function useLinkToProfileSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').LinkToProfileSubmissionMutation, import('./api').LinkToProfileSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').LinkToProfileSubmissionMutation, import('./api').LinkToProfileSubmissionMutationVariables>(Operations.LinkToProfileSubmission, options);
      }
export type LinkToProfileSubmissionMutationHookResult = ReturnType<typeof useLinkToProfileSubmissionMutation>;
export type LinkToCaseSubmissionMutationFn = Apollo.MutationFunction<import('./api').LinkToCaseSubmissionMutation, import('./api').LinkToCaseSubmissionMutationVariables>;
export function useLinkToCaseSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').LinkToCaseSubmissionMutation, import('./api').LinkToCaseSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').LinkToCaseSubmissionMutation, import('./api').LinkToCaseSubmissionMutationVariables>(Operations.LinkToCaseSubmission, options);
      }
export type LinkToCaseSubmissionMutationHookResult = ReturnType<typeof useLinkToCaseSubmissionMutation>;
export type UnlinkFromCaseSubmissionMutationFn = Apollo.MutationFunction<import('./api').UnlinkFromCaseSubmissionMutation, import('./api').UnlinkFromCaseSubmissionMutationVariables>;
export function useUnlinkFromCaseSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UnlinkFromCaseSubmissionMutation, import('./api').UnlinkFromCaseSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UnlinkFromCaseSubmissionMutation, import('./api').UnlinkFromCaseSubmissionMutationVariables>(Operations.UnlinkFromCaseSubmission, options);
      }
export type UnlinkFromCaseSubmissionMutationHookResult = ReturnType<typeof useUnlinkFromCaseSubmissionMutation>;
export type CreateTabRegistryMutationFn = Apollo.MutationFunction<import('./api').CreateTabRegistryMutation, import('./api').CreateTabRegistryMutationVariables>;
export function useCreateTabRegistryMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateTabRegistryMutation, import('./api').CreateTabRegistryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateTabRegistryMutation, import('./api').CreateTabRegistryMutationVariables>(Operations.CreateTabRegistry, options);
      }
export type CreateTabRegistryMutationHookResult = ReturnType<typeof useCreateTabRegistryMutation>;
export type UpdateTabRegistryMutationFn = Apollo.MutationFunction<import('./api').UpdateTabRegistryMutation, import('./api').UpdateTabRegistryMutationVariables>;
export function useUpdateTabRegistryMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateTabRegistryMutation, import('./api').UpdateTabRegistryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateTabRegistryMutation, import('./api').UpdateTabRegistryMutationVariables>(Operations.UpdateTabRegistry, options);
      }
export type UpdateTabRegistryMutationHookResult = ReturnType<typeof useUpdateTabRegistryMutation>;
export type ChangePositionTabRegistryMutationFn = Apollo.MutationFunction<import('./api').ChangePositionTabRegistryMutation, import('./api').ChangePositionTabRegistryMutationVariables>;
export function useChangePositionTabRegistryMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ChangePositionTabRegistryMutation, import('./api').ChangePositionTabRegistryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ChangePositionTabRegistryMutation, import('./api').ChangePositionTabRegistryMutationVariables>(Operations.ChangePositionTabRegistry, options);
      }
export type ChangePositionTabRegistryMutationHookResult = ReturnType<typeof useChangePositionTabRegistryMutation>;
export type DeleteTabRegistryMutationFn = Apollo.MutationFunction<import('./api').DeleteTabRegistryMutation, import('./api').DeleteTabRegistryMutationVariables>;
export function useDeleteTabRegistryMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteTabRegistryMutation, import('./api').DeleteTabRegistryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteTabRegistryMutation, import('./api').DeleteTabRegistryMutationVariables>(Operations.DeleteTabRegistry, options);
      }
export type DeleteTabRegistryMutationHookResult = ReturnType<typeof useDeleteTabRegistryMutation>;
export type CreateTransactionActivityTypeMutationFn = Apollo.MutationFunction<import('./api').CreateTransactionActivityTypeMutation, import('./api').CreateTransactionActivityTypeMutationVariables>;
export function useCreateTransactionActivityTypeMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateTransactionActivityTypeMutation, import('./api').CreateTransactionActivityTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateTransactionActivityTypeMutation, import('./api').CreateTransactionActivityTypeMutationVariables>(Operations.CreateTransactionActivityType, options);
      }
export type CreateTransactionActivityTypeMutationHookResult = ReturnType<typeof useCreateTransactionActivityTypeMutation>;
export type CreateTransactionBlacklistGroupMutationFn = Apollo.MutationFunction<import('./api').CreateTransactionBlacklistGroupMutation, import('./api').CreateTransactionBlacklistGroupMutationVariables>;
export function useCreateTransactionBlacklistGroupMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateTransactionBlacklistGroupMutation, import('./api').CreateTransactionBlacklistGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateTransactionBlacklistGroupMutation, import('./api').CreateTransactionBlacklistGroupMutationVariables>(Operations.CreateTransactionBlacklistGroup, options);
      }
export type CreateTransactionBlacklistGroupMutationHookResult = ReturnType<typeof useCreateTransactionBlacklistGroupMutation>;
export type UpdateTransactionBlacklistGroupBodyMutationFn = Apollo.MutationFunction<import('./api').UpdateTransactionBlacklistGroupBodyMutation, import('./api').UpdateTransactionBlacklistGroupBodyMutationVariables>;
export function useUpdateTransactionBlacklistGroupBodyMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateTransactionBlacklistGroupBodyMutation, import('./api').UpdateTransactionBlacklistGroupBodyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateTransactionBlacklistGroupBodyMutation, import('./api').UpdateTransactionBlacklistGroupBodyMutationVariables>(Operations.UpdateTransactionBlacklistGroupBody, options);
      }
export type UpdateTransactionBlacklistGroupBodyMutationHookResult = ReturnType<typeof useUpdateTransactionBlacklistGroupBodyMutation>;
export type DeleteTransactionBlacklistGroupMutationFn = Apollo.MutationFunction<import('./api').DeleteTransactionBlacklistGroupMutation, import('./api').DeleteTransactionBlacklistGroupMutationVariables>;
export function useDeleteTransactionBlacklistGroupMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteTransactionBlacklistGroupMutation, import('./api').DeleteTransactionBlacklistGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteTransactionBlacklistGroupMutation, import('./api').DeleteTransactionBlacklistGroupMutationVariables>(Operations.DeleteTransactionBlacklistGroup, options);
      }
export type DeleteTransactionBlacklistGroupMutationHookResult = ReturnType<typeof useDeleteTransactionBlacklistGroupMutation>;
export type ChangeAlertsStateBulkMutationFn = Apollo.MutationFunction<import('./api').ChangeAlertsStateBulkMutation, import('./api').ChangeAlertsStateBulkMutationVariables>;
export function useChangeAlertsStateBulkMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ChangeAlertsStateBulkMutation, import('./api').ChangeAlertsStateBulkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ChangeAlertsStateBulkMutation, import('./api').ChangeAlertsStateBulkMutationVariables>(Operations.ChangeAlertsStateBulk, options);
      }
export type ChangeAlertsStateBulkMutationHookResult = ReturnType<typeof useChangeAlertsStateBulkMutation>;
export type LinkAlertsProfileLinkBulkMutationFn = Apollo.MutationFunction<import('./api').LinkAlertsProfileLinkBulkMutation, import('./api').LinkAlertsProfileLinkBulkMutationVariables>;
export function useLinkAlertsProfileLinkBulkMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').LinkAlertsProfileLinkBulkMutation, import('./api').LinkAlertsProfileLinkBulkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').LinkAlertsProfileLinkBulkMutation, import('./api').LinkAlertsProfileLinkBulkMutationVariables>(Operations.LinkAlertsProfileLinkBulk, options);
      }
export type LinkAlertsProfileLinkBulkMutationHookResult = ReturnType<typeof useLinkAlertsProfileLinkBulkMutation>;
export type AssignAlertsAccountBulkMutationFn = Apollo.MutationFunction<import('./api').AssignAlertsAccountBulkMutation, import('./api').AssignAlertsAccountBulkMutationVariables>;
export function useAssignAlertsAccountBulkMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').AssignAlertsAccountBulkMutation, import('./api').AssignAlertsAccountBulkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').AssignAlertsAccountBulkMutation, import('./api').AssignAlertsAccountBulkMutationVariables>(Operations.AssignAlertsAccountBulk, options);
      }
export type AssignAlertsAccountBulkMutationHookResult = ReturnType<typeof useAssignAlertsAccountBulkMutation>;
export type SetAlertsDispositionBulkMutationFn = Apollo.MutationFunction<import('./api').SetAlertsDispositionBulkMutation, import('./api').SetAlertsDispositionBulkMutationVariables>;
export function useSetAlertsDispositionBulkMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').SetAlertsDispositionBulkMutation, import('./api').SetAlertsDispositionBulkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').SetAlertsDispositionBulkMutation, import('./api').SetAlertsDispositionBulkMutationVariables>(Operations.SetAlertsDispositionBulk, options);
      }
export type SetAlertsDispositionBulkMutationHookResult = ReturnType<typeof useSetAlertsDispositionBulkMutation>;
export type CreateTransactionCaseOriginMutationFn = Apollo.MutationFunction<import('./api').CreateTransactionCaseOriginMutation, import('./api').CreateTransactionCaseOriginMutationVariables>;
export function useCreateTransactionCaseOriginMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateTransactionCaseOriginMutation, import('./api').CreateTransactionCaseOriginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateTransactionCaseOriginMutation, import('./api').CreateTransactionCaseOriginMutationVariables>(Operations.CreateTransactionCaseOrigin, options);
      }
export type CreateTransactionCaseOriginMutationHookResult = ReturnType<typeof useCreateTransactionCaseOriginMutation>;
export type CreateDispositionMutationFn = Apollo.MutationFunction<import('./api').CreateDispositionMutation, import('./api').CreateDispositionMutationVariables>;
export function useCreateDispositionMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateDispositionMutation, import('./api').CreateDispositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateDispositionMutation, import('./api').CreateDispositionMutationVariables>(Operations.CreateDisposition, options);
      }
export type CreateDispositionMutationHookResult = ReturnType<typeof useCreateDispositionMutation>;
export type DeleteDispositionMutationFn = Apollo.MutationFunction<import('./api').DeleteDispositionMutation, import('./api').DeleteDispositionMutationVariables>;
export function useDeleteDispositionMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteDispositionMutation, import('./api').DeleteDispositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteDispositionMutation, import('./api').DeleteDispositionMutationVariables>(Operations.DeleteDisposition, options);
      }
export type DeleteDispositionMutationHookResult = ReturnType<typeof useDeleteDispositionMutation>;
export type ExecuteAdditionalMutationFn = Apollo.MutationFunction<import('./api').ExecuteAdditionalMutation, import('./api').ExecuteAdditionalMutationVariables>;
export function useExecuteAdditionalMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ExecuteAdditionalMutation, import('./api').ExecuteAdditionalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ExecuteAdditionalMutation, import('./api').ExecuteAdditionalMutationVariables>(Operations.ExecuteAdditional, options);
      }
export type ExecuteAdditionalMutationHookResult = ReturnType<typeof useExecuteAdditionalMutation>;
export type CreateTransactionFilterGroupMutationFn = Apollo.MutationFunction<import('./api').CreateTransactionFilterGroupMutation, import('./api').CreateTransactionFilterGroupMutationVariables>;
export function useCreateTransactionFilterGroupMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateTransactionFilterGroupMutation, import('./api').CreateTransactionFilterGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateTransactionFilterGroupMutation, import('./api').CreateTransactionFilterGroupMutationVariables>(Operations.CreateTransactionFilterGroup, options);
      }
export type CreateTransactionFilterGroupMutationHookResult = ReturnType<typeof useCreateTransactionFilterGroupMutation>;
export type UpdateTransactionFilterGroupMutationFn = Apollo.MutationFunction<import('./api').UpdateTransactionFilterGroupMutation, import('./api').UpdateTransactionFilterGroupMutationVariables>;
export function useUpdateTransactionFilterGroupMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateTransactionFilterGroupMutation, import('./api').UpdateTransactionFilterGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateTransactionFilterGroupMutation, import('./api').UpdateTransactionFilterGroupMutationVariables>(Operations.UpdateTransactionFilterGroup, options);
      }
export type UpdateTransactionFilterGroupMutationHookResult = ReturnType<typeof useUpdateTransactionFilterGroupMutation>;
export type DeleteTransactionFilterGroupMutationFn = Apollo.MutationFunction<import('./api').DeleteTransactionFilterGroupMutation, import('./api').DeleteTransactionFilterGroupMutationVariables>;
export function useDeleteTransactionFilterGroupMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteTransactionFilterGroupMutation, import('./api').DeleteTransactionFilterGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteTransactionFilterGroupMutation, import('./api').DeleteTransactionFilterGroupMutationVariables>(Operations.DeleteTransactionFilterGroup, options);
      }
export type DeleteTransactionFilterGroupMutationHookResult = ReturnType<typeof useDeleteTransactionFilterGroupMutation>;
export type CreateNarrativeTemplateMutationFn = Apollo.MutationFunction<import('./api').CreateNarrativeTemplateMutation, import('./api').CreateNarrativeTemplateMutationVariables>;
export function useCreateNarrativeTemplateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateNarrativeTemplateMutation, import('./api').CreateNarrativeTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateNarrativeTemplateMutation, import('./api').CreateNarrativeTemplateMutationVariables>(Operations.CreateNarrativeTemplate, options);
      }
export type CreateNarrativeTemplateMutationHookResult = ReturnType<typeof useCreateNarrativeTemplateMutation>;
export type UpdateNarrativeTemplateMutationFn = Apollo.MutationFunction<import('./api').UpdateNarrativeTemplateMutation, import('./api').UpdateNarrativeTemplateMutationVariables>;
export function useUpdateNarrativeTemplateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateNarrativeTemplateMutation, import('./api').UpdateNarrativeTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateNarrativeTemplateMutation, import('./api').UpdateNarrativeTemplateMutationVariables>(Operations.UpdateNarrativeTemplate, options);
      }
export type UpdateNarrativeTemplateMutationHookResult = ReturnType<typeof useUpdateNarrativeTemplateMutation>;
export type DeleteNarrativeTemplateMutationFn = Apollo.MutationFunction<import('./api').DeleteNarrativeTemplateMutation, import('./api').DeleteNarrativeTemplateMutationVariables>;
export function useDeleteNarrativeTemplateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteNarrativeTemplateMutation, import('./api').DeleteNarrativeTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteNarrativeTemplateMutation, import('./api').DeleteNarrativeTemplateMutationVariables>(Operations.DeleteNarrativeTemplate, options);
      }
export type DeleteNarrativeTemplateMutationHookResult = ReturnType<typeof useDeleteNarrativeTemplateMutation>;
export type CreateNarrativeMutationFn = Apollo.MutationFunction<import('./api').CreateNarrativeMutation, import('./api').CreateNarrativeMutationVariables>;
export function useCreateNarrativeMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateNarrativeMutation, import('./api').CreateNarrativeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateNarrativeMutation, import('./api').CreateNarrativeMutationVariables>(Operations.CreateNarrative, options);
      }
export type CreateNarrativeMutationHookResult = ReturnType<typeof useCreateNarrativeMutation>;
export type UpdateNarrativeMutationFn = Apollo.MutationFunction<import('./api').UpdateNarrativeMutation, import('./api').UpdateNarrativeMutationVariables>;
export function useUpdateNarrativeMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateNarrativeMutation, import('./api').UpdateNarrativeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateNarrativeMutation, import('./api').UpdateNarrativeMutationVariables>(Operations.UpdateNarrative, options);
      }
export type UpdateNarrativeMutationHookResult = ReturnType<typeof useUpdateNarrativeMutation>;
export type ChangeNarrativeStateMutationFn = Apollo.MutationFunction<import('./api').ChangeNarrativeStateMutation, import('./api').ChangeNarrativeStateMutationVariables>;
export function useChangeNarrativeStateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ChangeNarrativeStateMutation, import('./api').ChangeNarrativeStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ChangeNarrativeStateMutation, import('./api').ChangeNarrativeStateMutationVariables>(Operations.ChangeNarrativeState, options);
      }
export type ChangeNarrativeStateMutationHookResult = ReturnType<typeof useChangeNarrativeStateMutation>;
export type DeleteNarrativeMutationFn = Apollo.MutationFunction<import('./api').DeleteNarrativeMutation, import('./api').DeleteNarrativeMutationVariables>;
export function useDeleteNarrativeMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteNarrativeMutation, import('./api').DeleteNarrativeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteNarrativeMutation, import('./api').DeleteNarrativeMutationVariables>(Operations.DeleteNarrative, options);
      }
export type DeleteNarrativeMutationHookResult = ReturnType<typeof useDeleteNarrativeMutation>;
export type UpdateProviderMutationFn = Apollo.MutationFunction<import('./api').UpdateProviderMutation, import('./api').UpdateProviderMutationVariables>;
export function useUpdateProviderMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateProviderMutation, import('./api').UpdateProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateProviderMutation, import('./api').UpdateProviderMutationVariables>(Operations.UpdateProvider, options);
      }
export type UpdateProviderMutationHookResult = ReturnType<typeof useUpdateProviderMutation>;
export type SyncUnit21AlertsAndCasesMutationFn = Apollo.MutationFunction<import('./api').SyncUnit21AlertsAndCasesMutation, import('./api').SyncUnit21AlertsAndCasesMutationVariables>;
export function useSyncUnit21AlertsAndCasesMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').SyncUnit21AlertsAndCasesMutation, import('./api').SyncUnit21AlertsAndCasesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').SyncUnit21AlertsAndCasesMutation, import('./api').SyncUnit21AlertsAndCasesMutationVariables>(Operations.SyncUnit21AlertsAndCases, options);
      }
export type SyncUnit21AlertsAndCasesMutationHookResult = ReturnType<typeof useSyncUnit21AlertsAndCasesMutation>;
export type CreateTransactionQueueMutationFn = Apollo.MutationFunction<import('./api').CreateTransactionQueueMutation, import('./api').CreateTransactionQueueMutationVariables>;
export function useCreateTransactionQueueMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateTransactionQueueMutation, import('./api').CreateTransactionQueueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateTransactionQueueMutation, import('./api').CreateTransactionQueueMutationVariables>(Operations.CreateTransactionQueue, options);
      }
export type CreateTransactionQueueMutationHookResult = ReturnType<typeof useCreateTransactionQueueMutation>;
export type UpdateTransactionQueueMutationFn = Apollo.MutationFunction<import('./api').UpdateTransactionQueueMutation, import('./api').UpdateTransactionQueueMutationVariables>;
export function useUpdateTransactionQueueMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateTransactionQueueMutation, import('./api').UpdateTransactionQueueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateTransactionQueueMutation, import('./api').UpdateTransactionQueueMutationVariables>(Operations.UpdateTransactionQueue, options);
      }
export type UpdateTransactionQueueMutationHookResult = ReturnType<typeof useUpdateTransactionQueueMutation>;
export type DeleteTransactionQueueMutationFn = Apollo.MutationFunction<import('./api').DeleteTransactionQueueMutation, import('./api').DeleteTransactionQueueMutationVariables>;
export function useDeleteTransactionQueueMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteTransactionQueueMutation, import('./api').DeleteTransactionQueueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteTransactionQueueMutation, import('./api').DeleteTransactionQueueMutationVariables>(Operations.DeleteTransactionQueue, options);
      }
export type DeleteTransactionQueueMutationHookResult = ReturnType<typeof useDeleteTransactionQueueMutation>;
export type CreateTransactionResolutionMutationFn = Apollo.MutationFunction<import('./api').CreateTransactionResolutionMutation, import('./api').CreateTransactionResolutionMutationVariables>;
export function useCreateTransactionResolutionMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateTransactionResolutionMutation, import('./api').CreateTransactionResolutionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateTransactionResolutionMutation, import('./api').CreateTransactionResolutionMutationVariables>(Operations.CreateTransactionResolution, options);
      }
export type CreateTransactionResolutionMutationHookResult = ReturnType<typeof useCreateTransactionResolutionMutation>;
export type CreateRuleMutationFn = Apollo.MutationFunction<import('./api').CreateRuleMutation, import('./api').CreateRuleMutationVariables>;
export function useCreateRuleMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateRuleMutation, import('./api').CreateRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateRuleMutation, import('./api').CreateRuleMutationVariables>(Operations.CreateRule, options);
      }
export type CreateRuleMutationHookResult = ReturnType<typeof useCreateRuleMutation>;
export type TransactionRuleUpdateMutationFn = Apollo.MutationFunction<import('./api').TransactionRuleUpdateMutation, import('./api').TransactionRuleUpdateMutationVariables>;
export function useTransactionRuleUpdateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').TransactionRuleUpdateMutation, import('./api').TransactionRuleUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').TransactionRuleUpdateMutation, import('./api').TransactionRuleUpdateMutationVariables>(Operations.TransactionRuleUpdate, options);
      }
export type TransactionRuleUpdateMutationHookResult = ReturnType<typeof useTransactionRuleUpdateMutation>;
export type TransactionRuleRunScheduledMutationFn = Apollo.MutationFunction<import('./api').TransactionRuleRunScheduledMutation, import('./api').TransactionRuleRunScheduledMutationVariables>;
export function useTransactionRuleRunScheduledMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').TransactionRuleRunScheduledMutation, import('./api').TransactionRuleRunScheduledMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').TransactionRuleRunScheduledMutation, import('./api').TransactionRuleRunScheduledMutationVariables>(Operations.TransactionRuleRunScheduled, options);
      }
export type TransactionRuleRunScheduledMutationHookResult = ReturnType<typeof useTransactionRuleRunScheduledMutation>;
export type CreateRuleSetMutationFn = Apollo.MutationFunction<import('./api').CreateRuleSetMutation, import('./api').CreateRuleSetMutationVariables>;
export function useCreateRuleSetMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateRuleSetMutation, import('./api').CreateRuleSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateRuleSetMutation, import('./api').CreateRuleSetMutationVariables>(Operations.CreateRuleSet, options);
      }
export type CreateRuleSetMutationHookResult = ReturnType<typeof useCreateRuleSetMutation>;
export type UpdateRuleSetMutationFn = Apollo.MutationFunction<import('./api').UpdateRuleSetMutation, import('./api').UpdateRuleSetMutationVariables>;
export function useUpdateRuleSetMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateRuleSetMutation, import('./api').UpdateRuleSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateRuleSetMutation, import('./api').UpdateRuleSetMutationVariables>(Operations.UpdateRuleSet, options);
      }
export type UpdateRuleSetMutationHookResult = ReturnType<typeof useUpdateRuleSetMutation>;
export type ToggleEnableRuleSetMutationFn = Apollo.MutationFunction<import('./api').ToggleEnableRuleSetMutation, import('./api').ToggleEnableRuleSetMutationVariables>;
export function useToggleEnableRuleSetMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ToggleEnableRuleSetMutation, import('./api').ToggleEnableRuleSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ToggleEnableRuleSetMutation, import('./api').ToggleEnableRuleSetMutationVariables>(Operations.ToggleEnableRuleSet, options);
      }
export type ToggleEnableRuleSetMutationHookResult = ReturnType<typeof useToggleEnableRuleSetMutation>;
export type CreateTransactionScoringDependentRuleMutationFn = Apollo.MutationFunction<import('./api').CreateTransactionScoringDependentRuleMutation, import('./api').CreateTransactionScoringDependentRuleMutationVariables>;
export function useCreateTransactionScoringDependentRuleMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateTransactionScoringDependentRuleMutation, import('./api').CreateTransactionScoringDependentRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateTransactionScoringDependentRuleMutation, import('./api').CreateTransactionScoringDependentRuleMutationVariables>(Operations.CreateTransactionScoringDependentRule, options);
      }
export type CreateTransactionScoringDependentRuleMutationHookResult = ReturnType<typeof useCreateTransactionScoringDependentRuleMutation>;
export type UpdateTransactionScoringDependentRuleMutationFn = Apollo.MutationFunction<import('./api').UpdateTransactionScoringDependentRuleMutation, import('./api').UpdateTransactionScoringDependentRuleMutationVariables>;
export function useUpdateTransactionScoringDependentRuleMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateTransactionScoringDependentRuleMutation, import('./api').UpdateTransactionScoringDependentRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateTransactionScoringDependentRuleMutation, import('./api').UpdateTransactionScoringDependentRuleMutationVariables>(Operations.UpdateTransactionScoringDependentRule, options);
      }
export type UpdateTransactionScoringDependentRuleMutationHookResult = ReturnType<typeof useUpdateTransactionScoringDependentRuleMutation>;
export type DeleteTransactionScoringDependentRuleMutationFn = Apollo.MutationFunction<import('./api').DeleteTransactionScoringDependentRuleMutation, import('./api').DeleteTransactionScoringDependentRuleMutationVariables>;
export function useDeleteTransactionScoringDependentRuleMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteTransactionScoringDependentRuleMutation, import('./api').DeleteTransactionScoringDependentRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteTransactionScoringDependentRuleMutation, import('./api').DeleteTransactionScoringDependentRuleMutationVariables>(Operations.DeleteTransactionScoringDependentRule, options);
      }
export type DeleteTransactionScoringDependentRuleMutationHookResult = ReturnType<typeof useDeleteTransactionScoringDependentRuleMutation>;
export type CreateTransactionScoringManualRuleMutationFn = Apollo.MutationFunction<import('./api').CreateTransactionScoringManualRuleMutation, import('./api').CreateTransactionScoringManualRuleMutationVariables>;
export function useCreateTransactionScoringManualRuleMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateTransactionScoringManualRuleMutation, import('./api').CreateTransactionScoringManualRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateTransactionScoringManualRuleMutation, import('./api').CreateTransactionScoringManualRuleMutationVariables>(Operations.CreateTransactionScoringManualRule, options);
      }
export type CreateTransactionScoringManualRuleMutationHookResult = ReturnType<typeof useCreateTransactionScoringManualRuleMutation>;
export type UpdateTransactionScoringManualRuleMutationFn = Apollo.MutationFunction<import('./api').UpdateTransactionScoringManualRuleMutation, import('./api').UpdateTransactionScoringManualRuleMutationVariables>;
export function useUpdateTransactionScoringManualRuleMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateTransactionScoringManualRuleMutation, import('./api').UpdateTransactionScoringManualRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateTransactionScoringManualRuleMutation, import('./api').UpdateTransactionScoringManualRuleMutationVariables>(Operations.UpdateTransactionScoringManualRule, options);
      }
export type UpdateTransactionScoringManualRuleMutationHookResult = ReturnType<typeof useUpdateTransactionScoringManualRuleMutation>;
export type DeleteTransactionScoringManualRuleMutationFn = Apollo.MutationFunction<import('./api').DeleteTransactionScoringManualRuleMutation, import('./api').DeleteTransactionScoringManualRuleMutationVariables>;
export function useDeleteTransactionScoringManualRuleMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteTransactionScoringManualRuleMutation, import('./api').DeleteTransactionScoringManualRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteTransactionScoringManualRuleMutation, import('./api').DeleteTransactionScoringManualRuleMutationVariables>(Operations.DeleteTransactionScoringManualRule, options);
      }
export type DeleteTransactionScoringManualRuleMutationHookResult = ReturnType<typeof useDeleteTransactionScoringManualRuleMutation>;
export type CreateTransactionScoringMatrixMutationFn = Apollo.MutationFunction<import('./api').CreateTransactionScoringMatrixMutation, import('./api').CreateTransactionScoringMatrixMutationVariables>;
export function useCreateTransactionScoringMatrixMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateTransactionScoringMatrixMutation, import('./api').CreateTransactionScoringMatrixMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateTransactionScoringMatrixMutation, import('./api').CreateTransactionScoringMatrixMutationVariables>(Operations.CreateTransactionScoringMatrix, options);
      }
export type CreateTransactionScoringMatrixMutationHookResult = ReturnType<typeof useCreateTransactionScoringMatrixMutation>;
export type UpdateTransactionScoringMatrixMutationFn = Apollo.MutationFunction<import('./api').UpdateTransactionScoringMatrixMutation, import('./api').UpdateTransactionScoringMatrixMutationVariables>;
export function useUpdateTransactionScoringMatrixMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateTransactionScoringMatrixMutation, import('./api').UpdateTransactionScoringMatrixMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateTransactionScoringMatrixMutation, import('./api').UpdateTransactionScoringMatrixMutationVariables>(Operations.UpdateTransactionScoringMatrix, options);
      }
export type UpdateTransactionScoringMatrixMutationHookResult = ReturnType<typeof useUpdateTransactionScoringMatrixMutation>;
export type DeleteTransactionScoringMatrixMutationFn = Apollo.MutationFunction<import('./api').DeleteTransactionScoringMatrixMutation, import('./api').DeleteTransactionScoringMatrixMutationVariables>;
export function useDeleteTransactionScoringMatrixMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteTransactionScoringMatrixMutation, import('./api').DeleteTransactionScoringMatrixMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteTransactionScoringMatrixMutation, import('./api').DeleteTransactionScoringMatrixMutationVariables>(Operations.DeleteTransactionScoringMatrix, options);
      }
export type DeleteTransactionScoringMatrixMutationHookResult = ReturnType<typeof useDeleteTransactionScoringMatrixMutation>;
export type ChangeEnabledTransactionScoringMatrixMutationFn = Apollo.MutationFunction<import('./api').ChangeEnabledTransactionScoringMatrixMutation, import('./api').ChangeEnabledTransactionScoringMatrixMutationVariables>;
export function useChangeEnabledTransactionScoringMatrixMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ChangeEnabledTransactionScoringMatrixMutation, import('./api').ChangeEnabledTransactionScoringMatrixMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ChangeEnabledTransactionScoringMatrixMutation, import('./api').ChangeEnabledTransactionScoringMatrixMutationVariables>(Operations.ChangeEnabledTransactionScoringMatrix, options);
      }
export type ChangeEnabledTransactionScoringMatrixMutationHookResult = ReturnType<typeof useChangeEnabledTransactionScoringMatrixMutation>;
export type ChangeTestTransactionScoringMatrixMutationFn = Apollo.MutationFunction<import('./api').ChangeTestTransactionScoringMatrixMutation, import('./api').ChangeTestTransactionScoringMatrixMutationVariables>;
export function useChangeTestTransactionScoringMatrixMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ChangeTestTransactionScoringMatrixMutation, import('./api').ChangeTestTransactionScoringMatrixMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ChangeTestTransactionScoringMatrixMutation, import('./api').ChangeTestTransactionScoringMatrixMutationVariables>(Operations.ChangeTestTransactionScoringMatrix, options);
      }
export type ChangeTestTransactionScoringMatrixMutationHookResult = ReturnType<typeof useChangeTestTransactionScoringMatrixMutation>;
export type RunTransactionScoringMatrixMutationFn = Apollo.MutationFunction<import('./api').RunTransactionScoringMatrixMutation, import('./api').RunTransactionScoringMatrixMutationVariables>;
export function useRunTransactionScoringMatrixMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').RunTransactionScoringMatrixMutation, import('./api').RunTransactionScoringMatrixMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').RunTransactionScoringMatrixMutation, import('./api').RunTransactionScoringMatrixMutationVariables>(Operations.RunTransactionScoringMatrix, options);
      }
export type RunTransactionScoringMatrixMutationHookResult = ReturnType<typeof useRunTransactionScoringMatrixMutation>;
export type DownloadTransactionScoringMatrixXlsxMutationFn = Apollo.MutationFunction<import('./api').DownloadTransactionScoringMatrixXlsxMutation, import('./api').DownloadTransactionScoringMatrixXlsxMutationVariables>;
export function useDownloadTransactionScoringMatrixXlsxMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DownloadTransactionScoringMatrixXlsxMutation, import('./api').DownloadTransactionScoringMatrixXlsxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DownloadTransactionScoringMatrixXlsxMutation, import('./api').DownloadTransactionScoringMatrixXlsxMutationVariables>(Operations.DownloadTransactionScoringMatrixXlsx, options);
      }
export type DownloadTransactionScoringMatrixXlsxMutationHookResult = ReturnType<typeof useDownloadTransactionScoringMatrixXlsxMutation>;
export type RunTransactionsScoringMatrixMutationFn = Apollo.MutationFunction<import('./api').RunTransactionsScoringMatrixMutation, import('./api').RunTransactionsScoringMatrixMutationVariables>;
export function useRunTransactionsScoringMatrixMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').RunTransactionsScoringMatrixMutation, import('./api').RunTransactionsScoringMatrixMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').RunTransactionsScoringMatrixMutation, import('./api').RunTransactionsScoringMatrixMutationVariables>(Operations.RunTransactionsScoringMatrix, options);
      }
export type RunTransactionsScoringMatrixMutationHookResult = ReturnType<typeof useRunTransactionsScoringMatrixMutation>;
export type DownloadTransactionsScoringMatrixXlsxMutationFn = Apollo.MutationFunction<import('./api').DownloadTransactionsScoringMatrixXlsxMutation, import('./api').DownloadTransactionsScoringMatrixXlsxMutationVariables>;
export function useDownloadTransactionsScoringMatrixXlsxMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DownloadTransactionsScoringMatrixXlsxMutation, import('./api').DownloadTransactionsScoringMatrixXlsxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DownloadTransactionsScoringMatrixXlsxMutation, import('./api').DownloadTransactionsScoringMatrixXlsxMutationVariables>(Operations.DownloadTransactionsScoringMatrixXlsx, options);
      }
export type DownloadTransactionsScoringMatrixXlsxMutationHookResult = ReturnType<typeof useDownloadTransactionsScoringMatrixXlsxMutation>;
export type CreateTransactionScoringRuleMutationFn = Apollo.MutationFunction<import('./api').CreateTransactionScoringRuleMutation, import('./api').CreateTransactionScoringRuleMutationVariables>;
export function useCreateTransactionScoringRuleMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateTransactionScoringRuleMutation, import('./api').CreateTransactionScoringRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateTransactionScoringRuleMutation, import('./api').CreateTransactionScoringRuleMutationVariables>(Operations.CreateTransactionScoringRule, options);
      }
export type CreateTransactionScoringRuleMutationHookResult = ReturnType<typeof useCreateTransactionScoringRuleMutation>;
export type UpdateTransactionScoringRuleMutationFn = Apollo.MutationFunction<import('./api').UpdateTransactionScoringRuleMutation, import('./api').UpdateTransactionScoringRuleMutationVariables>;
export function useUpdateTransactionScoringRuleMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateTransactionScoringRuleMutation, import('./api').UpdateTransactionScoringRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateTransactionScoringRuleMutation, import('./api').UpdateTransactionScoringRuleMutationVariables>(Operations.UpdateTransactionScoringRule, options);
      }
export type UpdateTransactionScoringRuleMutationHookResult = ReturnType<typeof useUpdateTransactionScoringRuleMutation>;
export type DeleteTransactionScoringRuleMutationFn = Apollo.MutationFunction<import('./api').DeleteTransactionScoringRuleMutation, import('./api').DeleteTransactionScoringRuleMutationVariables>;
export function useDeleteTransactionScoringRuleMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteTransactionScoringRuleMutation, import('./api').DeleteTransactionScoringRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteTransactionScoringRuleMutation, import('./api').DeleteTransactionScoringRuleMutationVariables>(Operations.DeleteTransactionScoringRule, options);
      }
export type DeleteTransactionScoringRuleMutationHookResult = ReturnType<typeof useDeleteTransactionScoringRuleMutation>;
export type CreateTransactionScoringScoreRuleMutationFn = Apollo.MutationFunction<import('./api').CreateTransactionScoringScoreRuleMutation, import('./api').CreateTransactionScoringScoreRuleMutationVariables>;
export function useCreateTransactionScoringScoreRuleMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateTransactionScoringScoreRuleMutation, import('./api').CreateTransactionScoringScoreRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateTransactionScoringScoreRuleMutation, import('./api').CreateTransactionScoringScoreRuleMutationVariables>(Operations.CreateTransactionScoringScoreRule, options);
      }
export type CreateTransactionScoringScoreRuleMutationHookResult = ReturnType<typeof useCreateTransactionScoringScoreRuleMutation>;
export type UpdateTransactionScoringScoreRuleMutationFn = Apollo.MutationFunction<import('./api').UpdateTransactionScoringScoreRuleMutation, import('./api').UpdateTransactionScoringScoreRuleMutationVariables>;
export function useUpdateTransactionScoringScoreRuleMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateTransactionScoringScoreRuleMutation, import('./api').UpdateTransactionScoringScoreRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateTransactionScoringScoreRuleMutation, import('./api').UpdateTransactionScoringScoreRuleMutationVariables>(Operations.UpdateTransactionScoringScoreRule, options);
      }
export type UpdateTransactionScoringScoreRuleMutationHookResult = ReturnType<typeof useUpdateTransactionScoringScoreRuleMutation>;
export type DeleteTransactionScoringScoreRuleMutationFn = Apollo.MutationFunction<import('./api').DeleteTransactionScoringScoreRuleMutation, import('./api').DeleteTransactionScoringScoreRuleMutationVariables>;
export function useDeleteTransactionScoringScoreRuleMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteTransactionScoringScoreRuleMutation, import('./api').DeleteTransactionScoringScoreRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteTransactionScoringScoreRuleMutation, import('./api').DeleteTransactionScoringScoreRuleMutationVariables>(Operations.DeleteTransactionScoringScoreRule, options);
      }
export type DeleteTransactionScoringScoreRuleMutationHookResult = ReturnType<typeof useDeleteTransactionScoringScoreRuleMutation>;
export type CreateTransactionTagMutationFn = Apollo.MutationFunction<import('./api').CreateTransactionTagMutation, import('./api').CreateTransactionTagMutationVariables>;
export function useCreateTransactionTagMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateTransactionTagMutation, import('./api').CreateTransactionTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateTransactionTagMutation, import('./api').CreateTransactionTagMutationVariables>(Operations.CreateTransactionTag, options);
      }
export type CreateTransactionTagMutationHookResult = ReturnType<typeof useCreateTransactionTagMutation>;
export type UpdateTransactionTagMutationFn = Apollo.MutationFunction<import('./api').UpdateTransactionTagMutation, import('./api').UpdateTransactionTagMutationVariables>;
export function useUpdateTransactionTagMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateTransactionTagMutation, import('./api').UpdateTransactionTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateTransactionTagMutation, import('./api').UpdateTransactionTagMutationVariables>(Operations.UpdateTransactionTag, options);
      }
export type UpdateTransactionTagMutationHookResult = ReturnType<typeof useUpdateTransactionTagMutation>;
export type DeleteTransactionTagMutationFn = Apollo.MutationFunction<import('./api').DeleteTransactionTagMutation, import('./api').DeleteTransactionTagMutationVariables>;
export function useDeleteTransactionTagMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteTransactionTagMutation, import('./api').DeleteTransactionTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteTransactionTagMutation, import('./api').DeleteTransactionTagMutationVariables>(Operations.DeleteTransactionTag, options);
      }
export type DeleteTransactionTagMutationHookResult = ReturnType<typeof useDeleteTransactionTagMutation>;
export type CloseManyAlertsMutationFn = Apollo.MutationFunction<import('./api').CloseManyAlertsMutation, import('./api').CloseManyAlertsMutationVariables>;
export function useCloseManyAlertsMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CloseManyAlertsMutation, import('./api').CloseManyAlertsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CloseManyAlertsMutation, import('./api').CloseManyAlertsMutationVariables>(Operations.CloseManyAlerts, options);
      }
export type CloseManyAlertsMutationHookResult = ReturnType<typeof useCloseManyAlertsMutation>;
export type ChangeAlertStateMutationFn = Apollo.MutationFunction<import('./api').ChangeAlertStateMutation, import('./api').ChangeAlertStateMutationVariables>;
export function useChangeAlertStateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ChangeAlertStateMutation, import('./api').ChangeAlertStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ChangeAlertStateMutation, import('./api').ChangeAlertStateMutationVariables>(Operations.ChangeAlertState, options);
      }
export type ChangeAlertStateMutationHookResult = ReturnType<typeof useChangeAlertStateMutation>;
export type UpdateAlertMutationFn = Apollo.MutationFunction<import('./api').UpdateAlertMutation, import('./api').UpdateAlertMutationVariables>;
export function useUpdateAlertMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateAlertMutation, import('./api').UpdateAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateAlertMutation, import('./api').UpdateAlertMutationVariables>(Operations.UpdateAlert, options);
      }
export type UpdateAlertMutationHookResult = ReturnType<typeof useUpdateAlertMutation>;
export type AssignAlertMutationFn = Apollo.MutationFunction<import('./api').AssignAlertMutation, import('./api').AssignAlertMutationVariables>;
export function useAssignAlertMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').AssignAlertMutation, import('./api').AssignAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').AssignAlertMutation, import('./api').AssignAlertMutationVariables>(Operations.AssignAlert, options);
      }
export type AssignAlertMutationHookResult = ReturnType<typeof useAssignAlertMutation>;
export type LinkProfileAlertMutationFn = Apollo.MutationFunction<import('./api').LinkProfileAlertMutation, import('./api').LinkProfileAlertMutationVariables>;
export function useLinkProfileAlertMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').LinkProfileAlertMutation, import('./api').LinkProfileAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').LinkProfileAlertMutation, import('./api').LinkProfileAlertMutationVariables>(Operations.LinkProfileAlert, options);
      }
export type LinkProfileAlertMutationHookResult = ReturnType<typeof useLinkProfileAlertMutation>;
export type UnlinkProfileAlertMutationFn = Apollo.MutationFunction<import('./api').UnlinkProfileAlertMutation, import('./api').UnlinkProfileAlertMutationVariables>;
export function useUnlinkProfileAlertMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UnlinkProfileAlertMutation, import('./api').UnlinkProfileAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UnlinkProfileAlertMutation, import('./api').UnlinkProfileAlertMutationVariables>(Operations.UnlinkProfileAlert, options);
      }
export type UnlinkProfileAlertMutationHookResult = ReturnType<typeof useUnlinkProfileAlertMutation>;
export type ChangeDispositionAlertMutationFn = Apollo.MutationFunction<import('./api').ChangeDispositionAlertMutation, import('./api').ChangeDispositionAlertMutationVariables>;
export function useChangeDispositionAlertMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ChangeDispositionAlertMutation, import('./api').ChangeDispositionAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ChangeDispositionAlertMutation, import('./api').ChangeDispositionAlertMutationVariables>(Operations.ChangeDispositionAlert, options);
      }
export type ChangeDispositionAlertMutationHookResult = ReturnType<typeof useChangeDispositionAlertMutation>;
export type LinkToCaseAlertMutationFn = Apollo.MutationFunction<import('./api').LinkToCaseAlertMutation, import('./api').LinkToCaseAlertMutationVariables>;
export function useLinkToCaseAlertMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').LinkToCaseAlertMutation, import('./api').LinkToCaseAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').LinkToCaseAlertMutation, import('./api').LinkToCaseAlertMutationVariables>(Operations.LinkToCaseAlert, options);
      }
export type LinkToCaseAlertMutationHookResult = ReturnType<typeof useLinkToCaseAlertMutation>;
export type CreateTransactionCaseMutationFn = Apollo.MutationFunction<import('./api').CreateTransactionCaseMutation, import('./api').CreateTransactionCaseMutationVariables>;
export function useCreateTransactionCaseMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CreateTransactionCaseMutation, import('./api').CreateTransactionCaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CreateTransactionCaseMutation, import('./api').CreateTransactionCaseMutationVariables>(Operations.CreateTransactionCase, options);
      }
export type CreateTransactionCaseMutationHookResult = ReturnType<typeof useCreateTransactionCaseMutation>;
export type UpdateTransactionCaseMutationFn = Apollo.MutationFunction<import('./api').UpdateTransactionCaseMutation, import('./api').UpdateTransactionCaseMutationVariables>;
export function useUpdateTransactionCaseMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateTransactionCaseMutation, import('./api').UpdateTransactionCaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateTransactionCaseMutation, import('./api').UpdateTransactionCaseMutationVariables>(Operations.UpdateTransactionCase, options);
      }
export type UpdateTransactionCaseMutationHookResult = ReturnType<typeof useUpdateTransactionCaseMutation>;
export type TransactionCaseOpenMutationFn = Apollo.MutationFunction<import('./api').TransactionCaseOpenMutation, import('./api').TransactionCaseOpenMutationVariables>;
export function useTransactionCaseOpenMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').TransactionCaseOpenMutation, import('./api').TransactionCaseOpenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').TransactionCaseOpenMutation, import('./api').TransactionCaseOpenMutationVariables>(Operations.TransactionCaseOpen, options);
      }
export type TransactionCaseOpenMutationHookResult = ReturnType<typeof useTransactionCaseOpenMutation>;
export type TransactionCaseCloseMutationFn = Apollo.MutationFunction<import('./api').TransactionCaseCloseMutation, import('./api').TransactionCaseCloseMutationVariables>;
export function useTransactionCaseCloseMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').TransactionCaseCloseMutation, import('./api').TransactionCaseCloseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').TransactionCaseCloseMutation, import('./api').TransactionCaseCloseMutationVariables>(Operations.TransactionCaseClose, options);
      }
export type TransactionCaseCloseMutationHookResult = ReturnType<typeof useTransactionCaseCloseMutation>;
export type TransactionCaseChangeStateMutationFn = Apollo.MutationFunction<import('./api').TransactionCaseChangeStateMutation, import('./api').TransactionCaseChangeStateMutationVariables>;
export function useTransactionCaseChangeStateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').TransactionCaseChangeStateMutation, import('./api').TransactionCaseChangeStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').TransactionCaseChangeStateMutation, import('./api').TransactionCaseChangeStateMutationVariables>(Operations.TransactionCaseChangeState, options);
      }
export type TransactionCaseChangeStateMutationHookResult = ReturnType<typeof useTransactionCaseChangeStateMutation>;
export type UpdateTransactionActivityTypeMutationFn = Apollo.MutationFunction<import('./api').UpdateTransactionActivityTypeMutation, import('./api').UpdateTransactionActivityTypeMutationVariables>;
export function useUpdateTransactionActivityTypeMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateTransactionActivityTypeMutation, import('./api').UpdateTransactionActivityTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateTransactionActivityTypeMutation, import('./api').UpdateTransactionActivityTypeMutationVariables>(Operations.UpdateTransactionActivityType, options);
      }
export type UpdateTransactionActivityTypeMutationHookResult = ReturnType<typeof useUpdateTransactionActivityTypeMutation>;
export type UpdateTransactionCaseOriginMutationFn = Apollo.MutationFunction<import('./api').UpdateTransactionCaseOriginMutation, import('./api').UpdateTransactionCaseOriginMutationVariables>;
export function useUpdateTransactionCaseOriginMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateTransactionCaseOriginMutation, import('./api').UpdateTransactionCaseOriginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateTransactionCaseOriginMutation, import('./api').UpdateTransactionCaseOriginMutationVariables>(Operations.UpdateTransactionCaseOrigin, options);
      }
export type UpdateTransactionCaseOriginMutationHookResult = ReturnType<typeof useUpdateTransactionCaseOriginMutation>;
export type UpdateTransactionResolutionMutationFn = Apollo.MutationFunction<import('./api').UpdateTransactionResolutionMutation, import('./api').UpdateTransactionResolutionMutationVariables>;
export function useUpdateTransactionResolutionMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UpdateTransactionResolutionMutation, import('./api').UpdateTransactionResolutionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UpdateTransactionResolutionMutation, import('./api').UpdateTransactionResolutionMutationVariables>(Operations.UpdateTransactionResolution, options);
      }
export type UpdateTransactionResolutionMutationHookResult = ReturnType<typeof useUpdateTransactionResolutionMutation>;
export type LinkExternalRefTransactionCaseMutationFn = Apollo.MutationFunction<import('./api').LinkExternalRefTransactionCaseMutation, import('./api').LinkExternalRefTransactionCaseMutationVariables>;
export function useLinkExternalRefTransactionCaseMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').LinkExternalRefTransactionCaseMutation, import('./api').LinkExternalRefTransactionCaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').LinkExternalRefTransactionCaseMutation, import('./api').LinkExternalRefTransactionCaseMutationVariables>(Operations.LinkExternalRefTransactionCase, options);
      }
export type LinkExternalRefTransactionCaseMutationHookResult = ReturnType<typeof useLinkExternalRefTransactionCaseMutation>;
export type UnlinkExternalRefTransactionCaseMutationFn = Apollo.MutationFunction<import('./api').UnlinkExternalRefTransactionCaseMutation, import('./api').UnlinkExternalRefTransactionCaseMutationVariables>;
export function useUnlinkExternalRefTransactionCaseMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').UnlinkExternalRefTransactionCaseMutation, import('./api').UnlinkExternalRefTransactionCaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').UnlinkExternalRefTransactionCaseMutation, import('./api').UnlinkExternalRefTransactionCaseMutationVariables>(Operations.UnlinkExternalRefTransactionCase, options);
      }
export type UnlinkExternalRefTransactionCaseMutationHookResult = ReturnType<typeof useUnlinkExternalRefTransactionCaseMutation>;
export type ShortifyMutationFn = Apollo.MutationFunction<import('./api').ShortifyMutation, import('./api').ShortifyMutationVariables>;
export function useShortifyMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').ShortifyMutation, import('./api').ShortifyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').ShortifyMutation, import('./api').ShortifyMutationVariables>(Operations.Shortify, options);
      }
export type ShortifyMutationHookResult = ReturnType<typeof useShortifyMutation>;
export type CustomerVerificationCreateMutationFn = Apollo.MutationFunction<import('./api').CustomerVerificationCreateMutation, import('./api').CustomerVerificationCreateMutationVariables>;
export function useCustomerVerificationCreateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').CustomerVerificationCreateMutation, import('./api').CustomerVerificationCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').CustomerVerificationCreateMutation, import('./api').CustomerVerificationCreateMutationVariables>(Operations.CustomerVerificationCreate, options);
      }
export type CustomerVerificationCreateMutationHookResult = ReturnType<typeof useCustomerVerificationCreateMutation>;
export type AdditionalCustomerCreateMutationFn = Apollo.MutationFunction<import('./api').AdditionalCustomerCreateMutation, import('./api').AdditionalCustomerCreateMutationVariables>;
export function useAdditionalCustomerCreateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').AdditionalCustomerCreateMutation, import('./api').AdditionalCustomerCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').AdditionalCustomerCreateMutation, import('./api').AdditionalCustomerCreateMutationVariables>(Operations.AdditionalCustomerCreate, options);
      }
export type AdditionalCustomerCreateMutationHookResult = ReturnType<typeof useAdditionalCustomerCreateMutation>;
export type DeleteAdditionalCustomerMutationFn = Apollo.MutationFunction<import('./api').DeleteAdditionalCustomerMutation, import('./api').DeleteAdditionalCustomerMutationVariables>;
export function useDeleteAdditionalCustomerMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').DeleteAdditionalCustomerMutation, import('./api').DeleteAdditionalCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').DeleteAdditionalCustomerMutation, import('./api').DeleteAdditionalCustomerMutationVariables>(Operations.DeleteAdditionalCustomer, options);
      }
export type DeleteAdditionalCustomerMutationHookResult = ReturnType<typeof useDeleteAdditionalCustomerMutation>;
export type VerificationFlowCreateMutationFn = Apollo.MutationFunction<import('./api').VerificationFlowCreateMutation, import('./api').VerificationFlowCreateMutationVariables>;
export function useVerificationFlowCreateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').VerificationFlowCreateMutation, import('./api').VerificationFlowCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').VerificationFlowCreateMutation, import('./api').VerificationFlowCreateMutationVariables>(Operations.VerificationFlowCreate, options);
      }
export type VerificationFlowCreateMutationHookResult = ReturnType<typeof useVerificationFlowCreateMutation>;
export type VerificationFlowUpdateMutationFn = Apollo.MutationFunction<import('./api').VerificationFlowUpdateMutation, import('./api').VerificationFlowUpdateMutationVariables>;
export function useVerificationFlowUpdateMutation(baseOptions?: Apollo.MutationHookOptions<import('./api').VerificationFlowUpdateMutation, import('./api').VerificationFlowUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<import('./api').VerificationFlowUpdateMutation, import('./api').VerificationFlowUpdateMutationVariables>(Operations.VerificationFlowUpdate, options);
      }
export type VerificationFlowUpdateMutationHookResult = ReturnType<typeof useVerificationFlowUpdateMutation>;