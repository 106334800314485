import gql from 'graphql-tag';
import { VERIFICATION_FLOW_STEP_FRAGMENT } from './VerificationFlowStep';

export const VERIFICATION_STEP_PASSING_FRAGMENT = gql`
  ${VERIFICATION_FLOW_STEP_FRAGMENT}
  fragment VerificationStepPassingFragment on VerificationStepPassing {
    created_at
    customer_verification_id
    form_submission_id
    id
    idenfy_status
    status
    updated_at
    veriff_result @type(name: "IdvResultStepPassing"){
      idv_result_registry @type(name: "IdvResultRegistryStepPassing") {
        id
      }
    }
    idenfy_result @type(name: "IdvResultStepPassing"){
      idv_result_registry @type(name: "IdvResultRegistryStepPassing") {
        id
      }
    }
    verification_step @type(name: "VerificationFlowStep") {
      ...VerificationFlowStepFragment
    }
    verification_step_id
  }
`;
