import {
  ContentLoader,
  GridContainer,
} from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="20">
      <ContentLoader width="100%" height="33">
        <rect rx={8} ry={8} width="100%" height="33" />
      </ContentLoader>
      <ContentLoader width="100%" height="22">
        <rect rx={8} ry={8} width="100%" height="22" />
      </ContentLoader>
      <ContentLoader width="100%" height="800">
        <rect rx={8} ry={8} width="100%" height="800" />
      </ContentLoader>
    </GridContainer>
  );
}
