import gql from 'graphql-tag';

export const DataProfiles = gql`
  query DataProfiles(
    $page: Int
  ) {
    dataProfiles(
      page: $page
      per: 20
      )
      @rest(
        type: "ResponseDataProfiles"
        path: "/core/client/data_profiles?{args}"
      ) {
      data @type(name: "DataProfiles") {
        id
        client_id
        data_profile_config_id
        updated_at
        created_at
        uniq_code
        data_profile_fields @type(name: "DataProfileFiled") {
          id
          data_profile_field_config_id
          data_profile_field_id
          data_profile_id
          value
          data_profile_field_config @type(name: "DataProfileFiledConfig") {
            id
            code
            data_profile_config_id
            data_type
            is_required
            name
            created_at
            updated_at
          }
          updated_at
          created_at
        }
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`

export const DataProfile = gql`
  query DataProfile($id: String!) {
    dataProfile(id: $id)
      @rest(type: "ResponseDataProfile", path: "/core/client/data_profiles/{args.id}") {
      data @type(name: "DataProfile") {
        id
        uniq_code
        client_id
        data_profile_config_id
        updated_at
        created_at
        data_profile_fields @type(name: "DataProfileFiled") {
          id
          data_profile_field_config_id
          data_profile_field_id
          data_profile_id
          value
          data_profile_field_config @type(name: "DataProfileFiledConfig") {
            id
            code
            data_profile_config_id
            data_type
            is_required
            name
            created_at
            updated_at
          }
          updated_at
          created_at
        }
      }
      success
    }
  }
`;

export const DataProfileFieldConfigs = gql`
  query DataProfileFieldConfigs($per: Int!, $page: Int!, $data_profile_id: String!) {
    dataProfileFieldConfigs(per: $per,  page: $page, data_profile_id: $data_profile_id)
      @rest(type: "ResponseDataProfileFieldConfigs", path: "/core/client/data_profile_field_configs?{args}") {
      data @type(name: "DataProfileFiledConfig") {
        id
        code
        data_profile_config_id
        data_type
        is_required
        name
        created_at
        updated_at
        data_profile_field_config_id
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;