import gql from 'graphql-tag';
import { IDENFY_RESULT_FRAGMENT } from '../identityVerifications/IdenfyResult';
import { VERIFF_RESULT_FRAGMENT } from '../identityVerifications/VeriffResult';
import { CUSTOMER_FRAGMENT } from '../../../cm';
import { PROFILE_FRAGMENT } from '../../../profiles/profiles';
import { ATTACHMENT_FRAGMENT } from '../../../attachment';

export const CASE_SECTION_ITEM_FRAGMENT = gql`
  ${IDENFY_RESULT_FRAGMENT}
  ${VERIFF_RESULT_FRAGMENT}
  ${CUSTOMER_FRAGMENT}
  ${PROFILE_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
  fragment CaseSectionItemFragment on CaseSectionItem {
    ai_task
    value
    naics_name
    audit_id
    case_section_tab_id
    checkup_id
    checkup_result
    created_at
    created_by
    created_by_id
    description
    device_info
    form_audit
    form_field
    form_group
    form_screen
    created_at
    file_name
    file_size
    form_submission_id
    id
    updated_at
    uploaded_document_id
    kind
    customer_document_id
    case_question @type(name: "CaseQuestion") {
      id
      answer_body
      author @type(name: "Account") {
        avatar_id
        email
        file_name
        file_size
        full_name
        id
        phone
      }
      case_id
      case_question_config_id
      comment
      created_at
      is_marked_as_actual
      is_marked_as_irrelevant
      question_body
      status
      updated_at
    }
    case_summary @type(name: "AISummary") {
      id
      body
      case_id
      case_summary_config_id
      client_id
      created_at
      current_revision @type(name: "AISummaryRevision") {
        id
        body
        case_summary_id
        client_id
        comment
        created_at
        edited_at
        is_marked_as_actual
        is_marked_as_irrelevant
        request_prompt
        status
        updated_at
        edited_by @type(name: "Account") {
          id
          avatar_id
          email
          file_name
          file_size
          full_name
          phone
        }
      }
      revisions_count
      updated_at
      used_sources
    }
    customer_document @type(name: "CustomerDocument") {
      id
      client_id
      veriff_result_id
      idenfy_result_id
      created_at
      updated_at
      date_of_birth
      date_of_issue
      expiry_date
      first_name
      last_name
      gender
      document_number
      document_type
      blacklisted
      country
      nationality
      attachments @type(name: "Attachment") {
        ...AttachmentFragment
      }
      profiles @type(name: "Profile") {
        ...ProfileFragment
      }
      customers @type(name: "Customer") {
        ...CustomerFragment
      }
    }
    customer_message @type(name: "CustomerMessage") {
      id
      body
      client_id
      created_at
      name
      state
      subject
      updated_at
      email
      account @type(name: "Account") {
        id
        full_name
      }
      header
      sent_at
    }
    idenfy_result @type(name: "IdenfyResult") {
      ...IdenfyResultFragment
    }
    veriff_result @type(name: "VeriffResult") {
      ...VeriffResultFragment
    }
    stripe_checkout @type(name: "StripeCheckout") {
      amount_discount
      amount_total
      currency
      id
      session_id
      status
      verification_step_passing_id
    }
    case_custom_step_evaluation @type(name: "CaseCustomStepEvaluation") {
      id
      custom_step_action_evaluations @type(name: "CustomStepActionEvaluation") {
        id
        value
        uploaded_document_id
        file_name
        file_size
        custom_step_action @type(name: "CustomStepAction") {
          id
          kind
          mandatory
          name
          repeatable
        }
      }
      custom_step @type(name: "CustomStep") {
        id
        name
        custom_actions @type(name: "CustomStepAction") {
          id
          kind
          mandatory
          name
          repeatable
        }
      }
    }
    data_profile @type(name: "DataProfile") {
      id
      uniq_code
      client_id
      data_profile_config_id
      updated_at
      created_at
      data_profile_fields @type(name: "DataProfileFiled") {
        id
        data_profile_field_config_id
        data_profile_field_id
        data_profile_id
        value
        data_profile_field_config @type(name: "DataProfileFiledConfig") {
          id
          code
          data_profile_config_id
          data_type
          is_required
          name
          created_at
          updated_at
        }
        updated_at
        created_at
      }
    }
    case_section_tab_id
    description
  }
`;
