import {
  ErrorPages,
  ProtectedFeaturesRoute,
  ProtectedRoute,
} from '@aid-module/ui';
import { FEATURES, PERMISSIONS } from '@aid-package/context';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Create, Register, Update } from './builder';
import { Verifications } from './verifications';
import {
  VerificationFlowCreate,
  VerificationFlowRegister,
  CustomerVerificationDescription,
} from './VerificationFlow';
import {
  ClientDictionariesRegister,
  ClientDictionaryCreate,
  ClientDictionaryUpdate,
} from './clientDictionaries';
import { VerificationFlowUpdate } from './VerificationFlow/update';
import {
  RedirectFormMappings,
  RedirectForms,
  RedirectVerification,
} from './redirect';
import { FormMappingsDescription, FormMappingsRegister } from './formMappings';
import { AddressesRegister } from './Addresses';
import { AccountStatusesRegister } from './AccountStatuses';
import { CompaniesRegister, CompanyDescription } from './Companies';
import { IdvResultsRegister } from './IdvResults';
import { OnboardingFlowsRegister } from './OnboardingFlows';
import { PeriodicReviewsRegister } from './PeriodicReviews';
import { PeoplesRegister, PersonDescription } from './Peoples';
import { RiskAssessmentsRegister } from './RiskAssessments';
import { ScreeningChecksRegister } from './ScreeningChecks';
import { FormMappingExportsRegister } from './formMappingExports';
import { Form } from './CaseConfig/components/AIConfig/Form';

export function BuilderRouter() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute
            check={[
              PERMISSIONS.FORMS_CREATE,
              PERMISSIONS.FORMS_UPDATE,
              PERMISSIONS.FORMS_PUBLISH,
              PERMISSIONS.MANAGE_DICTIONARIES,
              PERMISSIONS.SUBMISSIONS_SEE_OTHERS,
              PERMISSIONS.SUBMISSIONS_UPDATE_OTHERS,
              PERMISSIONS.FORMS_MAPPINGS,
            ]}
          />
        }
      >
        // Redirect routes Start
        <Route index element={<Navigate to="forms" replace />} />
        <Route
          path="form"
          element={
            <ProtectedRoute
              check={[
                PERMISSIONS.FORMS_CREATE,
                PERMISSIONS.FORMS_UPDATE,
                PERMISSIONS.FORMS_PUBLISH,
              ]}
            />
          }
        >
          <Route index element={<RedirectForms />} />
          <Route path=":id" element={<RedirectForms />} />
          <Route path=":id/:tab/*" element={<RedirectForms />} />;
        </Route>
        <Route
          path="verifications"
          element={
            <ProtectedRoute
              check={[
                PERMISSIONS.SUBMISSIONS_SEE_OTHERS,
                PERMISSIONS.SUBMISSIONS_UPDATE_OTHERS,
              ]}
            />
          }
        >
          <Route index element={<RedirectVerification />} />
        </Route>
        // Redirect routes End
        <Route path="/forms">
          <Route index element={<Register />} />
          <Route
            path="verifications"
            element={
              <ProtectedRoute
                check={[
                  PERMISSIONS.SUBMISSIONS_SEE_OTHERS,
                  PERMISSIONS.SUBMISSIONS_UPDATE_OTHERS,
                ]}
              />
            }
          >
            <Route index element={<Verifications />} />
          </Route>
          <Route path="form">
            <Route
              element={<ProtectedFeaturesRoute check={FEATURES.FORM_BUILDER} />}
            >
              <Route index element={<Create />} />
            </Route>
            <Route path=":id" element={<Navigate to="general" replace />} />
            <Route path=":id/:tab/*" element={<Update />} />;
          </Route>
        </Route>
        <Route
          path="/verification_flows"
          element={
            <ProtectedRoute
              check={[
                PERMISSIONS.FORMS_CREATE,
                PERMISSIONS.FORMS_PUBLISH,
                PERMISSIONS.FORMS_UPDATE,
              ]}
            />
          }
        >
          <Route index element={<VerificationFlowRegister />} />
          <Route
            element={
              <ProtectedFeaturesRoute check={FEATURES.VERIFICATION_FLOW} />
            }
          >
            <Route path="form" element={<VerificationFlowCreate />} />
          </Route>
          <Route path="form/:id" element={<Navigate to="steps" replace />} />
          <Route path="form/:id/verifications/:customer_verification_id" element={<CustomerVerificationDescription />} />
          <Route path="form/:id/:tab/*" element={<VerificationFlowUpdate />} />
        </Route>
        <Route
          path="/client_dictionaries"
          element={<ProtectedRoute check={[PERMISSIONS.MANAGE_DICTIONARIES]} />}
        >
          <Route index element={<ClientDictionariesRegister />} />
          <Route
            element={
              <ProtectedFeaturesRoute check={FEATURES.CUSTOMIZATION_TOOLS} />
            }
          >
            <Route path="form" element={<ClientDictionaryCreate />} />
          </Route>

          <Route path="form/:id" element={<ClientDictionaryUpdate />} />
        </Route>
        <Route
          path="/form_mappings"
          element={<ProtectedRoute check={PERMISSIONS.FORMS_MAPPINGS} />}
        >
          <Route index element={<RedirectFormMappings />} />
          <Route
            path="mappings"
            element={
              <ProtectedRoute
                check={[
                  PERMISSIONS.FORMS_CREATE,
                  PERMISSIONS.FORMS_PUBLISH,
                  PERMISSIONS.FORMS_UPDATE,
                ]}
              />
            }
          >
            <Route index element={<FormMappingsRegister />} />
            <Route path=":id" element={<FormMappingsDescription />} />
          </Route>
          <Route path=":id" element={<FormMappingsDescription />} />
          <Route path="addresses" element={<AddressesRegister />} />
          <Route
            path="account_statuses"
            element={<AccountStatusesRegister />}
          />
          <Route path="companies">
            <Route index element={<CompaniesRegister />} />
            <Route path=":id" element={<CompanyDescription />} />
          </Route>
          <Route path="idv_results" element={<IdvResultsRegister />} />
          <Route
            path="onboarding_flows"
            element={<OnboardingFlowsRegister />}
          />
          <Route
            path="periodic_reviews"
            element={<PeriodicReviewsRegister />}
          />
          <Route path="persons">
            <Route index element={<PeoplesRegister />} />
            <Route path=":id" element={<PersonDescription />} />
          </Route>
          <Route
            path="risk_assessments"
            element={<RiskAssessmentsRegister />}
          />
          <Route
            path="screening_checks"
            element={<ScreeningChecksRegister />}
          />
          <Route path="exports" element={<FormMappingExportsRegister />} />
        </Route>
        <Route path="*" element={<ErrorPages type="local" code={404} />} />
      </Route>
    </Routes>
  );
}

export { Form as AIConfigForm };
