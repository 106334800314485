import loadable from "@loadable/component";
import { LoadableFunctionalComponent } from "../../utils";
import { BooleanInputLoader } from "../../shared";
import { CheckboxComponentViewProps } from "./component/types";

export type { CheckboxComponentViewProps };

export const Checkbox = loadable(() => import("./component"), {
  resolveComponent: (components) => components.CheckboxComponent,
  fallback: <BooleanInputLoader />,
}) as LoadableFunctionalComponent<CheckboxComponentViewProps>;
