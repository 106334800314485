import gql from "graphql-tag";

export const UpdateDataProfileField = gql`
  mutation UpdateDataProfileField(
    $id: String!
    $body: BodyUpdateDataProfileField!
  ) {
    updateDataProfileField(id: $id, body: $body)
      @rest(
        type: "UpdateDataProfileField"
        path: "/core/client/data_profile_fields/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "DataProfileFiled") {
        id
        data_profile_field_config_id
        data_profile_field_id
        data_profile_id
        value
        data_profile_field_config @type(name: "DataProfileFiledConfig") {
          id
          code
          data_profile_config_id
          data_type
          is_required
          name
          created_at
          updated_at
        }
      }
      success
    }
  }
`;


export const UpdateDataProfile = gql`
mutation UpdateDataProfile(
  $id: String!
  $body: BodyUpdateDataProfile!
) {
  updateDataProfile(id: $id, body: $body)
    @rest(
      type: "UpdateDataProfile"
      path: "/core/client/data_profiles/{args.id}"
      bodyKey: "body"
      method: "PUT"
    ) {
      data @type(name: "DataProfile") {
        id
        client_id
        data_profile_config_id
        updated_at
        created_at
        uniq_code
        data_profile_fields @type(name: "DataProfileFiled") {
          id
          data_profile_field_config_id
          data_profile_field_id
          data_profile_id
          value
          data_profile_field_config @type(name: "DataProfileFiledConfig") {
            id
            code
            data_profile_config_id
            data_type
            is_required
            name
            created_at
            updated_at
          }
          updated_at
          created_at
        }
      }
      success
  }
}
`;