import gql from "graphql-tag";
import { CUSTOMER_VERIFICATION_FRAGMENT } from "../../../fragment/verification";
import { CUSTOMER_FRAGMENT } from "packages/api/src/fragment/cm";

export const CustomerVerificationCreate = gql`
  ${CUSTOMER_VERIFICATION_FRAGMENT}
  mutation CustomerVerificationCreate($body: BodyCreateCustomerVerification!) {
    createCustomerVerification(body: $body)
      @rest(
        type: "CustomerVerificationCreate"
        path: "/core/client/customer_verifications"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "CustomerVerification") {
        ...CustomerVerificationFragment
      }
      success
    }
  }
`;

export const AdditionalCustomerCreate = gql`
  ${CUSTOMER_FRAGMENT}
  mutation AdditionalCustomerCreate($body: BodyCreateAdditonlCustomer!) {
    createAdditionalCustomer(body: $body)
      @rest(
        type: "CreateAdditionalCustomer"
        path: "/core/client/additional_customers"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "AdditionalCustomer") {
        id
        first_name
        last_name
        email
        customer_verification_id
        customer @type(name: "Customer") {
          ...CustomerFragment
        }
        created_at
      }
      success
    }
  }
`;

export const DeleteAdditionalCustomer = gql`
  mutation DeleteAdditionalCustomer(
    $id: String!
    $customer_verification_id: String!
  ) {
    deleteAdditionalCustomer(
      id: $id
      customer_verification_id: $customer_verification_id
    )
      @rest(
        type: "DeleteAdditionalCustomer"
        path: "/core/client/additional_customers/{args.id}?{args}"
        method: "DELETE"
      ) {
      success
    }
  }
`;
