import {
  PERMISSIONS,
  PERMISSIONS_CM_CASES,
  PERMISSIONS_PROFILES,
  PERMISSIONS_SUBMISSIONS,
} from '@aid-package/context';
import { PageNavigationItem } from './components/PageNavigation/types';

export const EmailsNavigationItems: PageNavigationItem[] = [
  {
    name: 'Letters',
    path: '/verification/customer_messages',
    icon: 'letter',
    check: [
      PERMISSIONS.SEE_CUSTOMER_MESSAGES,
      PERMISSIONS.MANAGE_CUSTOMER_MESSAGES,
    ],
  },
  {
    name: 'Templates',
    path: '/verification/email_templates',
    icon: 'emailTemplate',
    check: PERMISSIONS.MANAGE_EMAIL_TEMPLATES,
  },
];

export const CaseManagementNavigationItems: PageNavigationItem[] = [
  {
    name: 'Cases',
    path: '/verification/cases',
    icon: 'briefcase',
    check: PERMISSIONS_CM_CASES,
  },
  {
    name: 'Received Cases',
    path: '/sharing/case_projections',
    icon: 'briefcase',
    check: PERMISSIONS.MANAGE_DATA_SHARING,
  },
  {
    name: 'Verifications',
    path: '/verification/verifications',
    icon: 'checklist',
    check: PERMISSIONS_SUBMISSIONS,
  },
  {
    name: 'Enhanced due diligence',
    path: '/verification/edd_reports',
    icon: 'candidate',
    check: PERMISSIONS.MANAGE_EDD_REPORTS,
  },
  {
    name: 'Profiles',
    path: '/profile/profiles',
    icon: 'users',
    check: PERMISSIONS_PROFILES,
  },
  {
    name: 'Identity verifications',
    path: '/verification/identity_verifications',
    icon: 'identityVerification',
    check: [
      PERMISSIONS.CM_IDENTITY_VERIFICATIONS,
      PERMISSIONS.MANAGE_CM_CASES,
      PERMISSIONS.SEE_APPROVED_CM_CASES,
    ],
  },
  {
    name: 'Data Profiles',
    path: '/verification/data_profiles',
    icon: 'users',
    check: PERMISSIONS.MANAGE_DATA_PROFILE,
  },
];

export const UsersNavigationItems: PageNavigationItem[] = [
  {
    name: 'Users',
    path: '/account/users',
    icon: 'users',
    check: PERMISSIONS.CLIENT_MANAGE,
  },
  {
    name: 'User Invites',
    path: '/account/invites',
    icon: 'invite',
    check: PERMISSIONS.CLIENT_INVITE_USERS,
  },
];

export const TmCasesNavigationItems: PageNavigationItem[] = [
  {
    name: 'All Investigations',
    path: '/transaction/investigations',
    icon: 'research',
    check: [PERMISSIONS.SEE_TM, PERMISSIONS.SEE_CASE_RELATED_TRANSACTIONS],
  },
  {
    name: 'My Investigations',
    path: '/transaction/my_cases',
    icon: 'searcher',
    check: [PERMISSIONS.SEE_TM],
  },
  {
    name: 'Investigation Queues',
    path: '/transaction/cases_queues',
    icon: 'tabs',
    check: [PERMISSIONS.SEE_TM],
  },
];

export const AlertsNavigationItems: PageNavigationItem[] = [
  {
    name: 'Transaction Screening Alerts',
    path: '/transaction/screening_alerts',
    icon: 'bell',
    check: [
      PERMISSIONS.SEE_SCREENING_ALERTS,
      PERMISSIONS.SEE_CASE_RELATED_TRANSACTIONS,
    ],
  },
  {
    name: 'Transaction Compliance Alerts',
    path: '/transaction/monitoring_alerts',
    icon: 'unit21',
    check: [
      PERMISSIONS.SEE_MONITORING_ALERTS,
      PERMISSIONS.SEE_CASE_RELATED_TRANSACTIONS,
    ],
  },
  {
    name: 'My Alerts',
    path: '/transaction/my_alerts',
    icon: 'bellUser',
    check: [
      PERMISSIONS.SEE_MONITORING_ALERTS,
      PERMISSIONS.SEE_SCREENING_ALERTS,
    ],
  },
  {
    name: 'Alert Queues',
    path: '/transaction/alerts_queues',
    icon: 'tabs',
    check: [
      PERMISSIONS.SEE_MONITORING_ALERTS,
      PERMISSIONS.SEE_SCREENING_ALERTS,
    ],
  },
];

export const RemindersNavigationItems: PageNavigationItem[] = [
  {
    name: 'Settings',
    path: '/verification/reminder_settings',
    icon: 'settings',
    check: [PERMISSIONS.REMINDER_SETTINGS],
  },
  {
    name: 'Templates',
    path: '/verification/reminder_templates',
    icon: 'templates',
    check: [PERMISSIONS.REMINDER_SETTINGS],
  },
];

export const TMSettingsItems: PageNavigationItem[] = [
  {
    name: 'Narrative Templates',
    path: '/transaction/narrative_templates',
    icon: 'form',
    check: [PERMISSIONS.SEE_TM],
  },
  {
    name: 'Queues',
    path: '/transaction/queues',
    icon: 'bell',
    check: [PERMISSIONS.SEE_TM],
  },
  {
    name: 'Providers',
    path: '/transaction/providers',
    icon: 'history',
    check: [PERMISSIONS.MANAGE_TM],
  },
  {
    name: 'Tags',
    path: '/transaction/tags',
    icon: 'tags',
    check: [PERMISSIONS.SEE_TM],
  },
  {
    name: 'Disposition',
    path: '/transaction/dispositions',
    icon: 'settings',
    check: [PERMISSIONS.SEE_TM],
  },
  {
    name: 'Blacklist Groups',
    path: '/transaction/blacklist_groups',
    icon: 'settings',
    check: [PERMISSIONS.SEE_TM],
  },
];

export const DashboardsNavigationItems: PageNavigationItem[] = [
  {
    name: 'Quick view',
    path: '/statistic/dashboard',
    icon: 'dashboard',
    check: [PERMISSIONS.SEE_DASHBOARD],
  },
  {
    name: 'Compliance view',
    path: '/statistic/compliance_dashboard',
    icon: 'scheme',
    check: [PERMISSIONS.SEE_DASHBOARD],
  },
  {
    name: 'Statistics',
    path: '/statistic/verification_dashboard',
    icon: 'dashboard',

    check: [PERMISSIONS.SEE_DASHBOARD_VERIFICATION],
  },
  {
    name: 'Metrics',
    path: '/statistic/metrics',
    icon: 'statistic',
    check: [PERMISSIONS.SEE_DASHBOARD],
  },
  {
    name: 'Verification statistics',
    path: '/statistic/submissions',
    icon: 'submissionStatistic',
    check: [PERMISSIONS.SEE_SUBMISSIONS_STATISTICS],
  },
  {
    name: 'TM Analytics',
    path: '/statistic/transactions',
    icon: 'transactions',
    check: [PERMISSIONS.SEE_TM_DASHBOARD],
  },
];

export const DataRetentionNavigationItems: PageNavigationItem[] = [
  {
    name: 'DATA RETENTION PERIOD HISTORY',
    path: '/account/data/retention_configs',
    icon: 'cloudServer',
    check: PERMISSIONS.MANAGE_DATA_RETENTION,
  },
  {
    name: 'DELETED DATA',
    path: '/account/data/retention_plans',
    icon: 'delete',
    check: PERMISSIONS.MANAGE_DATA_RETENTION,
  },
];

export const SharingNavigationItems: PageNavigationItem[] = [
  {
    name: 'OUTGOING SHARINGS',
    path: '/sharing/outgoing',
    icon: 'export',
    check: PERMISSIONS.MANAGE_DATA_SHARING,
  },
  {
    name: 'INCOMING SHARINGS',
    path: '/sharing/incoming',
    icon: 'import',
    check: PERMISSIONS.MANAGE_DATA_SHARING,
  },
];
