import { GridContainer } from '@aid-module/ui';
import { useCallback } from 'react';
import { PageNavigationItem } from './components';
import { PageNavigationItemViewProps } from './components/PageNavigationitem/types';
import { LayoutContainer } from './styles';
import { PageNavigationViewProps } from './types';

export function PageNavigation({
  items,
  gridTemplateColumns = 'repeat(6, 217px)'
}: PageNavigationViewProps) {
  const renderPageNavigationItem = useCallback(
    (item: PageNavigationItemViewProps['item']) => (
      <PageNavigationItem key={item.name} item={item} />
    ),
    []
  );

  return (
    <LayoutContainer>
      <GridContainer gridTemplateColumns={gridTemplateColumns} gap="12">
        {items.map(renderPageNavigationItem)}
      </GridContainer>
    </LayoutContainer>
  );
}
